.tabs-pane {
  border: none;
  height: 100px;
}
.htd-placeholder-spot > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab {
  margin-right: 0 !important;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav {
  display: flex;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-ink-bar {
  display: none !important;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab {
  padding: 16px 0;
  margin-right: 16px;
  flex: 1 1;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab:last-child {
  flex: none;
  margin-right: 0;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab > .c7n-steps-item {
  width: 100%;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab > .c7n-steps-item > .c7n-steps-item-icon > i.icon {
  color: #000;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-line {
  position: absolute;
  top: 0.14rem;
  left: 100%;
  display: block;
  width: 99.99rem;
  height: 0.01rem;
  background: rgba(0, 0, 0, 0.26);
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .step-hidden {
  display: none;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-spot {
  padding: 28px 0 16px;
  position: relative;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-spot > .c7n-steps-item-line {
  left: 36%;
  height: 3px;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-spot .c7n-steps-item-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.36);
  left: 20px;
  top: 10px;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-spot .c7n-error-dot {
  background-color: #d50000;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-spot .c7n-finish-dot {
  background-color: #3f51b5;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .c7n-steps-item-spot .c7n-active-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.htd-placeholder-steps > div.htd-drop-area > div.c7n-tabs > div.c7n-tabs-bar > div.c7n-tabs-nav-container > div.c7n-tabs-nav-wrap > div.c7n-tabs-nav-scroll > div.c7n-tabs-nav > div.c7n-tabs-tab .font-size13 {
  font-size: 0.13rem !important;
}
