/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-mapping-table-config-panel-table .c7n-pro-table-professional-toolbar {
  padding-top: 0 !important;
}
.htd-mapping-table-config-panel-table .c7n-pro-table-professional-toolbar .c7n-pro-table-toolbar {
  padding: 0 !important;
}
.htd-mapping-table-config-panel-table .c7n-pro-table-drag-column > span {
  width: 18px !important;
  padding: 0 !important;
}
.htd-mapping-table-config-panel-table .htd-mapping-table-config-panel-table-notice > span {
  padding: 0 !important;
}
.htd-mapping-table-config-panel-table .htd-mapping-table-config-panel-table-notice > span > i {
  font-size: 12px;
}
.htd-mapping-table-config-panel-table .htd-mapping-table-config-panel-table-operator > span {
  padding: 0 !important;
}
.htd-mapping-table-config-panel-table .htd-mapping-table-config-panel-table-operator > span button + button {
  margin-left: 0 !important;
}
.htd-mapping-table-config-panel-table table {
  width: 258px !important;
}
.htd-mapping-table-config-panel-table table > colgroup > col:first-child {
  width: 0.2rem !important;
  min-width: 0.2rem !important;
}
.htd-mapping-table-config-panel-table table > thead > tr > th:nth-child(2) {
  left: 0.2rem !important;
}
.htd-mapping-table-config-panel-table table > tbody > tr > td:nth-child(2) {
  left: 0.2rem !important;
}
.htd-aside-right-columns-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.htd-aside-right-columns-button-wrapper .ant-btn {
  width: 276px;
  height: 36px;
  background-color: #e27279 !important;
  border-radius: 2px;
  color: #fff !important;
  border: none !important;
}
.htd-aside-right-columns-button-wrapper button {
  color: #e27279;
}
.htd-aside-right-columns-button-wrapper button:hover {
  color: #e27279;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon {
  cursor: pointer;
  color: #999;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon:hover {
  color: #e27279;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.htd-aside-right-table-field-edit-panel {
  position: absolute;
  top: 0;
  right: 299px;
  width: 280px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #efefef;
  z-index: 99;
  overflow: hidden;
  margin-right: 1px;
}
.htd-aside-right-table-field-edit-panel .htd-aside-right-table-field-validate-message-setting {
  cursor: pointer;
}
.htd-aside-right-table-field-edit-panel .htd-input-width .ant-form-item-control-input-content::after {
  content: 'px';
  margin-left: 5px;
}
.htd-aside-right-table-field-edit-panel .c7n-pro-select-box .c7n-pro-radio-button {
  padding: 0 8px !important;
}
.htd-aside-right-main-section .ant-table-wrapper {
  max-height: 270px;
  overflow-y: auto;
}
