.htd-placeholder-form-field {
  display: flex;
  height: 28px;
  width: 100%;

  &-required &-label:before {
    content: '*';
    margin-right: 2px;
    line-height: 28px;
    color: red;
    display: inline;
  }
  &-disabled &-content {
    border-color: #dfdfdf;
    background-color: #efefef;
  }

  &-label {
    width: 80px;
    padding-right: 6px;
    font-size: 12px;
    line-height: 28px;
    overflow: hidden;
    text-align: right;
    color: #333;
  }

  &-content {
    position: relative;
    width: 100px;
    height: 100%;
    padding: 0 8px;
    line-height: 26px;
    // border: 1px solid #9ba4bb;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: #bebebe;
    .c7n-pro-select {
      border: none;
    }
    &-extra {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 32px;
    }
  }
  &-datePicker &-content:after {
    font-family: icomoon;
    content: '\EA72';
    position: absolute;
    height: 16px;
    line-height: 16px;
    top: calc(50% - 8px);
    right: 4px;
    bottom: 2px;
  }
  &-datePicker&-range &-content:before {
    font-family: icomoon;
    content: '\EA72';
    position: absolute;
    height: 16px;
    line-height: 16px;
    top: calc(50% - 8px);
    right: calc(50% - 8px);
    bottom: 2px;
  }
  &-number &-content-extra {
    font-family: icomoon;
    font-size: 14px;
    line-height: 14px;
    &:before,
    &:after {
      position: absolute;
      right: 4px;
    }
    &:before {
      top: 0;
      content: '\E316';
    }
    &:after {
      bottom: 0;
      content: '\E313';
    }
  }
  &-select &-content-extra {
    &:after {
      position: absolute;
      right: 4px;
      font-family: icomoon;
      content: '\E313';
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;