/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-aside-right-columns-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.htd-aside-right-columns-button-wrapper button {
  color: #e27279;
}
.htd-aside-right-columns-button-wrapper button:hover {
  color: #e27279;
}
.htd-aside-right-columns-table .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  border: none;
  font-size: 12px;
  height: 20px;
}
.htd-aside-right-columns-table tr {
  background-color: #f8f8f8;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
}
.htd-aside-right-columns-table th,
.htd-aside-right-columns-table td {
  padding: 4px 2px !important;
}
.htd-aside-right-columns-table th input,
.htd-aside-right-columns-table td input {
  font-size: 12px;
}
.htd-aside-right-columns-table th::before {
  background-color: transparent !important;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status {
  width: 0;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status-box {
  display: flex;
  align-items: center;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status-box-img {
  width: 5px;
  height: 16px;
  margin-left: 4px;
  cursor: move;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status .anticon {
  font-size: 12px;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator {
  cursor: pointer;
  width: 20%;
  color: #999;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon {
  cursor: pointer;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon:hover {
  color: #e27279;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.data-table-h .ant-table-wrapper {
  max-height: 270px;
  overflow-y: auto;
}
.jus-conn {
  justify-content: center !important;
}
.jus-conn .ant-btn {
  width: 276px;
  height: 36px;
  background-color: #e27279 !important;
  border-radius: 2px;
  color: #fff !important;
  border: none !important;
}
.ant-table-content table {
  border-spacing: 0 8px !important;
}
.htd-aside-right-table-field-edit-panel {
  position: absolute;
  top: 0;
  right: 299px;
  width: 280px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #efefef;
  z-index: 99;
  overflow: hidden;
}
.htd-aside-right-table-field-edit-panel .htd-aside-right-table-field-validate-message-setting {
  cursor: pointer;
}
.htd-aside-right-table-field-edit-panel .htd-input-width .ant-form-item-control-input-content::after {
  content: 'px';
  margin-left: 5px;
}
.htd-aside-right-table-field-edit-panel .c7n-pro-select-box .c7n-pro-radio-button {
  padding: 0 8px !important;
}
.field-list-popover-box .c7n-input-content {
  height: 28px;
}
.field-list-popover-box .c7n-input-content > .c7n-input-rendered-wrapper {
  height: 28px !important;
}
.field-list-popover-box .c7n-input-content > .c7n-input-rendered-wrapper .c7n-input-rendered {
  height: 28px;
}
.field-list-popover-box .c7n-input-content > .c7n-input-rendered-wrapper .c7n-input-rendered > input {
  height: 28px !important;
}
.field-list-popover-box .c7n-input-content > .c7n-input-rendered-wrapper .c7n-input-label-wrapper > .c7n-input-label {
  line-height: 23px;
}
.field-list-popover-box .field-list-popover-list {
  max-height: 300px;
  overflow-y: auto;
  width: 260px;
}
.field-list-popover-box .field-list-popover-list .field-list-popover-list-item {
  padding-right: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field-list-popover-box > span > div > div > .c7n-input-suffix {
  top: 1px !important;
}
.model-box thead .ant-table-selection {
  left: -8px;
}
.model-box tr .ant-table-cell:hover {
  background-color: #fafafa !important;
}
.model-box ::-webkit-scrollbar {
  height: 8px;
}
.model-box .ant-table-thead .ant-table-cell {
  height: 30px !important;
  background-color: #eeeeee;
}
.model-box .ant-table-tbody .ant-table-cell {
  height: 30px !important;
}
.model-box .ant-table-thead .ant-table-cell:hover {
  background-color: #eeeeee !important;
}
.model-box .ant-table-thead > tr > th,
.model-box .ant-table-tbody > tr > td,
.model-box .ant-table tfoot > tr > th,
.model-box .ant-table tfoot > tr > td {
  padding: 4px 16px;
}
.model-box .ant-table-tbody > tr > td,
.model-box .ant-table tfoot > tr > th,
.model-box .ant-table tfoot > tr > td {
  padding: 4px 8px;
}
.model-box .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #999;
}
.model-box .ant-modal-header {
  padding: 16px 0 16px 24px !important;
  border-bottom: 1px solid #eaeaea !important;
}
.model-box .ant-table-body tr td.ant-table-cell {
  padding: 4px;
  vertical-align: top !important;
}
