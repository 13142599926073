.htd-placeholder-form-field {
  display: flex;
  height: 28px;
  width: 100%;
}
.htd-placeholder-form-field-required .htd-placeholder-form-field-label:before {
  content: '*';
  margin-right: 2px;
  line-height: 28px;
  color: red;
  display: inline;
}
.htd-placeholder-form-field-disabled .htd-placeholder-form-field-content {
  border-color: #dfdfdf;
  background-color: #efefef;
}
.htd-placeholder-form-field-label {
  width: 80px;
  padding-right: 6px;
  font-size: 12px;
  line-height: 28px;
  overflow: hidden;
  text-align: right;
  color: #333;
}
.htd-placeholder-form-field-content {
  position: relative;
  width: 100px;
  height: 100%;
  padding: 0 8px;
  line-height: 26px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #bebebe;
}
.htd-placeholder-form-field-content .c7n-pro-select {
  border: none;
}
.htd-placeholder-form-field-content-extra {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32px;
}
.htd-placeholder-form-field-datePicker .htd-placeholder-form-field-content:after {
  font-family: icomoon;
  content: '\EA72';
  position: absolute;
  height: 16px;
  line-height: 16px;
  top: calc(50% - 8px);
  right: 4px;
  bottom: 2px;
}
.htd-placeholder-form-field-datePicker.htd-placeholder-form-field-range .htd-placeholder-form-field-content:before {
  font-family: icomoon;
  content: '\EA72';
  position: absolute;
  height: 16px;
  line-height: 16px;
  top: calc(50% - 8px);
  right: calc(50% - 8px);
  bottom: 2px;
}
.htd-placeholder-form-field-number .htd-placeholder-form-field-content-extra {
  font-family: icomoon;
  font-size: 14px;
  line-height: 14px;
}
.htd-placeholder-form-field-number .htd-placeholder-form-field-content-extra:before,
.htd-placeholder-form-field-number .htd-placeholder-form-field-content-extra:after {
  position: absolute;
  right: 4px;
}
.htd-placeholder-form-field-number .htd-placeholder-form-field-content-extra:before {
  top: 0;
  content: '\E316';
}
.htd-placeholder-form-field-number .htd-placeholder-form-field-content-extra:after {
  bottom: 0;
  content: '\E313';
}
.htd-placeholder-form-field-select .htd-placeholder-form-field-content-extra:after {
  position: absolute;
  right: 4px;
  font-family: icomoon;
  content: '\E313';
}
