/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-components-Modal-drawer .htd-drag-item-container {
  height: 98.2% !important;
}
.htd-components-Modal {
  position: fixed !important;
  left: calc(50% - 250px);
  top: calc(50% - 250px);
  display: none;
  z-index: 20;
}
.htd-components-Modal-fullscreen {
  width: initial;
  height: initial;
  left: 326px;
  top: 60px;
  bottom: 12px;
  right: 312px;
}
.htd-components-Modal-drawer {
  width: 320px;
  height: initial;
  left: initial;
  right: 312px;
  top: 60px;
  bottom: 0;
}
.htd-components-Modal-has-focused,
.htd-components-Modal.htd-canvas-draggable-container-active {
  display: block;
}
.htd-components-Modal .htd-drag-item-container-header,
.htd-components-Modal .htd-drag-item-container-title {
  z-index: 8;
}
.htd-components-Modal .htd-drag-box-content {
  z-index: 6;
}
.htd-placeholder-modal-fullscreen {
  position: fixed !important;
  left: 326px;
  top: 60px;
  bottom: 12px;
  right: 312px;
}
.htd-placeholder-modal-fullscreen .htd-placeholder-modal-content {
  width: 100%;
  height: 100%;
}
.htd-placeholder-modal-mask {
  position: fixed;
  left: 326px;
  top: 60px;
  bottom: 12px;
  right: 312px;
  background-color: rgba(0, 0, 0, 0.5);
}
.htd-placeholder-modal-drawer,
.htd-placeholder-modal-drawer,
.htd-placeholder-modal-content {
  width: 100%;
  height: 100%;
}
.htd-placeholder-modal-content {
  position: relative;
  padding: 0 16px 16px 16px;
  background-color: #fff;
}
.htd-placeholder-modal-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 48px;
}
.htd-placeholder-modal-content-main {
  box-sizing: border-box;
  padding-top: 16px;
  width: 100%;
  height: calc(100% - 48px - 36px);
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.05);
}
.htd-placeholder-modal-content-footer {
  height: 36px;
  padding-top: 12px;
  text-align: right;
}
.htd-placeholder-modal-content-footer-btn {
  height: 28px;
  outline: none;
  margin-left: 8px;
  min-width: 64px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #30303d;
  background-color: #fff;
}
.htd-placeholder-modal-content-footer-btn-primary {
  background-color: #30303d;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #fff;
}
