.htd-ds-config-collapse .ant-collapse-header {
  background-color: #fff;
}
.htd-ds-config-collapse .ant-collapse-header:hover {
  background-color: transparent !important;
}
.htd-ds-config-collapse .htd-ds-config-top-border {
  border-top: 1px solid #dbdbdb;
}
.htd-ds-config-collapse .htd-ds-config-top-border .htd-ds-config-back {
  background-color: #f7f7f7 !important;
  padding-top: 4px;
}
.htd-ds-config-collapse .htd-ds-config-top-border .htd-ds-config-back .t-j-api-box {
  margin-left: 12px;
  color: #525a6e;
  font-size: 12px;
  padding-bottom: 12px;
  padding-top: 10px;
  width: 25%;
}
.htd-ds-config-collapse .htd-ds-config-top-border .htd-ds-config-back .t-j-api-box svg {
  width: 12px !important;
  height: 12px !important;
}
.htd-ds-config-collapse .htd-ds-config-top-border .htd-ds-config-back .t-j-api-box svg:hover {
  color: #525a6e;
}
.htd-ds-config-collapse .htd-ds-config-top-border .htd-ds-config-back .htd-ds-config-but {
  color: #525a6e !important;
  font-size: 12px;
  margin-left: 14px;
}
.htd-ds-config-collapse .htd-ds-config-top-border .htd-ds-config-back .htd-ds-config-but > i {
  margin-right: 0;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-header {
  height: 44px !important;
  padding: 0 12px !important;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content {
  padding: 0;
  border: none !important;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 4px 0 0 0;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px 0;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row + button {
  margin-top: 4px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row-left {
  width: 100%;
  max-width: 183px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  line-height: 14px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row-right {
  position: absolute;
  top: 4px;
  right: 8px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 22px;
  transition: all 0.2s;
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
  color: #999;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row-right i.icon {
  color: #e0e0e0;
  cursor: pointer;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row-right i.icon.icon-edit-o:hover {
  color: #e27279;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row-right i.icon.icon-delete:hover {
  color: #e27279;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-row:hover .htd-ds-config-api-row-right {
  opacity: 1;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-name {
  display: inline-block;
  width: 32px;
  height: 16px;
  background-color: rgba(160, 160, 173, 0.23);
  border-radius: 2px;
  line-height: 16px;
  color: #525a6e;
  font-size: 10px;
  text-align: center;
  margin-right: 2px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-method {
  display: inline-block;
  padding: 2px;
  margin: 0 2px;
  background-color: #f6f6f6;
  border-radius: 2px;
  font-size: 12px !important;
  width: 38px;
  height: 16px;
  line-height: 10px;
  text-align: center;
  font-weight: 500;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-method-get {
  background-color: rgba(50, 190, 80, 0.23);
  color: #2cae00;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-method-post {
  background-color: rgba(0, 145, 255, 0.2);
  color: #0091ff;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-method-put {
  background-color: #097bed;
  color: #fff;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-method-delete {
  background-color: rgba(249, 111, 104, 0.2);
  color: #f96f68;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-api-url {
  display: inline-block;
  color: #666666;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-field table {
  font-size: 12px;
  background-color: #f7f7f7;
  color: #666;
  padding: 0 8px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-field .ant-table-header {
  background-color: transparent;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-ds-config-field .ant-table-header th {
  background-color: transparent;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table .ant-table-header {
  height: 32px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table .ant-table-thead .ant-table-cell {
  background-color: #f7f7f7 !important;
  border: none;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  height: 32px !important;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table .ant-table-tbody > tr > td > input {
  background-color: #ececec;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table .ant-table-tbody > tr > td svg {
  color: #999;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table .ant-table-tbody > tr > td svg:hover {
  color: #e27279;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table th,
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table td {
  padding: 4px 2px !important;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table th input,
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table td input {
  font-size: 12px;
}
.htd-ds-config-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .htd-aside-left-fields-table th::before {
  background-color: transparent !important;
}
.htd-ds-config-collapse-icon {
  width: 16px;
  height: 16px;
}
.htd-ds-config-collapse:hover {
  cursor: pointer;
}
.query-api-table-no-data-box .no-data {
  border-bottom: none !important;
}
.render-top-span {
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.render-top-span-title {
  color: #999;
  margin-right: 4px;
}
.htd-add-field-modal .c7n-pro-modal-header {
  padding: 24px 14px 12px 14px;
  border-bottom: none !important;
}
.htd-add-field-modal .c7n-pro-modal-header .c7n-pro-modal-title {
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}
.htd-add-field-modal .c7n-pro-modal-body {
  padding: 0 14px 0 14px;
}
.htd-add-field-modal .c7n-pro-modal-footer {
  border-top: 1px solid #eeeeee !important;
  padding-right: 14px;
}
.htd-add-field-modal .c7n-pro-modal-footer .c7n-pro-btn-wrapper {
  height: 28px;
  width: 64px;
  font-size: 12px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: #525a6e;
}
.htd-add-field-modal .c7n-pro-modal-footer .c7n-pro-btn-primary {
  background-color: #e27279;
  color: #fff;
}
.htd-add-field-modal .c7n-pro-modal-footer .c7n-pro-btn-primary.c7n-pro-btn-raised:not(.c7n-pro-btn-disabled):hover {
  background-color: #e27279;
  color: #fff;
}
.htd-add-field-modal-row {
  margin-bottom: 8px;
  font-size: 12px;
  color: #666666;
}
.htd-add-field-modal-row-title {
  height: 32px;
  line-height: 32px;
  margin-bottom: 12px;
  width: 96%;
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
}
.htd-add-field-modal-row-title > div i {
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #999;
}
.htd-add-field-modal-row-title > div .icon-help:before {
  width: 12.25px;
  height: 12.25px;
}
.htd-add-field-modal-row-form {
  height: 44px !important;
  line-height: 41px;
  background-color: #f9f9f9;
  width: 96%;
}
.htd-add-field-modal-row-form > div {
  text-align: center;
}
.htd-add-field-modal-row-form > div .c7n-pro-select-wrapper {
  height: 28px;
}
.htd-add-field-modal-row-form > div .c7n-pro-select-wrapper > label {
  height: 28px;
}
.htd-add-field-modal-row-form > div .c7n-pro-select-wrapper > label > .c7n-pro-select {
  height: 28px;
}
.htd-add-field-modal-row-form > div .left1-svg1-cla1 {
  position: relative;
  top: 4px;
}
.htd-add-field-modal-row-form .c7n-pro-input-required-colors,
.htd-add-field-modal-row-form .c7n-pro-select-required-colors {
  background-color: #fff !important;
}
.htd-add-field-modal-row-form .c7n-pro-input-required-colors .c7n-pro-input:hover {
  border-color: #e27279 !important;
}
.htd-add-field-modal-row-form .c7n-pro-select:hover {
  border-color: #e27279 !important;
}
.htd-add-field-modal-table .c7n-pro-table-professional-toolbar {
  padding-top: 0;
}
.htd-add-field-modal-table .c7n-pro-table-professional-toolbar .c7n-pro-table-toolbar {
  text-align: left;
}
.add-api-list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-api-list-box > span {
  width: 16px;
  height: 16px;
}
.add-api-list-box > span svg {
  width: 16px;
  height: 16px;
  color: #999;
}
.add-api-list-box > span svg:hover {
  color: #e27279;
}
.add-api-list-box .c7n-col-6 {
  padding-right: 0 !important;
}
.table-null-left-box {
  background-color: #f7f7f7;
  width: 100%;
  height: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 42px;
  color: #999;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-null-left-box img {
  width: 84px;
  height: 50px;
}
.table-null-left-box span {
  line-height: 24px;
}
.t-j-api-model-box {
  color: #e27279;
  padding-bottom: 16px;
  padding-top: 4px;
  width: 10%;
  font-size: 12px;
  cursor: pointer;
}
.t-j-api-model-box svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 2.4px;
}
.t-j-api-model-box-title {
  position: relative;
  top: -1px;
}
.htd-aside-right-columns-table-operator svg {
  color: #999 !important;
}
