.my-popconfirm-contents {
  padding: 12px 16px;
  color: #000000d9;
}
.my-popconfirm-contents .my-popconfirm-message {
  position: relative;
  padding: 4px 0 12px;
  color: #000000d9;
  font-size: 14px;
}
.my-popconfirm-contents .my-popconfirm-message .my-popconfirm-message-title {
  display: inline-block;
  padding-left: 9px;
}
.my-popconfirm-contents .my-popconfirm-buttons .ant-btn-primary {
  color: #fff !important;
  border-color: #e27279 !important;
  background: #e27279 !important;
}
.htd-aside-right-columns-table-operator .my-popconfirm-message svg {
  color: #faad14 !important;
}
