.error-boundary-box {
  width: 50%;
  height: 60vh;
  position: relative;
  top: 50%;
  margin-top: -25vh;
  left: 50%;
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-boundary-box .error-boundary-box-img {
  width: 80%;
  margin-bottom: 12px;
}
.error-boundary-box .error-boundary-box-button {
  width: 140px;
  height: 32px;
  background-color: #e27279;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
