/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-drag-box {
  position: relative;
  cursor: grab;
}
.htd-drag-box .htd-drag-item-container {
  border: 1px dashed transparent;
}
.htd-drag-box-hover > .htd-drag-box-content > .htd-drag-item-container {
  border-color: rgba(0, 0, 0, 0.15);
  background-color: #f2f2f2;
}
.htd-drag-box-hover > .htd-drag-box-content > .htd-drag-item-container > .htd-drag-item-container-title {
  display: flex;
}
.htd-drag-box-fixed {
  cursor: pointer;
}
.htd-drag-box-placeholder {
  flex: 1 1 100%;
  border: none !important;
}
.htd-drag-box-placeholder-inline-block {
  flex: none !important;
  width: auto !important;
}
.htd-drag-box-mask {
  position: absolute;
  height: calc(100% - 8px);
  top: 4px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #333;
  right: 4px;
  transition: all 0.25s ease;
  border-radius: 2px;
  z-index: 10;
}
.htd-drag-box-mask:hover {
  background-color: #f6e9ea;
  color: #e27279;
}
.htd-drag-box-mask-placeholder {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}
.htd-drag-box-mask-placeholder::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  border: 1px solid #e27279;
}
.htd-drag-box-mask-placeholder.htd-drag-box-mask-placeholder-inline-block::after {
  width: 2px !important;
  height: 20px !important;
}
.htd-drag-box-sortable .htd-drag-box-content {
  padding-right: 58px;
}
.htd-drag-box-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.box-border {
  border: 1px solid red;
}
