/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-aside-right-main .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.htd-aside-right-main .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.htd-aside-right-main .antd-form-box-padding > .ant-form-item {
  padding: 0 12px;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra {
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra > .anticon {
  cursor: pointer;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra > .anticon:hover {
  color: #e27279;
}
.htd-validate-config-panel.ant-form .ant-form-item {
  justify-content: space-between;
}
.htd-validate-config-panel.ant-form .ant-form-item > .ant-form-item-control {
  max-width: 69%;
}
.htd-validate-config-panel.ant-form .ant-form-item .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.htd-validate-config-panel.ant-form .ant-form-item .ant-form-item-control-input-content .htd-variable-input {
  justify-content: flex-end;
}
.htd-validate-config-panel.ant-form .ant-form-item .ant-form-item-control-input-content > div {
  width: 100%;
}
.htd-validate-config-panel.ant-form .ant-form-item .ant-form-item-control-input-content > .ant-radio-group.ant-radio-group-outline {
  display: flex;
  justify-content: flex-end;
}
