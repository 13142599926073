@import '../../../utils/styles/variables.less';

.htd-aside-right {
  height: 100%;
  overflow: hidden;
  border-left: 1px solid #efefef;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 0 8px;
    font-size: 14px;
    line-height: 32px;
    border-bottom: 1px solid #dfdfdf;

    i.icon {
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
  }
  &-main {
    height: calc(100% - 41px);
    // padding: 0 8px 8px 8px;
    overflow-y: auto;
    // 右侧属性折叠面板样式
    .ant-collapse {
      border: none;
      border-radius: 0;
      background-color: transparent !important;

      .ant-collapse-header {
        padding: 8px 0px !important;
        height: 36px;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
        .ant-collapse-arrow {
          margin-left: 12px;
          margin-right: 8px !important;
        }
      }

      .ant-collapse-item {
        border-radius: 0 !important;
        // margin-bottom: 8px;
        // height: 36px;
        border-bottom: none;
        margin-bottom: 8px;
      }

      .ant-collapse-content {
        background-color: transparent !important;
        // 干掉右侧属性面板 展开之后 多余的下边框
        border-top: none !important;
      }

      .ant-collapse-content-box {
        padding: 0 12px 4px 12px;
      }
    }

    // 右侧表单样式
    .ant-form {
      .ant-radio-group {
        .ant-radio-button-wrapper {
          padding: 0 8px !important;
          line-height: 28px;
          font-size: 12px;
        }
      }
      .ant-form-item {
        justify-content: space-between;
        > .ant-form-item-control {
          max-width: 69%;
        }
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .htd-variable-input {
            justify-content: flex-end;
          }
          > div {
            width: 100%;
          }
          > .ant-radio-group.ant-radio-group-outline {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      .ant-table-cell {
        .ant-form-item-control {
          max-width: 100%;
        }
      }
    }
    &-section {
      margin-top: 8px;
      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        font-size: 12px;
        line-height: 16px;
        padding-left: 6px;
        &:before {
          content: '';
          width: 2px;
          height: 12px;
          position: absolute;
          left: 0;
          top: calc(50% - 6px);
          background-color: @canvas-border-color;
        }
      }
    }
  }
  // 单选框样式
  // .ant-radio-button {
  //   width: 50px !important;
  // }
  .ant-radio-button-wrapper {
    // width: 50px !important;
    flex: 1 !important;
    text-align: center;
    color: @back !important;
  }
  .ant-radio-button-wrapper-checked {
    // width: 50px !important;
    background-color: @back !important;
    color: #ffffff !important;
    border: 1px solid @back !important;
    box-shadow: none !important;
  }
  // 图标选择器样式
  .c7n-pro-icon-picker-wrapper {
    width: 100%;
  }
  // 单选框 选中之后 前面存在边框 bug
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: @back !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: @back !important;
    border: none !important;
  }
}
// 右侧面板 和 编辑弹框 公用样式 （去掉属性面板这里的下边框）
.box-header {
  border-bottom: none;
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;