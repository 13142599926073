.htd-placeholder-row {
  padding: 8px;
  // 组件边框 (内容元素的虚线边框样式)
  // border: 1px dashed #e8e8e8;
  background-color: rgba(0, 0, 0, 0.05);

  > .htd-drop-area {
    display: flex;
    flex-flow: row wrap;
    min-height: 100px;
  }

  &-tensile {
    position: relative;

    &-line {
      position: absolute;
      top: 10%;
      left: -2px;
      height: 80%;
      width: 3px;
      cursor: col-resize;
      // 数据属性编辑 弹框打开之后 这里的线条 层级太高 穿透了
      z-index: 19;
      margin-left: 1px;
      border-left-style: dashed;
      border-left-width: 2px;
      border-left-color: #e8e8e8;

      &:hover {
        border-left-color: #e27277;
      }
    }
  }
}
// 当容量里面有下级元素时 清空背景颜色
.not-chr {
  background-color: transparent !important;
}

.htd-placeholder-col {
  // border: 1px dashed #e8e8e8;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  height: 100%;

  > .htd-drop-area {
    height: 100%;
  }
}

.htd-placeholder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  > span {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  // border-bottom: 1px solid #e8e8e8;

  > span {
    display: inline-block;
    // margin: 0 24px 0 16px;
    margin-left: 16px;
    font-weight: bold;
  }

  > div {
    flex: 1;
    height: 100%;

    > .htd-drop-area {
      // 按钮位置时 去掉了
      // display: flex;
      // flex-direction: row-reverse;
      // justify-content: flex-start;
      // align-items: center;
      // // 头部 拖拽按钮之后  右边距
      // padding-right: 16px;

      padding-top: 5px;
      padding-left: 16px;
      padding-right: 16px;

      .htd-drop-area-placeholder {
        margin-left: 0;
        right: 0;
        left: unset;
        margin-right: 16px;

        &::after {
          content: '拖拽按钮';
          width: 82px;
          display: inline-block;
          height: 28px;
          background-color: #f2f2f2;
          border: 1px dashed rgba(0, 0, 0, 0.15);
          border-radius: 2px;
          line-height: 27px;
          position: relative;
          top: -4px;
          right: -8px;
          text-align: start;
          padding-left: 8px;
          color: #bfc3d0;
          font-size: 12px;
        }
      }
    }
  }
  .htd-drag-header-right-jt {
    position: absolute;
    right: 24px;
  }
}
// Tabs 样式
.htd-placeholder-tabs {
  // border: 1px dashed #e8e8e8;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;

  > .htd-drop-area {
    min-height: 100px;
  }
}
// 解决Tabs 存在overFlow 组件 标题显示不出来问题
.tabs-over-flow {
  overflow: hidden;
  .c7n-tabs {
    overflow: visible;
  }
  // 标题显示不全 用上边距调整位置 挤出 标题的位置
  .c7n-tabs:not(.c7n-tabs-vertical) > .c7n-tabs-content-animated {
    margin-top: 12px;
  }
  .c7n-tabs:not(.c7n-tabs-vertical) > .c7n-tabs-content {
    margin-top: 12px;
  }
  .c7n-tabs-vertical > .c7n-tabs-content {
    overflow: visible;
    margin-top: 12px !important;
  }
  // 去掉 bar的边框（解决因为调整了content上边距之后 边框高度不齐问题）
  .c7n-tabs-vertical.c7n-tabs-right > .c7n-tabs-bar {
    border-left: none !important;
  }
  .c7n-tabs-vertical.c7n-tabs-left > .c7n-tabs-bar {
    border-right: none !important;
  }
  //  左右布局时用margin 挤出bar位置
  .htd-placeholder-tabs-content-margin-left {
    > .c7n-tabs-content {
      // border-left: none !important;
      margin-top: 12px !important;
      margin-left: 80px !important;
    }
    > .c7n-tabs-content-animated {
      margin-left: 80px !important;
    }
  }
  .htd-placeholder-tabs-content-margin-right {
    > .c7n-tabs-content {
      // border-right: none !important;
      margin-top: 12px !important;
      margin-right: 80px !important;
    }
    > .c7n-tabs-content-animated {
      margin-right: 80px !important;
    }
  }
}

.htd-placeholder-tabs-tabpane {
  // border: 1px dashed #e8e8e8;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;

  > .htd-drop-area {
    min-height: 100px;
  }
}

.htd-placeholder-content {
  height: 100%;
  // padding: 8px;
  padding: 21px 16px 16px 16px;
}

.htd-placeholder-table {
  pointer-events: none;

  .c7n-pro-table-professional-query-more {
    pointer-events: auto;
  }

  .c7n-pro-table-content-overflow {
    pointer-events: auto;
    & > table {
      pointer-events: none;
    }
    &::-webkit-scrollbar {
      height: 10px;
    }
  }
}

.htd-placeholder-button {
  pointer-events: none;
}

.htd-placeholder-card-border {
  border: #e27277 1px solid;
  min-height: 140px;
}

.htd-placeholder-card-noborder {
  min-height: 140px;
}

.htd-placeholder-card-label-pre {
  display: inline-block;
  width: 4px;
  height: 100%;
  background-color: #e27277;
}

.htd-placeholder-card-label {
  // border: 1px solid #e27277;
  height: 17px;
  line-height: 32px;
  margin: 0 10px;
  font-size: 14px;
  text-align: left;
}

.htd-placeholder-card-label-text {
  display: inline-block;
  margin: 0px 0px 0 10px;
  width: 50%;
  height: 14px;
}

.htd-placeholder-card-content {
  min-height: 90px;
  // border: 1px dashed #e8e8e8;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 10px 10px;

  > .htd-drop-area {
    min-height: 100px;
  }
}
/* lov框样式bug */
.htd-placeholder-form-field {
  .c7n-pro-select-focused .c7n-pro-select {
    border: none !important;
  }
  // from中lov框的图标偏移问题
  &-content {
    > label > .c7n-pro-select-suffix {
      right: -8px;
      top: -2px;
    }
  }
}

.htd-placeholder-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  > span {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  // border-bottom: 1px solid #e8e8e8;

  > span {
    display: inline-block;
    // margin: 0 24px 0 16px;
    margin-left: 16px;
    font-weight: bold;
  }

  > div {
    flex: 1;
    height: 100%;

    > .htd-drop-area {
      // display: flex;
      // flex-direction: row-reverse;
      // justify-content: flex-start;
      // align-items: center;
      padding-top: 5px;
      padding-left: 16 px;
      padding-right: 16px;

      .htd-drop-area-placeholder {
        &::after {
          content: '拖拽按钮到这里';
          display: inline-block;
          height: 28px;
          border-radius: 2px;
          line-height: 27px;
          position: relative;
          top: -4px;
          right: -8px;
          text-align: start;
          padding-left: 8px;
          padding-right: 8px;
          color: #bfc3d0;
          font-size: 12px;
        }
      }
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;