/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-common-config-panel .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
.htd-common-config-panel .ant-form-item-label {
  width: 75px;
}
.htd-common-config-panel .ant-form-item-label > label {
  width: 75px;
}
.htd-common-config-panel .ant-col-8 {
  flex: 0 0 30.5%;
}
.htd-common-config-panel .ant-input-number {
  width: 100%;
}
.htd-common-config-panel-section-header {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  padding-left: 6px;
}
.htd-common-config-panel-section-header:before {
  content: '';
  width: 2px;
  height: 12px;
  position: absolute;
  left: 0;
  top: calc(50% - 6px);
  background-color: #e27279;
}
.htd-common-config-panel-section-body {
  margin-top: 8px;
}
.common-config-panel-box .ant-form-item-control {
  width: 190px;
}
.ant-form-item-label {
  height: 28px;
}
.ant-form-item-control {
  height: 28px;
}
.ant-form-item-label > label {
  height: 28px !important;
  color: #666 !important;
}
.auto-complete-box > div > div > div > div > div > div > span > input {
  border: none !important;
}
