.error-boundary-box {
  width: 50%;
  height: 60vh;
  position: relative;
  top: 50%;
  margin-top: -25vh;
  left: 50%;
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error-boundary-box-img {
    width: 80%;
    margin-bottom: 12px;
  }
  .error-boundary-box-button {
    width: 140px;
    height: 32px;
    background-color: #e27279;
    border-radius: 2px;
    color: #fff;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;