/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-components-Col-1 {
  flex: 0 0 4.16666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-2 {
  flex: 0 0 8.33333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-3 {
  flex: 0 0 12.5%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-4 {
  flex: 0 0 16.66666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-5 {
  flex: 0 0 20.83333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-6 {
  flex: 0 0 25%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-7 {
  flex: 0 0 29.16666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-8 {
  flex: 0 0 33.33333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-9 {
  flex: 0 0 37.5%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-10 {
  flex: 0 0 41.66666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-11 {
  flex: 0 0 45.83333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-12 {
  flex: 0 0 50%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-13 {
  flex: 0 0 54.16666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-14 {
  flex: 0 0 58.33333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-15 {
  flex: 0 0 62.5%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-16 {
  flex: 0 0 66.66666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-17 {
  flex: 0 0 70.83333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-18 {
  flex: 0 0 75%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-19 {
  flex: 0 0 79.16666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-20 {
  flex: 0 0 83.33333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-21 {
  flex: 0 0 87.5%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-22 {
  flex: 0 0 91.66666667%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-23 {
  flex: 0 0 95.83333333%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Col-24 {
  flex: 0 0 100%;
  padding-left: 2px;
  padding-right: 2px;
  width: 50%;
  min-height: 80px;
}
.htd-components-Form-1 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-1 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 100% !important;
}
.htd-components-Form-1 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 100% !important;
}
.htd-components-Form-2 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-2 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 50% !important;
}
.htd-components-Form-2 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 50% !important;
}
.htd-components-Form-2 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 100% !important;
}
.htd-components-Form-3 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-3 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 33.33333333% !important;
}
.htd-components-Form-3 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 33.33333333% !important;
}
.htd-components-Form-3 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 66.66666667% !important;
}
.htd-components-Form-3 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 100% !important;
}
.htd-components-Form-4 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-4 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 25% !important;
}
.htd-components-Form-4 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 25% !important;
}
.htd-components-Form-4 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 50% !important;
}
.htd-components-Form-4 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 75% !important;
}
.htd-components-Form-4 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 100% !important;
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 20% !important;
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 20% !important;
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 40% !important;
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 60% !important;
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 80% !important;
}
.htd-components-Form-5 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 100% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 16.66666667% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 16.66666667% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 33.33333333% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 50% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 66.66666667% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 83.33333333% !important;
}
.htd-components-Form-6 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 100% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 14.28571429% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 14.28571429% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 28.57142857% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 42.85714286% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 57.14285714% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 71.42857143% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 85.71428571% !important;
}
.htd-components-Form-7 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 100% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 12.5% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 12.5% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 25% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 37.5% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 50% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 62.5% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 75% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 87.5% !important;
}
.htd-components-Form-8 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 100% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 11.11111111% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 11.11111111% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 22.22222222% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 33.33333333% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 44.44444444% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 55.55555556% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 66.66666667% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 77.77777778% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 88.88888889% !important;
}
.htd-components-Form-9 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 100% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 10% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 10% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 20% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 30% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 40% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 50% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 60% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 70% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 80% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 90% !important;
}
.htd-components-Form-10 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 100% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 9.09090909% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 9.09090909% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 18.18181818% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 27.27272727% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 36.36363636% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 45.45454545% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 54.54545455% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 63.63636364% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 72.72727273% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 81.81818182% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 90.90909091% !important;
}
.htd-components-Form-11 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 100% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 8.33333333% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 8.33333333% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 16.66666667% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 25% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 33.33333333% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 41.66666667% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 50% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 58.33333333% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 66.66666667% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 75% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 83.33333333% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 91.66666667% !important;
}
.htd-components-Form-12 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 100% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 7.69230769% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 7.69230769% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 15.38461538% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 23.07692308% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 30.76923077% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 38.46153846% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 46.15384615% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 53.84615385% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 61.53846154% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 69.23076923% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 76.92307692% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 84.61538462% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 92.30769231% !important;
}
.htd-components-Form-13 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 100% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 7.14285714% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 7.14285714% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 14.28571429% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 21.42857143% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 28.57142857% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 35.71428571% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 42.85714286% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 50% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 57.14285714% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 64.28571429% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 71.42857143% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 78.57142857% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 85.71428571% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 92.85714286% !important;
}
.htd-components-Form-14 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 100% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 6.66666667% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 6.66666667% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 13.33333333% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 20% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 26.66666667% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 33.33333333% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 40% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 46.66666667% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 53.33333333% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 60% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 66.66666667% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 73.33333333% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 80% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 86.66666667% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 93.33333333% !important;
}
.htd-components-Form-15 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 100% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 6.25% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 6.25% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 12.5% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 18.75% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 25% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 31.25% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 37.5% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 43.75% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 50% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 56.25% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 62.5% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 68.75% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 75% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 81.25% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 87.5% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 93.75% !important;
}
.htd-components-Form-16 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 100% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 5.88235294% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 5.88235294% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 11.76470588% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 17.64705882% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 23.52941176% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 29.41176471% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 35.29411765% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 41.17647059% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 47.05882353% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 52.94117647% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 58.82352941% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 64.70588235% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 70.58823529% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 76.47058824% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 82.35294118% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 88.23529412% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 94.11764706% !important;
}
.htd-components-Form-17 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 100% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 5.55555556% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 5.55555556% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 11.11111111% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 16.66666667% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 22.22222222% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 27.77777778% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 33.33333333% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 38.88888889% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 44.44444444% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 50% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 55.55555556% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 61.11111111% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 66.66666667% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 72.22222222% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 77.77777778% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 83.33333333% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 88.88888889% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 94.44444444% !important;
}
.htd-components-Form-18 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 100% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 5.26315789% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 5.26315789% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 10.52631579% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 15.78947368% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 21.05263158% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 26.31578947% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 31.57894737% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 36.84210526% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 42.10526316% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 47.36842105% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 52.63157895% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 57.89473684% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 63.15789474% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 68.42105263% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 73.68421053% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 78.94736842% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 84.21052632% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 89.47368421% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 94.73684211% !important;
}
.htd-components-Form-19 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-19 {
  width: 100% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 5% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 5% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 10% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 15% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 20% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 25% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 30% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 35% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 40% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 45% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 50% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 55% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 60% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 65% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 70% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 75% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 80% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 85% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 90% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-19 {
  width: 95% !important;
}
.htd-components-Form-20 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-20 {
  width: 100% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 4.76190476% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 4.76190476% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 9.52380952% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 14.28571429% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 19.04761905% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 23.80952381% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 28.57142857% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 33.33333333% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 38.0952381% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 42.85714286% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 47.61904762% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 52.38095238% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 57.14285714% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 61.9047619% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 66.66666667% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 71.42857143% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 76.19047619% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 80.95238095% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 85.71428571% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-19 {
  width: 90.47619048% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-20 {
  width: 95.23809524% !important;
}
.htd-components-Form-21 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-21 {
  width: 100% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 4.54545455% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 4.54545455% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 9.09090909% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 13.63636364% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 18.18181818% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 22.72727273% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 27.27272727% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 31.81818182% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 36.36363636% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 40.90909091% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 45.45454545% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 50% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 54.54545455% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 59.09090909% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 63.63636364% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 68.18181818% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 72.72727273% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 77.27272727% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 81.81818182% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-19 {
  width: 86.36363636% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-20 {
  width: 90.90909091% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-21 {
  width: 95.45454545% !important;
}
.htd-components-Form-22 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-22 {
  width: 100% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 4.34782609% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 4.34782609% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 8.69565217% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 13.04347826% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 17.39130435% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 21.73913043% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 26.08695652% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 30.43478261% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 34.7826087% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 39.13043478% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 43.47826087% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 47.82608696% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 52.17391304% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 56.52173913% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 60.86956522% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 65.2173913% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 69.56521739% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 73.91304348% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 78.26086957% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-19 {
  width: 82.60869565% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-20 {
  width: 86.95652174% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-21 {
  width: 91.30434783% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-22 {
  width: 95.65217391% !important;
}
.htd-components-Form-23 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-23 {
  width: 100% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area::after {
  display: block;
  width: 100%;
  height: 50px;
  content: '';
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
  margin-bottom: 16px;
  width: 4.16666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-1 {
  width: 4.16666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-2 {
  width: 8.33333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-3 {
  width: 12.5% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-4 {
  width: 16.66666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-5 {
  width: 20.83333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-6 {
  width: 25% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-7 {
  width: 29.16666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-8 {
  width: 33.33333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-9 {
  width: 37.5% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-10 {
  width: 41.66666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-11 {
  width: 45.83333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-12 {
  width: 50% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-13 {
  width: 54.16666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-14 {
  width: 58.33333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-15 {
  width: 62.5% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-16 {
  width: 66.66666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-17 {
  width: 70.83333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-18 {
  width: 75% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-19 {
  width: 79.16666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-20 {
  width: 83.33333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-21 {
  width: 87.5% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-22 {
  width: 91.66666667% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-23 {
  width: 95.83333333% !important;
}
.htd-components-Form-24 > div > div > main > .htd-placeholder-form > .htd-drop-area > .htd-drag-box:not(.htd-components-PLACEHOLDER).htd-components-form-field-24 {
  width: 100% !important;
}
.htd-placeholder-row-tensile-line:hover {
  border-left-style: solid;
  border-left-width: 3px;
}
.clear-float {
  clear: left;
}
.htd-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.htd-home-header {
  flex: 0 0 48px;
  background-color: #30303d;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.htd-home-header-top-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.htd-home-header-top-box img {
  width: 40px;
  height: 18px;
  margin-right: 8px;
}
.htd-home-header-title {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-image: linear-gradient(134deg, #f78249 0%, #e27279 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.htd-home-header-actions-generate {
  height: 28px;
  padding: 0 16px;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.35s;
  border-radius: 2px;
}
.htd-home-header-actions-generate-default {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}
.htd-home-header-actions-generate-primary {
  color: #fff;
  background-color: #e27279;
}
.htd-home-header-actions-generate-primary:hover {
  background-color: #e27279;
  color: #fff;
}
.htd-home-header-actions-buttonBox {
  border-right: 1px solid #55555f;
  display: inline-block;
  margin-right: 24px;
  font-size: 12px;
}
.htd-home-header-actions-buttonBox a {
  margin-right: 24px;
  color: #999999;
}
.htd-home-header-actions-buttonBox img {
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
}
.htd-home-header-actions button + button {
  margin-left: 8px;
}
.htd-home-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: calc(100% - 48px);
}
.htd-home .htd-aside-left {
  flex: 0 0 265px;
}
.htd-home .htd-aside-right {
  flex: 0 0 300px;
}
.htd-home .htd-canvas-wrap {
  flex: 1;
}
.htd-drag-box-inline {
  display: inline;
}
.htd-drag-box-inline-container,
.htd-drag-box-inline-block {
  display: inline-block;
}
.htd-components-preview-modal .c7n-pro-modal-body {
  padding-top: 0 !important;
}
.htd-components-preview-modal .c7n-pro-modal-header {
  border-bottom: none !important;
}
.page-tabs {
  position: absolute;
  left: 326px;
  top: 48px;
  z-index: 9;
  display: flex;
  height: 40px;
  align-items: center;
}
.page-tabs .page-tabs-tab-active {
  display: flex;
  min-width: 60px;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: white;
  background: #e27277;
  border-radius: 3px;
  margin-right: 8px;
}
.page-tabs .page-tabs-tab {
  display: flex;
  min-width: 60px;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #f1f1f1;
  border-radius: 3px;
  margin-right: 8px;
}
.page-tabs .page-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.page-tabs .page-close {
  width: 16px;
  height: 16px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.page-tabs .page-close .icon {
  font-size: 14px;
}
.page-tabs .page-close:hover,
.page-tabs .add-page-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.page-tabs .add-page-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 20px;
}
.page-tabs .add-page-button span {
  margin-top: -4px;
}
.htd-page-config-modal .c7n-pro-modal-header {
  padding: 16px 0 16px 24px;
  border-bottom: 1px solid #eaeaea !important;
}
