/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.field-edit-panel-box .query-box .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
.field-edit-panel-box .query-box .ant-form-item-label {
  width: 75px;
}
.field-edit-panel-box .query-box .ant-form-item-control {
  width: 190px;
  height: 28px !important;
}
.field-edit-panel-box .query-box .ant-form-item-control .ant-form-item-control-input {
  width: 190px;
  height: 28px !important;
}
.field-edit-panel-box .query-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px !important;
}
.field-edit-panel-box .query-box .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.field-edit-panel-box .table-null-right-box {
  margin: 0;
}
.field-edit-panel-box .htd-aside-right-columns-table .htd-aside-right-columns-table-operator {
  cursor: pointer;
  width: 20%;
}
.field-edit-panel-box .htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon {
  cursor: pointer;
}
.field-edit-panel-box .htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon:hover {
  color: #e27279;
}
.field-edit-panel-box .htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.field-edit-panel-box .ant-table-wrapper {
  max-height: 270px;
  overflow-y: auto;
}
.query-field-seach .c7n-input-content > .c7n-input-rendered-wrapper {
  height: 28px !important;
}
.query-field-seach .c7n-input-content > .c7n-input-rendered-wrapper .c7n-input-rendered {
  height: 28px;
}
.query-field-seach .c7n-input-content > .c7n-input-rendered-wrapper .c7n-input-rendered > input {
  height: 28px !important;
}
.query-field-seach .c7n-input-content > .c7n-input-rendered-wrapper .c7n-input-label-wrapper > .c7n-input-label {
  line-height: 23px;
}
