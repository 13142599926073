@import './utils/styles/variables.less';

body {
  font-family: PingFangSC-Regular;
  .ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        font-size: 16px;
        padding: 24px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 400;
        border-bottom: 0;
      }
      .ant-modal-body {
        padding: 16px 24px;
      }
      .ant-modal-footer {
        padding: 16px 24px;
      }
    }
  }
  .ant-switch {
    background-color: #d9d9d9;
    &-checked {
      background-color: @primary-color;
    }
  }
  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;