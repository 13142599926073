@import '~antd/lib/style/themes/default.less';

.editable-row {
  .ant-form-item {
    margin: 0 !important;
  }

  &.drop-over-downward td {
    border-bottom: 2px dashed @primary-color;
  }
  &.drop-over-upward td {
    border-top: 2px dashed #e27279;
  }
}
// 仅在图标上拖动
.only-table-icon-drop-over-downward {
  border-bottom: 2px dashed @primary-color !important;
}
.only-table-icon-drop-over-upward {
  border-top: 2px dashed #e27279;
}
// 配置面板 表格为空时 样式
.table-null-right-box {
  background-color: #f7f7f7;
  width: 100%;
  height: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 42px;
  color: #999;
  font-weight: 400;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 84px;
    height: 50px;
  }
  span {
    line-height: 24px;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;