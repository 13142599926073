/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-common-action-panel .htd-common-action-panel-list .left1-svg1-cla1 svg {
  width: 12px;
  height: 12px;
}
.htd-common-action-panel .htd-common-action-panel-list .htd-common-action-panel-item > .ant-row > .ant-col {
  padding: 4px;
}
.htd-common-action-panel .htd-common-action-panel-list .htd-common-action-panel-item > .ant-row:first-child > .ant-col:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  background-color: #f2f2f2;
}
.htd-common-action-panel .htd-common-action-panel-list .htd-common-action-panel-item > .ant-row:first-child > .ant-col:last-child > span:last-child > span {
  cursor: pointer;
  color: #999;
}
.htd-common-action-panel .htd-common-action-panel-list .htd-common-action-panel-item > .ant-row:first-child > .ant-col:last-child > span:last-child > span:hover {
  color: #e27279;
}
.htd-common-action-panel .htd-common-action-panel-list .htd-common-action-panel-item > .ant-row:first-child > .ant-col:last-child > span:last-child > span + span {
  margin-left: 4px;
}
.htd-common-action-panel .list-top-margin {
  margin-top: 12px;
}
.htd-common-action-modal .ant-col-14 {
  flex: 0 0 56%;
  max-width: 56%;
}
.htd-common-action-modal .top-title-box-row {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.htd-common-action-modal .top-title-box-row-left-box {
  display: flex;
  width: 43%;
  background-color: #fff;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-col-4 {
  flex: 0 0 40%;
  max-width: 40%;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-col-6 {
  flex: 0 0 58%;
  max-width: 58%;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-collapse {
  border: none;
  border-radius: 0;
  background-color: transparent !important;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-collapse .ant-collapse-header {
  padding: 8px 4px !important;
  height: 36px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-collapse .ant-collapse-header .ant-collapse-arrow {
  margin-left: 6px;
  margin-right: 8px !important;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-collapse .ant-collapse-item {
  border-radius: 0 !important;
  border-bottom: none;
  margin-bottom: 8px;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-collapse .ant-collapse-content {
  background-color: transparent !important;
  border-top: none !important;
}
.htd-common-action-modal .top-title-box-row-left-box .ant-collapse .ant-collapse-content-box {
  padding: 0 12px 4px 12px;
}
.htd-common-action-modal .top-title-box-row-left-box .first-list-cf .htd-common-action-modal-list {
  border-right: transparent;
}
.htd-common-action-modal .ant-modal-header {
  border-bottom: none;
  padding-bottom: 20px !important;
}
.htd-common-action-modal .ant-modal-body {
  padding: 0 24px 20px 24px !important;
}
.htd-common-action-modal .ant-model-content .ant-modal-footer {
  font-size: 14px;
}
.htd-common-action-modal .ant-modal-footer {
  font-size: 14px;
  padding: 10px 24px !important;
}
.htd-common-action-modal .ant-modal-footer .ant-btn {
  height: 28px !important;
  line-height: 14px;
  font-size: 12px;
}
.htd-common-action-modal .htd-common-action-modal-content {
  height: 400px;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  font-size: 12px;
}
.htd-common-action-modal .htd-common-action-modal-content-action-type {
  margin-right: 8px;
}
.htd-common-action-modal .htd-common-action-modal-content-action-from {
  border: 1px solid #d0d0d0;
}
.htd-common-action-modal .htd-common-action-modal-content-action-from .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.htd-common-action-modal .htd-common-action-modal-content-action-from .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 24px;
}
.htd-common-action-modal .htd-common-action-modal-content-action-from .ant-col-14 {
  flex: 0 0 54%;
  max-width: 54%;
}
.htd-common-action-modal .htd-common-action-modal-content > .ant-col {
  height: 100%;
}
.htd-common-action-modal .htd-common-action-modal-list {
  height: 100%;
  border: 1px solid #d0d0d0;
  font-size: 12px;
}
.htd-common-action-modal .htd-common-action-modal-list-search {
  padding: 8px 4px 0 4px;
  /* 搜索框 默认提示文字 偏移问题 */
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-label-wrapper {
  top: -2px;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content {
  height: 28px;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 2px !important;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content .c7n-input-rendered-wrapper {
  height: 28px !important;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered-sm {
  height: 28px !important;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered-sm .c7n-input {
  height: 28px !important;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered-sm .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered-sm .c7n-input-label {
  line-height: 27px;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-suffix {
  top: 1px;
}
.htd-common-action-modal .htd-common-action-modal-list-search .c7n-input-content:hover {
  border-color: #e27279 !important;
}
.htd-common-action-modal .htd-common-action-modal-list-search svg {
  color: #a0a0ad;
}
.htd-common-action-modal .htd-common-action-modal-list-content {
  height: calc(100% - 48px);
  overflow-y: auto;
}
.htd-common-action-modal .htd-common-action-modal-list-content .ant-collapse-header {
  padding: 0 8px !important;
}
.htd-common-action-modal .htd-common-action-modal-list-content p {
  padding-left: 16px;
}
.htd-common-action-modal .htd-common-action-modal-list-content .ant-collapse-content-box {
  padding: 0 !important;
}
.htd-common-action-modal .htd-common-action-modal-list-content .ant-collapse-content-box p {
  padding-left: 32px;
  display: flex;
}
.htd-common-action-modal .htd-common-action-modal-list-content .ant-collapse-content-box p > span:not(.anticon) {
  max-width: calc(100% - 22px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.htd-common-action-modal .htd-common-action-modal-list-content .ant-collapse-content-box p > .anticon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.htd-common-action-modal .htd-common-action-modal-list-content p {
  margin: 0;
  padding: 0 8px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.htd-common-action-modal .htd-common-action-modal-list-content p.active {
  color: #e27279;
  background-color: rgba(242, 242, 242, 0.47);
}
.htd-common-action-modal .htd-common-action-modal-list-content p:hover {
  background-color: #eee;
}
.htd-common-action-modal .htd-common-action-modal-form {
  height: 100%;
  overflow-y: auto;
  padding: 12px;
}
.htd-common-action-modal .htd-common-action-modal-form .ant-form-item {
  margin-bottom: 12px;
}
.htd-common-action-modal .htd-common-action-modal-form .ant-col-6 {
  flex: 0 0 22%;
  max-width: 22%;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-aside-model-right-collapse-box {
  background-color: #fff;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-aside-model-right-collapse-box .ant-collapse-item {
  border-bottom: none;
  background-color: #fff;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-aside-model-right-collapse-box .ant-collapse-header {
  background-color: #fff;
  line-height: 44px;
  padding: 0;
  height: 44px;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-aside-model-right-collapse-box .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-aside-model-right-collapse-box .ant-collapse-content .ant-form-item {
  height: 44px;
  margin-bottom: 6px;
  height: fit-content;
}
.htd-common-action-modal .htd-common-action-modal-form .other-param-box .ant-form-item-control {
  height: fit-content;
}
.htd-common-action-modal .htd-common-action-modal-form .other-param-box .ant-form-item-control-input {
  height: auto !important;
  width: 95%;
  min-height: auto !important;
}
.htd-common-action-modal .htd-common-action-modal-form .other-param-box .ant-form-item-control-input .ant-form-item-control-input-content {
  height: auto !important;
}
.htd-common-action-modal .htd-common-action-modal-form .other-param-box .ant-form-item-control-input .ant-form-item-control-input-content .ant-select-selection-search-input {
  height: 28px;
}
.htd-common-action-modal .htd-common-action-modal-form .other-param-item-width {
  border-bottom: 1px solid #ececec;
}
.htd-common-action-modal .htd-common-action-modal-form .other-param-item-width:last-child {
  border-bottom: none;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-common-action-modal-input-array-box {
  margin-bottom: 8px;
  position: relative;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-common-action-modal-input-array-box .htd-common-action-modal-input-array-line-action {
  position: absolute;
  width: 20px;
  top: 6px;
  right: -24px;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-common-action-modal-input-array-box .htd-common-action-modal-input-array-line-action .anticon {
  cursor: pointer;
  color: #999;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-common-action-modal-input-array-box .htd-common-action-modal-input-array-line-action .anticon:hover {
  color: #e27279;
}
.htd-common-action-modal .htd-common-action-modal-form .htd-common-action-modal-input-array-box .htd-common-action-modal-input-array-line-action .anticon + .anticon {
  margin-left: 6px;
}
.htd-common-action-modal-input-array .htd-common-action-modal-input-array-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.htd-common-action-modal-input-array .htd-common-action-modal-input-array-line + .htd-common-action-modal-input-array-line {
  margin-top: 8px;
}
.htd-common-action-modal-input-array .htd-common-action-modal-input-array-line:nth-child(n + 2):last-child .htd-common-action-modal-input-array-line-input {
  width: calc(100% - 40px);
}
.htd-common-action-modal-input-array .htd-common-action-modal-input-array-line .htd-common-action-modal-input-array-line-input {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.htd-common-action-modal-input-array.htd-common-action-modal-input-array-key-value .htd-common-action-modal-input-array-line .htd-common-action-modal-input-array-line-input > input:first-child {
  width: 40%;
}
.htd-common-action-modal-input-array.htd-common-action-modal-input-array-key-value .htd-common-action-modal-input-array-line .htd-common-action-modal-input-array-line-input > input:last-child {
  width: calc(60% - 6px);
}
.htd-common-action-modal-input-array.htd-common-action-modal-input-array-key-value .htd-common-action-modal-input-array-line .htd-common-action-modal-input-array-line-input > input + .htd-variable-input {
  width: calc(60% - 6px);
  margin-left: 6px;
  height: 28px;
}
.htd-common-action-modal-input-array.htd-common-action-modal-input-array-key-value .htd-common-action-modal-input-array-line .htd-common-action-modal-input-array-line-input > input + .htd-variable-input .c7n-input-wrapper {
  height: 28px;
}
.add-action-box {
  margin-top: 12px;
}
.add-action-box .t-j-api-model-box {
  width: 20%;
  padding: 0 0 12px 0;
}
.add-action-box .t-j-api-model-box svg {
  width: 14px !important;
  height: 14px !important;
  margin-right: 4px;
}
.add-action-box .t-j-api-model-box .t-j-api-model-box-title {
  top: 0;
}
