/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-code-viewer {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.htd-code-viewer-header {
  flex: 0 0 36px;
}
.htd-code-viewer-header .htd-code-viewer-header-file-active {
  background-color: #e27277;
  border-radius: 1px;
  color: #fff;
}
.htd-code-viewer-header ul {
  margin: 0;
  padding: 0;
}
.htd-code-viewer-header ul li {
  display: inline-block;
  padding: 0 12px;
  margin-right: 8px;
  line-height: 28px;
  cursor: pointer;
}
.htd-code-viewer-header-file {
  margin-right: 8px;
  background-color: #fff;
  color: #333;
  border-radius: 2px;
  transition: all 0.35s;
}
.htd-code-viewer-header-file-active {
  background-color: #f6e9ea;
  color: #e27279;
}
.htd-code-viewer-main {
  flex: 1;
  font-size: 12px;
  line-height: 1.5;
}
.htd-code-viewer-main-pre > pre {
  height: 73vh;
  overflow-y: auto;
}
