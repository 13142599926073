.htd-placeholder-form {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}
.htd-placeholder-form > .htd-drop-area {
  min-height: 100px;
}
.htd-placeholder-form > .htd-drop-area .htd-placeholder-form-field-content {
  width: calc(100% - 86px) !important;
}
.htd-placeholder-form-useColon .htd-placeholder-form-field-label:after {
  content: ':';
  margin-left: 1px;
}
.htd-placeholder-form::after {
  display: block;
  content: '';
  clear: both;
}
