/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-aside-right {
  height: 100%;
  overflow: hidden;
  border-left: 1px solid #efefef;
}
.htd-aside-right-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0 8px;
  font-size: 14px;
  line-height: 32px;
  border-bottom: 1px solid #dfdfdf;
}
.htd-aside-right-header i.icon {
  cursor: pointer;
}
.htd-aside-right-header i.icon:hover {
  color: #e27279;
}
.htd-aside-right-main {
  height: calc(100% - 41px);
  overflow-y: auto;
}
.htd-aside-right-main .ant-collapse {
  border: none;
  border-radius: 0;
  background-color: transparent !important;
}
.htd-aside-right-main .ant-collapse .ant-collapse-header {
  padding: 8px 0px !important;
  height: 36px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.htd-aside-right-main .ant-collapse .ant-collapse-header .ant-collapse-arrow {
  margin-left: 12px;
  margin-right: 8px !important;
}
.htd-aside-right-main .ant-collapse .ant-collapse-item {
  border-radius: 0 !important;
  border-bottom: none;
  margin-bottom: 8px;
}
.htd-aside-right-main .ant-collapse .ant-collapse-content {
  background-color: transparent !important;
  border-top: none !important;
}
.htd-aside-right-main .ant-collapse .ant-collapse-content-box {
  padding: 0 12px 4px 12px;
}
.htd-aside-right-main .ant-form .ant-radio-group .ant-radio-button-wrapper {
  padding: 0 8px !important;
  line-height: 28px;
  font-size: 12px;
}
.htd-aside-right-main .ant-form .ant-form-item {
  justify-content: space-between;
}
.htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
  max-width: 69%;
}
.htd-aside-right-main .ant-form .ant-form-item .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.htd-aside-right-main .ant-form .ant-form-item .ant-form-item-control-input-content .htd-variable-input {
  justify-content: flex-end;
}
.htd-aside-right-main .ant-form .ant-form-item .ant-form-item-control-input-content > div {
  width: 100%;
}
.htd-aside-right-main .ant-form .ant-form-item .ant-form-item-control-input-content > .ant-radio-group.ant-radio-group-outline {
  display: flex;
  justify-content: flex-end;
}
.htd-aside-right-main .ant-form .ant-table-cell .ant-form-item-control {
  max-width: 100%;
}
.htd-aside-right-main-section {
  margin-top: 8px;
}
.htd-aside-right-main-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  padding-left: 6px;
}
.htd-aside-right-main-section-title:before {
  content: '';
  width: 2px;
  height: 12px;
  position: absolute;
  left: 0;
  top: calc(50% - 6px);
  background-color: #e27279;
}
.htd-aside-right .ant-radio-button-wrapper {
  flex: 1 !important;
  text-align: center;
  color: #e27279 !important;
}
.htd-aside-right .ant-radio-button-wrapper-checked {
  background-color: #e27279 !important;
  color: #ffffff !important;
  border: 1px solid #e27279 !important;
  box-shadow: none !important;
}
.htd-aside-right .c7n-pro-icon-picker-wrapper {
  width: 100%;
}
.htd-aside-right .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #e27279 !important;
}
.htd-aside-right .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #e27279 !important;
  border: none !important;
}
.box-header {
  border-bottom: none;
}
