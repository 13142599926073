@import '../../../utils/styles/variables.less';

.htd-canvas-wrap {
  padding: 12px;
  background-color: #ededed;
}

.canvas-header-box {
  // height: 60px;
  height: 44px;
  background-color: #fff;
  margin-bottom: 12px;
  margin-top: 2px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  // padding: 5px 12px;
  // border: 1px dashed red;
}

.htd-canvas {
  position: relative;
  width: 100%;
  height: calc(100% - 56px);
  // height: 100%;
  // padding: 16px 12px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  overflow-y: auto;

  &-droppable-root-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible !important;
    overflow-y: auto;
  }

  &-droppable-container {
    transition: all 0.2s;
  }

  &-droppable-inner-container {
    width: 100%;
    min-height: 100%;
  }

  // 画布修改 去掉 最外层 的边框

  // &-draggable-container {
  //   border: 1px dashed transparent;
  // }

  &-draggable-dragging {
    background-color: #c4c1c1 !important;
    border: 1px solid transparent !important;

    .htd-drag-item-container-title,
    .htd-drag-item-container-header {
      display: none !important;
    }
  }
}

.htd-canvas-height {
  // 内容
  .htd-drag-item-container {
    // height: fit-content !important;
    height: fit-content;
    // min-height: calc(100% - 74px) !important;
  }
}

.htd-canvas-height2 {
  height: calc(100% - 94px);
}

// .htd-canvas-header {
//   height: 60px;
//   margin-bottom: 30px;
//   background-color: #fff;
// }
// .htd-canvas-content {
//   height: 400px;
//   background-color: #fff;
// }

.canvas-footer-box {
  height: 44px;
  background-color: #fff;
  margin-bottom: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;