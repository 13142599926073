@import '../../utils/styles/variables.less';

.htd-common-config-panel {
  // 输入框长度样式
  .ant-form-item {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
  }
  .ant-form-item-label {
    width: 75px;
  }
  .ant-form-item-label > label {
    width: 75px;
  }
  // .ant-form-item-control {
  //   width: 190px;
  //   .ant-form-item-control-input {
  //     width: 190px;
  //   }
  // }
  .ant-col-8 {
    flex: 0 0 30.5%;
  }
  .ant-input-number {
    width: 100%;
  }
  &-section {
    &-header {
      position: relative;
      font-size: 12px;
      line-height: 16px;
      padding-left: 6px;
      &:before {
        content: '';
        width: 2px;
        height: 12px;
        position: absolute;
        left: 0;
        top: calc(50% - 6px);
        background-color: @canvas-border-color;
      }
    }
    &-body {
      margin-top: 8px;
    }
  }
}
.common-config-panel-box {
  .ant-form-item-control {
    width: 190px;
  }
}
.ant-form-item-label {
  height: 28px;
}
.ant-form-item-control {
  height: 28px;
}
.ant-form-item-label > label {
  height: 28px !important;
  color: #666 !important;
}
// 数据字段 的输入框选择 去掉边框
.auto-complete-box {
  > div > div > div > div > div > div > span > input {
    border: none !important;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;