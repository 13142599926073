@import '~antd/lib/style/themes/default.less';

.htd-aside-right-main-validator {
  .ant-form-item {
    // 去掉 校验 的下边框
    border-bottom: none !important;
    .ant-form-item-extra {
      position: absolute;
      right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      > .anticon {
        cursor: pointer;

        &:hover {
          color: @primary-color;
        }
      }
    }
  }
  .antd-form-number {
    position: absolute;
    left: -160px;
  }
  .ant-form-item-extra {
    cursor: pointer;
  }
}

.htd-validate-test.ant-form {
  .ant-form-item {
    justify-content: space-between;
    > .ant-form-item-control {
      max-width: 69%;
      display: flex;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .htd-variable-input {
        justify-content: flex-end;
      }
      > div {
        width: 100%;
      }
      > .ant-radio-group.ant-radio-group-outline {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;