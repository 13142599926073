.tabs-pane {
  border: none;
  height: 100px;
}

.htd-placeholder-spot
  > div.htd-drop-area
  > div.c7n-tabs
  > div.c7n-tabs-bar
  > div.c7n-tabs-nav-container
  > div.c7n-tabs-nav-wrap
  > div.c7n-tabs-nav-scroll
  > div.c7n-tabs-nav
  > div.c7n-tabs-tab {
  margin-right: 0 !important;
}

.htd-placeholder-steps
  > div.htd-drop-area
  > div.c7n-tabs
  > div.c7n-tabs-bar
  > div.c7n-tabs-nav-container
  > div.c7n-tabs-nav-wrap
  > div.c7n-tabs-nav-scroll
  > div.c7n-tabs-nav {
  display: flex;
  & > div.c7n-tabs-ink-bar {
    display: none !important;
  }
  & > div.c7n-tabs-tab {
    padding: 16px 0;
    margin-right: 16px;
    flex: 1 1;
    &:last-child {
      flex: none;
      margin-right: 0;
    }
    & > .c7n-steps-item {
      width: 100%;
      & > .c7n-steps-item-icon {
        & > i.icon {
          color: #000;
        }
      }
    }

    .c7n-steps-item-line {
      position: absolute;
      top: 0.14rem;
      left: 100%;
      display: block;
      width: 99.99rem;
      height: 0.01rem;
      background: rgba(0, 0, 0, 0.26);
    }
    .step-hidden {
      display: none;
    }
    .c7n-steps-item-spot {
      padding: 28px 0 16px;
      position: relative;
      & > .c7n-steps-item-line {
        left: 36%;
        height: 3px;
      }
      .c7n-steps-item-dot {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.36);
        left: 20px;
        top: 10px;
      }
      .c7n-error-dot {
        background-color: #d50000;
      }
      .c7n-finish-dot {
        background-color: #3f51b5;
      }
      .c7n-active-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }
    }
    .font-size13 {
      font-size: 0.13rem !important;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;