/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-drag-item-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.htd-drag-item-container-active {
  border: 2px solid #e27279 !important;
}
.htd-drag-item-container-active > .htd-drag-item-container-title,
.htd-drag-item-container-active > .htd-drag-item-container-header {
  display: flex;
  background-color: #e27279;
  color: #fff;
  font-size: 12px;
  border-radius: 1px;
  height: 20px;
}
.htd-drag-item-container-active img {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
}
.htd-drag-item-container-title {
  position: absolute;
  top: -3px;
  left: 0;
  padding: 0 4px;
  display: none;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  background-color: transparent;
  color: #e27279;
  font-weight: bold;
  z-index: 2;
}
.htd-drag-item-container-header {
  position: absolute;
  top: -3px;
  right: 0;
  transform: translateY(-100%);
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0px 2px;
  height: 18px;
  z-index: 2;
}
.htd-drag-item-container-header-action {
  color: #fff;
  background-color: #e27279;
  line-height: 0;
  cursor: pointer;
  transition: all 0.25s;
  margin-left: 2px;
}
.htd-drag-item-container-header-action svg {
  color: #fff !important;
  width: 14px !important;
  height: 14px !important;
}
.htd-drag-item-container-header-action svg:hover {
  color: #fff !important;
}
.htd-drag-item-container-header-action:hover {
  opacity: 0.6;
}
.htd-drag-item-container-header-action:not(:first-child) {
  margin-left: 4px;
}
.htd-drag-item-container-header-action-delete {
  margin-left: 2px;
}
.htd-drag-item-container-header-action-save {
  margin-right: 2px;
}
.htd-drag-item-container-main {
  position: relative;
  width: 100%;
  height: 100%;
}
.htd-drag-item-container-main .htd-placeholder-form-field .c7n-pro-select-wrapper {
  background-color: transparent;
}
.htd-category-select {
  width: 100%;
  display: inline-flex;
}
.htd-category-select .ant-select {
  flex: 0 0 203px;
}
.htd-category-select-add {
  display: none;
  flex: 0 0 auto;
  cursor: pointer;
  margin-left: 8px;
  line-height: 28px;
  font-size: 12px;
  color: #525a6e;
  letter-spacing: 0;
  font-weight: 400;
  transition: color 0.2s;
}
.htd-category-select-add:hover {
  color: #e27279;
}
.htd-category-select-add svg {
  margin-right: 2px;
}
.htd-block-save-modal .ant-form-item {
  margin-bottom: 12px;
}
.htd-block-save-modal .ant-form-item-has-error {
  margin-bottom: 20px;
}
.htd-block-save-modal .ant-modal-body {
  padding-bottom: 0 !important;
}
.htd-block-save-modal .ant-form-item-label {
  width: 80px;
  padding-right: 30px;
}
.htd-block-save-modal .ant-form-item-label label:after {
  display: none;
}
.htd-block-save-modal-tooltip-label {
  display: inline-flex;
  margin-right: -18px;
  align-items: center;
}
.htd-block-save-modal-tooltip-label svg {
  margin-left: 4px;
}
.htd-change-component-modal .ant-modal-body {
  padding-bottom: 0 !important;
}
.htd-change-component-modal .ant-modal-body .ant-form-item-control {
  height: auto;
}
.htd-change-component-modal .ant-modal-body .ant-form-item-control .ant-form-item-control-input {
  height: auto !important;
}
