@import '~antd/lib/style/themes/default.less';
@import '../../index.less';

.htd-mapping-table-config-panel-table {
  .c7n-pro-table-professional-toolbar {
    padding-top: 0 !important;

    .c7n-pro-table-toolbar {
      // text-align: left;
      padding: 0 !important;
    }
  }

  .c7n-pro-table-drag-column {
    > span {
      width: 18px !important;
      padding: 0 !important;
    }
  }

  .htd-mapping-table-config-panel-table-notice {
    > span {
      padding: 0 !important;
      > i {
        font-size: 12px;
      }
    }
  }

  .htd-mapping-table-config-panel-table-operator {
    > span {
      padding: 0 !important;
      button + button {
        margin-left: 0 !important;
      }
    }
  }

  table {
    width: 258px !important;
    > colgroup {
      > col:first-child {
        width: 0.2rem !important;
        min-width: 0.2rem !important;
      }
    }
    > thead {
      > tr {
        > th:nth-child(2) {
          left: 0.2rem !important;
        }
      }
    }
    > tbody {
      > tr {
        > td:nth-child(2) {
          left: 0.2rem !important;
        }
      }
    }
  }
}

.htd-aside-right-columns-button-wrapper {
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    width: 276px;
    height: 36px;
    background-color: @back !important;
    border-radius: 2px;
    color: #fff !important;
    border: none !important;
  }

  button {
    color: @primary-color;
    &:hover {
      color: @primary-color;
    }
  }
}

.htd-aside-right-columns-table {
  .htd-aside-right-columns-table-operator {
    .anticon {
      cursor: pointer;
      color: #999;
      &:hover {
        // color: @primary-color;
        color: #e27279;
      }
      & + .anticon {
        margin-left: 8px;
      }
    }
  }
}

.htd-aside-right-table-field-edit-panel {
  position: absolute;
  top: 0;
  right: 299px;
  width: 280px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #efefef;
  z-index: 99;
  overflow: hidden;
  margin-right: 1px;

  .htd-aside-right-table-field-validate-message-setting {
    cursor: pointer;
  }

  .htd-input-width {
    .ant-form-item-control-input-content {
      &::after {
        content: 'px';
        margin-left: 5px;
      }
    }
  }

  .c7n-pro-select-box {
    .c7n-pro-radio-button {
      padding: 0 8px !important;
    }
  }
}
.htd-aside-right-main-section {
  // 表格内容最大高度
  .ant-table-wrapper {
    max-height: 270px;
    overflow-y: auto;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;