@import '../index.less';

.htd-ds-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // height: 28px;
  // margin: 0 4px;
  // 输入框长度样式
  .ant-form-item {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
  }

  .ant-form-item-label {
    width: 75px;
  }

  .ant-form-item-control {
    width: 190px;

    .ant-form-item-control-input {
      width: 190px;
    }
  }

  .ant-form-horizontal {
    width: 100%;

    .ant-row,
    ant-form-item {
      margin-bottom: 0;
      color: #666666;
    }
  }

  &-add-box {
    font-size: 12px;
    cursor: pointer;
    color: #525a6e;
    height: 28px;
  }

  // &-add-box:hover {
  //     background-color: rgba(#525A6E,0.4);
  // }
  .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
    height: 28px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }

  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    .ant-select-selector {
      height: 28px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }

    // 下拉框hover
    .ant-select-selector:hover {
      border: 1px solid @back;
    }
  }

  input {
    height: 28px;
    font-size: 12px;
  }

  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 26px;
  }
}

.htd-config-panel {
  // position: relative;
  .ant-collapse-extra {
    position: relative;
  }

  &-setting {
    &-box {
      width: 32px;
      height: 31px;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;

      &-true {
        background-color: #ffffff;
      }
    }

    &-img {
      width: 16px;
      height: 16px;
    }
  }
}

// 内容部分
.pagination-form-box {
  // 输入框长度样式
  .ant-form-item {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
  }

  .ant-form-item-label {
    width: 75px;
  }

  .ant-form-item-control {
    width: 190px;

    .ant-form-item-control-input {
      width: 190px;
    }
  }
}

// 查询域 样式
.query-ant-from-box {
  margin: 0 8px !important;

  // 输入框长度样式
  .ant-form-item {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
  }

  .ant-form-item-label {
    width: 75px;
  }

  .ant-form-item-control {
    width: 190px;

    .ant-form-item-control-input {
      width: 190px;
    }
  }

  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    .ant-select-selector {
      height: 28px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }

  input {
    height: 28px;
    font-size: 12px;
  }
}

// input 和 选择框 高度变化
.htd-aside-right-main .ant-form .ant-form-item {
  margin-bottom: 12px;
  height: 28px;
  font-size: 12px;
}

.htd-aside-right-main .ant-form .ant-form-item:last-child {
  margin-bottom: 4px;
}

.ant-form-item-control-input {
  height: 28px !important;
  min-height: 28px !important;
  font-size: 12px;
}

.ant-radio-button-wrapper {
  font-size: 12px;
  height: 28px !important;
}

.ant-radio-button-wrapper > .ant-radio-button {
  height: 28px !important;
}

.resize-code-area {
  .CodeMirror {
    resize: vertical;
    min-height: 300px;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;