@import '~antd/lib/style/themes/default.less';

.htd-common-action-panel {
  // margin-top: 16px;
  .htd-common-action-panel-list {
    // margin-top: 12px;
    .left1-svg1-cla1 {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .htd-common-action-panel-item {
      > .ant-row {
        > .ant-col {
          padding: 4px;
        }

        &:first-child {
          > .ant-col:last-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 2px;
            background-color: #f2f2f2;

            > span:last-child {
              > span {
                cursor: pointer;
                color: #999;
                &:hover {
                  color: @primary-color;
                }

                & + span {
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
  .list-top-margin {
    margin-top: 12px;
  }
}

.htd-common-action-modal {
  .ant-col-14 {
    flex: 0 0 56%;
    max-width: 56%;
  }
  .top-title-box-row {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    &-left-box {
      .ant-col-4 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .ant-col-6 {
        flex: 0 0 58%;
        max-width: 58%;
      }
      display: flex;
      width: 43%;
      background-color: #fff;
      // 右侧属性折叠面板样式
      .ant-collapse {
        border: none;
        border-radius: 0;
        background-color: transparent !important;

        .ant-collapse-header {
          padding: 8px 4px !important;
          height: 36px;
          border-top: 1px solid #dbdbdb;
          border-bottom: 1px solid #dbdbdb;
          .ant-collapse-arrow {
            margin-left: 6px;
            margin-right: 8px !important;
          }
        }

        .ant-collapse-item {
          border-radius: 0 !important;
          border-bottom: none;
          margin-bottom: 8px;
        }

        .ant-collapse-content {
          background-color: transparent !important;
          // 干掉右侧属性面板 展开之后 多余的下边框
          border-top: none !important;
        }

        .ant-collapse-content-box {
          padding: 0 12px 4px 12px;
        }
      }
      .first-list-cf {
        .htd-common-action-modal-list {
          border-right: transparent;
        }
      }
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 20px !important;
  }
  .ant-modal-body {
    padding: 0 24px 20px 24px !important;
  }
  .ant-model-content {
    .ant-modal-footer {
      font-size: 14px;
    }
  }
  .ant-modal-footer {
    font-size: 14px;
    padding: 10px 24px !important;
    .ant-btn {
      height: 28px !important;
      line-height: 14px;
      font-size: 12px;
    }
  }
  .htd-common-action-modal-content {
    height: 400px;
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    font-size: 12px;
    &-action-type {
      margin-right: 8px;
    }
    &-action-from {
      border: 1px solid #d0d0d0;
      // 动作弹框 的下拉框里面的文字 感觉没有居中
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        line-height: 24px;
      }
      .ant-col-14 {
        flex: 0 0 54%;
        max-width: 54%;
      }
    }

    > .ant-col {
      height: 100%;
    }
  }

  .htd-common-action-modal-list {
    height: 100%;
    border: 1px solid #d0d0d0;
    // background-color: #f7f7f7;
    font-size: 12px;
    &-search {
      padding: 8px 4px 0 4px;
      // 动作弹框 中 查询组件样式
      /* 搜索框 默认提示文字 偏移问题 */
      .c7n-input-label-wrapper {
        top: -2px;
      }
      .c7n-input-content {
        height: 28px;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        border-radius: 2px !important;
        .c7n-input-rendered-wrapper {
          height: 28px !important;
          .c7n-input-rendered-sm {
            height: 28px !important;
            .c7n-input {
              height: 28px !important;
            }
            .c7n-input-content
              .c7n-input-rendered-wrapper
              .c7n-input-rendered-sm
              .c7n-input-label {
              line-height: 27px;
            }
          }
          .c7n-input-suffix {
            top: 1px;
          }
        }
      }
      .c7n-input-content:hover {
        border-color: #e27279 !important;
      }
      svg {
        color: #a0a0ad;
      }
    }

    &-content {
      height: calc(100% - 48px);
      overflow-y: auto;

      .ant-collapse-header {
        padding: 0 8px !important;
      }
      p {
        padding-left: 16px;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
        p {
          padding-left: 32px;

          display: flex;

          > span:not(.anticon) {
            max-width: calc(100% - 22px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > .anticon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
          }
        }
      }

      p {
        margin: 0;
        padding: 0 8px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          // color: @primary-color;
          color: #e27279;
          background-color: rgba(242, 242, 242, 0.47);
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  // .htd-common-action-modal-list:first-child {
  //   border-right: transparent;
  // }

  .htd-common-action-modal-form {
    height: 100%;
    overflow-y: auto;
    padding: 12px;
    .ant-form-item {
      margin-bottom: 12px;
    }
    .ant-col-6 {
      flex: 0 0 22%;
      max-width: 22%;
    }
    .htd-aside-model-right-collapse-box {
      background-color: #fff;
      .ant-collapse-item {
        border-bottom: none;
        background-color: #fff;
      }
      .ant-collapse-header {
        background-color: #fff;
        line-height: 44px;
        padding: 0;
        height: 44px;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
        }
        .ant-form-item {
          // background-color: #F9F9F9;
          height: 44px;
          margin-bottom: 6px;
          // display: flex;
          // align-items: center;
          height: fit-content;
        }
      }
    }
    // 设置fromItem 样式
    .other-param-box {
      .ant-form-item-control {
        height: fit-content;
        // text-align: end;
        // max-height: 100px;
        // overflow-y: auto;
      }
      .ant-form-item-control-input {
        height: auto !important;
        width: 95%;
        min-height: auto !important;
        .ant-form-item-control-input-content {
          height: auto !important;
          .ant-select-selection-search-input {
            height: 28px;
          }
        }
      }
    }
    .other-param-item-width {
      border-bottom: 1px solid #ececec;
    }
    .other-param-item-width:last-child {
      border-bottom: none;
    }
    // 将 删除按钮从列里面拿出来
    .htd-common-action-modal-input-array-box {
      margin-bottom: 8px;
      position: relative;
      .htd-common-action-modal-input-array-line-action {
        // display: flex;
        // justify-content: flex-end;
        position: absolute;
        width: 20px;
        top: 6px;
        right: -24px;

        .anticon {
          cursor: pointer;
          color: #999;

          &:hover {
            // color: @primary-color;
            color: #e27279;
          }

          & + .anticon {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

.htd-common-action-modal-input-array {
  .htd-common-action-modal-input-array-line {
    display: flex;
    // justify-content: space-between;
    justify-content: flex-start;
    align-items: center;

    & + .htd-common-action-modal-input-array-line {
      margin-top: 8px;
    }

    // 将删除按钮 从列里面拿出来
    // &:first-child {
    //   .htd-common-action-modal-input-array-line-input {
    //     width: calc(100% - 18px);
    //   }
    // }

    &:nth-child(n + 2):last-child {
      .htd-common-action-modal-input-array-line-input {
        width: calc(100% - 40px);
      }
    }

    .htd-common-action-modal-input-array-line-input {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }

  &.htd-common-action-modal-input-array-key-value {
    .htd-common-action-modal-input-array-line {
      .htd-common-action-modal-input-array-line-input {
        > input {
          &:first-child {
            width: 40%;
          }

          &:last-child {
            width: calc(60% - 6px);
          }

          & + .htd-variable-input {
            width: calc(60% - 6px);
            margin-left: 6px;
            height: 28px;
            .c7n-input-wrapper {
              height: 28px;
            }
          }
        }
      }
    }
  }
}
.add-action-box {
  margin-top: 12px;
  .t-j-api-model-box {
    width: 20%;
    padding: 0 0 12px 0;
    svg {
      width: 14px !important;
      height: 14px !important;
      margin-right: 4px;
    }
    .t-j-api-model-box-title {
      top: 0;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;