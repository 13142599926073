@import '../../../utils/styles/variables.less';

.htd-drag-box {
  position: relative;
  cursor: grab;
  // 容器边框
  .htd-drag-item-container {
    border: 1px dashed transparent;
    // border: 1px dashed rgba(0, 0, 0, 0.15);
  }
  &-hover {
    & > .htd-drag-box-content {
      & > .htd-drag-item-container {
        // border-color: @canvas-border-color;
        border-color: rgba(0, 0, 0, 0.15);
        background-color: #f2f2f2;
        & > .htd-drag-item-container-title {
          display: flex;
        }
      }
    }
  }
  &-fixed {
    cursor: pointer;
  }
  &-placeholder {
    flex: 1 1 100%;
    border: none !important;
    &-inline-block {
      flex: none !important;
      width: auto !important;
    }
  }
  &-mask {
    position: absolute;
    height: calc(100% - 8px);
    top: 4px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @common-bg;
    color: @font-color;
    right: 4px;
    transition: all 0.25s ease;
    border-radius: @border-radius;
    z-index: 10;
    &:hover {
      background-color: @common-bg-hover;
      color: @font-color-hover;
    }
    &-placeholder {
      position: relative;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      // 拖拽位置可放置 上面的 横线
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border: 1px solid @primary-color;
      }
      &.htd-drag-box-mask-placeholder-inline-block {
        &::after {
          width: 2px !important;
          height: 20px !important;
        }
      }
      // .htd-drag-drop-placeholder {
      //   border: none !important;
      //   width: 100%;
      //   height: 48px;
      //   background: fade(@primary-color, 20%);
      //   border: 1px dashed @primary-color;
      //   color: @primary-color;
      //   text-align: center;
      //   line-height: 48px;
      // }
    }
  }
  &-sortable &-content {
    padding-right: 58px;
  }
  &-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
.box-border {
  border: 1px solid red;
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;