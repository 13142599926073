@import '~codemirror/theme/material.css';
.htd-variable-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.htd-variable-input .ant-input-group-addon {
  padding: 0;
}
.htd-variable-input .htd-variable-input-icon-fx {
  padding: 0 11px;
  cursor: pointer;
}
.htd-variable-input .htd-variable-input-icon {
  display: flex;
  background: #fafafa;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.htd-variable-input-modal-wrap {
  z-index: 1999 !important;
}
.htd-variable-input-modal-wrap .ant-modal-header {
  border-bottom: none;
  padding: 24px 16px 16px 24px !important;
}
.htd-variable-input-modal-wrap .ant-modal-footer {
  position: relative;
  height: 59.2px;
  line-height: 38px;
  padding: 16px 20px !important;
}
.htd-variable-input-modal-wrap .ant-modal-footer .left-button-box {
  position: absolute;
  left: 24px;
  top: 21px;
  background-color: #525a6e !important;
  color: #fff !important;
  border-color: #525a6e !important;
}
.htd-variable-input-modal-wrap .ant-modal-footer .ant-btn {
  height: 28px;
  line-height: 14px;
}
.htd-variable-input-modal-wrap .ant-modal-footer span {
  font-size: 12px;
}
.htd-variable-input-modal-wrap .ant-col-6 {
  flex: 0 0 30%;
  max-width: 30%;
}
.htd-variable-input-modal-wrap .ant-col-18 {
  flex: 0 0 67%;
  max-width: 67%;
}
.htd-variable-input-modal-wrap .variable-top-menu-row {
  font-size: 14px;
  margin-bottom: 12px;
  margin-left: 16px;
}
.htd-variable-input-modal-wrap .variable-middle-menu-row {
  margin-left: 16px;
  font-size: 12px;
}
.htd-variable-input-modal-wrap .variable-middle-menu-row-col-left {
  margin-right: 16px;
}
.htd-variable-input-modal-wrap .ant-modal-body {
  padding: 0 16px 20px 8px !important;
}
.htd-variable-input-modal-title {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 500;
}
.htd-variable-input-modal-chosepanel {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 2px;
}
.htd-variable-input-modal-menu {
  font-size: 12px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.htd-variable-input-modal-menu .search-box {
  margin-top: 8px;
  padding: 0 8px;
}
.htd-variable-input-modal-menu .search-box .c7n-input-suffix {
  top: 3px;
}
.htd-variable-input-modal-menu .search-box .left1-svg1-cla1 svg {
  color: #a0a0ad;
}
.htd-variable-input-modal-menu .htd-config-panel {
  border: none !important;
}
.htd-variable-input-modal-menu .ant-collapse-content-box {
  border: none !important;
}
.htd-variable-input-modal-menu .ant-collapse-item {
  border: none !important;
}
.htd-variable-input-modal-menu .ant-collapse-item-active {
  border: none !important;
}
.htd-variable-input-modal-menu .ant-collapse-content {
  border: none !important;
}
.htd-variable-input-modal-menu .ant-collapse-header {
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  padding-left: 8px !important;
}
.htd-variable-input-modal-menu .ant-collapse-header .ant-collapse-arrow {
  margin-right: 6px !important;
}
.htd-variable-input-modal-menu .ant-collapse-content-box {
  padding: 0 !important;
}
.htd-variable-input-modal-menu .data-list-item {
  padding-left: 24px;
  line-height: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 12px;
}
.htd-variable-input-modal-menu .data-list-item:hover {
  background-color: rgba(242, 242, 242, 0.47);
  color: #e27279;
}
.htd-variable-input-modal-menu .ant-menu-submenu-selected {
  background: rgba(242, 242, 242, 0.47) !important;
}
.htd-variable-input-modal-menu .ant-menu-item-selected {
  background: rgba(242, 242, 242, 0.47) !important;
  color: #e27279;
}
.htd-variable-input-modal-menu .ant-menu-item :hover {
  color: #e27279;
}
.htd-variable-input-modal-menu .ant-menu-sub.ant-menu-inline {
  background: #ffffff;
}
.htd-variable-input-modal-menu .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.htd-variable-input-modal-menu .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.htd-variable-input-modal-menu .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.htd-variable-input-modal-menu .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  background: #f2f2f2;
  margin: 0;
  height: 32px;
}
.htd-variable-input-modal-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.htd-variable-input-modal-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 36px;
}
.htd-variable-input-modal-menu .ant-menu-inline .ant-menu-selected::after,
.htd-variable-input-modal-menu .ant-menu-inline .ant-menu-item-selected::after {
  transform: none;
}
.htd-variable-input-modal-example {
  margin: 0 0 16px 16px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #999999;
  letter-spacing: 0;
  font-weight: 400;
}
.htd-variable-input-modal-example .code {
  background-color: #eaeaea;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  margin-right: 4px;
}
.htd-variable-input-modal-config {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.htd-variable-input-modal-code-wapper .c7n-pro-code-area .CodeMirror {
  border-radius: 2px !important;
  height: 180px !important;
}
.htd-variable-input-modal-code-helper {
  height: 49%;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d0d0;
  border-radius: 2px;
  position: relative;
  padding: 16px 8px;
  margin-left: 0;
  margin-bottom: 0;
}
.title-yf {
  position: absolute;
  top: -12px;
  left: 12px;
  width: 35px;
  text-align: center;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
.title-ms {
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
}
