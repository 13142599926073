.htd-placeholder-form {
  padding: 8px;
  // border: 1px dashed #e8e8e8;
  background-color: rgba(0, 0, 0, 0.05);

  > .htd-drop-area {
    min-height: 100px;

    .htd-placeholder-form-field {
      &-content {
        width: calc(100% - 86px) !important;
      }
    }
  }
  &-useColon {
    .htd-placeholder-form-field-label:after {
      content: ':';
      margin-left: 1px;
    }
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;