.htd-aside-left-main-dataSet {
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}
.htd-aside-left-main-dataSet .ant-collapse-borderless {
  background-color: #fff;
}
.htd-aside-left-main-dataSet > button {
  width: calc(100% - 16px);
  margin: 0 8px 8px 8px;
}
.htd-aside-left-main-dataSet-collapse {
  height: calc(100% - 36px);
  overflow-y: auto;
  font-size: 12px !important;
}
.htd-aside-left-main-dataSet-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px !important;
}
.htd-aside-left-main-dataSet-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-arrow {
  margin-right: 8px !important;
}
.htd-aside-left-main-dataSet-collapse .htd-aside-left-main-dataSet-collapse-panel-bottom {
  border: none !important;
  padding: 0 8px;
}
.htd-aside-left-main-dataSet-collapse .htd-aside-left-main-dataSet-collapse-panel-bottom .ant-collapse-content-box {
  padding: 0;
}
.htd-aside-left-main-dataSet-collapse .htd-aside-left-main-dataSet-collapse-panel-bottom .ant-collapse-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  font-size: 12px;
}
.htd-aside-left-main-dataSet-collapse .ant-collapse-content .htd-ds-config-back {
  background-color: transparent;
}
.htd-aside-left-main-dataSet-collapse-panel-bottom {
  margin-bottom: 12px;
}
.htd-aside-left-main-dataSet .item-back {
  background-color: red;
}
.htd-aside-left-main-dataSet .item-back:hover {
  background-color: blue;
}
.htd-collapse-edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 22px);
}
.htd-collapse-edit-header .c7n-pro-input-wrapper label {
  height: 28px;
}
.htd-collapse-edit-header .c7n-pro-input-wrapper label .c7n-pro-input {
  height: 28px;
  border-color: #d9d9d9;
}
.htd-collapse-edit-header-imgIcon {
  width: 16px;
  height: 16px;
  color: #e0e0e0;
  cursor: pointer;
  opacity: 0;
  position: relative;
  left: 6px;
}
.htd-collapse-edit-header-input {
  background-color: transparent !important;
  width: 190px;
  height: 28px;
}
.htd-collapse-edit-header-input input {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
}
.htd-collapse-edit-header-input input:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 5px;
  background-color: #fff;
}
.htd-collapse-edit-header:hover .htd-collapse-edit-header-imgIcon {
  color: #e27279 !important;
  opacity: 1;
}
.htd-collapse-edit-header:hover .c7n-pro-input {
  border: 1px solid #d9d9d9;
  background-color: #fff !important;
  border-radius: 2px;
  padding-left: 5px;
  cursor: pointer;
}
.left-collapse-box .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0 !important;
}
.c7n-pro-input:focus {
  cursor: text !important;
}
