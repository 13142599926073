@import '~codemirror/theme/material.css';

.htd-variable-input {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-input-group-addon {
    padding: 0;
  }

  .htd-variable-input-icon-fx {
    padding: 0 11px;
    cursor: pointer;
  }

  .htd-variable-input-icon {
    display: flex;
    background: #fafafa;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
}

.htd-variable-input-modal-wrap {
  .ant-modal-header {
    border-bottom: none;
    padding: 24px 16px 16px 24px !important;
  }
  .ant-modal-footer {
    position: relative;
    height: 59.2px;
    line-height: 38px;
    padding: 16px 20px !important;
    .left-button-box {
      position: absolute;
      left: 24px;
      top: 21px;
      background-color: #525a6e !important;
      color: #fff !important;
      border-color: #525a6e !important;
    }
    .ant-btn {
      height: 28px;
      line-height: 14px;
    }
    span {
      font-size: 12px;
    }
  }

  z-index: 1999 !important;
  .ant-col-6 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .ant-col-18 {
    flex: 0 0 67%;
    max-width: 67%;
  }
  .variable-top-menu-row {
    font-size: 14px;
    margin-bottom: 12px;
    margin-left: 16px;
  }
  .variable-middle-menu-row {
    margin-left: 16px;
    font-size: 12px;
    &-col-left {
      margin-right: 16px;
    }
  }

  .ant-modal-body {
    padding: 0 16px 20px 8px !important;
  }
}

.htd-variable-input-modal-title {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 500;
}

.htd-variable-input-modal-chosepanel {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 2px;
}

.htd-variable-input-modal-menu {
  font-size: 12px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  .search-box {
    margin-top: 8px;
    padding: 0 8px;
    .c7n-input-suffix {
      top: 3px;
    }
    .left1-svg1-cla1 {
      svg {
        color: #a0a0ad;
      }
    }
  }
  .htd-config-panel {
    border: none !important;
  }
  .ant-collapse-content-box {
    border: none !important;
  }
  .ant-collapse-item {
    border: none !important;
  }
  .ant-collapse-item-active {
    border: none !important;
  }
  .ant-collapse-content {
    border: none !important;
  }
  .ant-collapse-header {
    height: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    padding-left: 8px !important;
    .ant-collapse-arrow {
      margin-right: 6px !important;
    }
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .data-list-item {
    padding-left: 24px;
    line-height: 32px;
    height: 32px;
    cursor: pointer;
    font-size: 12px;
  }
  .data-list-item:hover {
    background-color: rgba(242, 242, 242, 0.47);
    color: #e27279;
  }

  .ant-menu-submenu-selected {
    background: rgba(242, 242, 242, 0.47) !important;
  }

  .ant-menu-item-selected {
    background: rgba(242, 242, 242, 0.47) !important;
    color: #e27279;
  }

  .ant-menu-item {
    :hover {
      color: #e27279;
    }
  }

  .ant-menu-sub.ant-menu-inline {
    background: #ffffff;
  }

  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    background: #f2f2f2;
    margin: 0;
    height: 32px;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 36px;
  }

  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    transform: none;
  }
}

.htd-variable-input-modal-example {
  margin: 0 0 16px 16px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #999999;
  letter-spacing: 0;
  font-weight: 400;

  .code {
    background-color: #eaeaea;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.htd-variable-input-modal-config {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.htd-variable-input-modal-code-wapper {
  // margin: 6px;
  // border-style: solid;
  // border-width: 1px;
  // border-color: #c7c7c7;
  // border-radius: 4px;

  .c7n-pro-code-area {
    .CodeMirror {
      border-radius: 2px !important;
      height: 180px !important;
    }
  }
}

.htd-variable-input-modal-code-helper {
  // margin: 6px;
  height: 49%;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d0d0;
  border-radius: 2px;
  position: relative;
  padding: 16px 8px;
  margin-left: 0;
  margin-bottom: 0;
}
.title-yf {
  position: absolute;
  top: -12px;
  left: 12px;
  width: 35px;
  text-align: center;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
.title-ms {
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;