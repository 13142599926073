/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-aside-right-main-validator .self-validator-box {
  width: 84px;
  height: 28px;
  border: 2px solid #e27279;
  margin: 16px 0 6px 6px;
  font-size: 12px;
  color: #e27279;
  text-align: center;
  line-height: 24px;
  border-radius: 2px;
}
.htd-aside-right-main-validator .validate-list-img {
  width: 16px;
  height: 16px;
}
.htd-aside-right-main-validator .validate-list-img:hover {
  color: #e27279 !important;
}
.htd-aside-right-main-validator .ant-checkbox-checked {
  background-color: #e27279;
}
.htd-aside-right-main-validator .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e27279;
  border-radius: 2px;
}
.htd-aside-right-main-validator .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.htd-aside-right-main-validator .ant-checkbox:hover .ant-checkbox-inner,
.htd-aside-right-main-validator .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #e27279;
}
.htd-aside-right-main-validator .ant-form-item {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 6px;
  margin-bottom: 12px;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra {
  right: 24.4px !important;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra > span > svg:hover {
  color: #e27279;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra > .anticon {
  cursor: pointer;
}
.htd-aside-right-main-validator .ant-form-item .ant-form-item-extra > .anticon:hover {
  color: #e27279;
}
.htd-validate-config-panel .ant-form-item {
  justify-content: space-between;
}
.htd-validate-config-panel .ant-form-item > .ant-form-item-control {
  max-width: 69%;
}
.htd-validate-config-panel .ant-form-item .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.htd-validate-config-panel .ant-form-item .ant-form-item-control-input-content .htd-variable-input {
  justify-content: flex-end;
}
.htd-validate-config-panel .ant-form-item .ant-form-item-control-input-content > div {
  width: 100%;
}
.htd-validate-config-panel .ant-form-item .ant-form-item-control-input-content > .ant-radio-group.ant-radio-group-outline {
  display: flex;
  justify-content: flex-end;
}
.antd-table-number {
  position: absolute;
  left: -136px;
}
.file-edit-panel-content-back .htd-aside-right-columns-button-wrapper .ant-btn {
  height: 36px;
  background-color: #e27279 !important;
  border-radius: 2px;
  color: #fff !important;
  border: none !important;
}
.file-edit-panel-content-back .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.file-edit-panel-content-back .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.file-edit-panel-content-back .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  border: none;
  font-size: 12px;
  height: 20px;
}
.file-edit-panel-content-back tr {
  background-color: #f8f8f8;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
}
.file-edit-panel-content-back th,
.file-edit-panel-content-back td {
  padding: 4px 2px !important;
}
.file-edit-panel-content-back th input,
.file-edit-panel-content-back td input {
  font-size: 12px;
}
.file-edit-panel-content-back th::before {
  background-color: transparent !important;
}
.file-edit-panel-content-back .htd-aside-right-columns-table-status {
  width: 0;
}
.file-edit-panel-content-back .htd-aside-right-columns-table-status .anticon {
  font-size: 12px;
}
.file-edit-panel-content-back .htd-aside-right-buttons-table-operator {
  width: 20%;
}
.file-edit-panel-content-back .htd-aside-right-buttons-table-operator .anticon {
  cursor: pointer;
}
.file-edit-panel-content-back .htd-aside-right-buttons-table-operator .anticon:hover {
  color: #e27279;
}
.file-edit-panel-content-back .htd-aside-right-buttons-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.file-edit-panel-content-back .htd-aside-right-columns-table-operator {
  width: 20%;
  color: #999;
}
.file-edit-panel-content-back .htd-aside-right-columns-table-operator .anticon {
  cursor: pointer;
}
.file-edit-panel-content-back .htd-aside-right-columns-table-operator .anticon:hover {
  color: #e27279;
}
.file-edit-panel-content-back .htd-aside-right-columns-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.file-edit-panel-content-back .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
  height: 28px;
}
.file-edit-panel-content-back .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px;
}
.file-edit-panel-content-back .ant-form-item .ant-select .ant-select-selector,
.file-edit-panel-content-back .ant-form-item .ant-cascader-picker .ant-select-selector {
  height: 28px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.file-edit-panel-content-back input {
  height: 28px;
  font-size: 12px;
}
.file-edit-panel-content-back .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 26px;
}
.htd-aside-right-table-field-edit-panel {
  z-index: 50;
}
