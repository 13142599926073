.htd-ds-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.htd-ds-box .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
.htd-ds-box .ant-form-item-label {
  width: 75px;
}
.htd-ds-box .ant-form-item-control {
  width: 190px;
}
.htd-ds-box .ant-form-item-control .ant-form-item-control-input {
  width: 190px;
}
.htd-ds-box .ant-form-horizontal {
  width: 100%;
}
.htd-ds-box .ant-form-horizontal .ant-row,
.htd-ds-box .ant-form-horizontal ant-form-item {
  margin-bottom: 0;
  color: #666666;
}
.htd-ds-box-add-box {
  font-size: 12px;
  cursor: pointer;
  color: #525a6e;
  height: 28px;
}
.htd-ds-box .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
  height: 28px;
}
.htd-ds-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px;
}
.htd-ds-box .ant-form-item .ant-select .ant-select-selector,
.htd-ds-box .ant-form-item .ant-cascader-picker .ant-select-selector {
  height: 28px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.htd-ds-box .ant-form-item .ant-select .ant-select-selector:hover,
.htd-ds-box .ant-form-item .ant-cascader-picker .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.htd-ds-box input {
  height: 28px;
  font-size: 12px;
}
.htd-ds-box .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 26px;
}
.htd-config-panel .ant-collapse-extra {
  position: relative;
}
.htd-config-panel-setting-box {
  width: 32px;
  height: 31px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.htd-config-panel-setting-box-true {
  background-color: #ffffff;
}
.htd-config-panel-setting-img {
  width: 16px;
  height: 16px;
}
.pagination-form-box .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
.pagination-form-box .ant-form-item-label {
  width: 75px;
}
.pagination-form-box .ant-form-item-control {
  width: 190px;
}
.pagination-form-box .ant-form-item-control .ant-form-item-control-input {
  width: 190px;
}
.query-ant-from-box {
  margin: 0 8px !important;
}
.query-ant-from-box .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
.query-ant-from-box .ant-form-item-label {
  width: 75px;
}
.query-ant-from-box .ant-form-item-control {
  width: 190px;
}
.query-ant-from-box .ant-form-item-control .ant-form-item-control-input {
  width: 190px;
}
.query-ant-from-box .ant-form-item .ant-select .ant-select-selector,
.query-ant-from-box .ant-form-item .ant-cascader-picker .ant-select-selector {
  height: 28px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.query-ant-from-box input {
  height: 28px;
  font-size: 12px;
}
.htd-aside-right-main .ant-form .ant-form-item {
  margin-bottom: 12px;
  height: 28px;
  font-size: 12px;
}
.htd-aside-right-main .ant-form .ant-form-item:last-child {
  margin-bottom: 4px;
}
.ant-form-item-control-input {
  height: 28px !important;
  min-height: 28px !important;
  font-size: 12px;
}
.ant-radio-button-wrapper {
  font-size: 12px;
  height: 28px !important;
}
.ant-radio-button-wrapper > .ant-radio-button {
  height: 28px !important;
}
.resize-code-area .CodeMirror {
  resize: vertical;
  min-height: 300px;
}
