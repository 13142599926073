@import '~antd/lib/style/themes/default.less';
.field-edit-panel-box {
  .query-box {
    // 输入框长度样式
    .ant-form-item {
      width: 100%;
      display: flex;
      justify-content: flex-start !important;
    }
    .ant-form-item-label {
      width: 75px;
    }
    .ant-form-item-control {
      width: 190px;
      height: 28px !important;
      .ant-form-item-control-input {
        width: 190px;
        height: 28px !important;
      }
    }
    // 下拉框高度 和 边框hover
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 28px !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
      border: 1px solid #e27279;
    }
  }
  // 设置  表格 无数据 时 边距样式
  .table-null-right-box {
    margin: 0;
  }
  .htd-aside-right-columns-table {
    .htd-aside-right-columns-table-operator {
      cursor: pointer;
      width: 20%;
      .anticon {
        cursor: pointer;
        &:hover {
          // color: @primary-color;
          color: #e27279;
        }
        & + .anticon {
          margin-left: 8px;
        }
      }
    }
  }
  .ant-table-wrapper {
    max-height: 270px;
    overflow-y: auto;
  }
}
.query-field-seach {
  .c7n-input-content > .c7n-input-rendered-wrapper {
    height: 28px !important;
    .c7n-input-rendered {
      height: 28px;
      > input {
        height: 28px !important;
      }
    }
    .c7n-input-label-wrapper > .c7n-input-label {
      line-height: 23px;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;