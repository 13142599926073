@import '../../../../utils/styles/variables.less';

.htd-blocks-container {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  display: flex;
  position: relative;
  background: #fff;
  &-header {
    height: 44px;
    padding: 0 12px 12px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #f7f7f7;
    &-search {
      display: block;
      width: 100%;
      position: relative;
      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: #333333;
        &:hover {
          color: @primary-color;
        }
      }
    }
    .ant-input {
      height: 32px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 2px;
    }
  }
}
.htd-blocks-aside {
  width: 110px;
  flex: 0 0 110px;
  background: rgba(255, 255, 255, 0.01);
  border-right: 1px solid #f0f0f0;
  &-list {
    margin: 0 0 8px 0;
    padding: 0;
    &-item {
      line-height: 34px;
      padding: 0 12px;
      font-size: 12px;
      list-style: none;
      cursor: pointer;
      font-family: PingFangSC-Regular;
      color: #333333;
      font-weight: 400;
      transition: all 0.2s;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 2px;
        height: 18px;
        top: calc(50% - 9px);
        background: none;
        transition: all 0.2s;
      }
      &-active {
        color: @primary-color;
        font-weight: 500;
        &:before {
          background: @primary-color;
        }
      }
      &-add {
        padding: 0 2px;
        div {
          width: 100%;
          padding: 0 8px;
          line-height: 28px;
          background: rgba(226, 114, 121, 0.08);
          border: 1px dashed @primary-color;
          border-radius: 2px;
          color: @primary-color;
          display: inline-flex;
          align-items: center;
          svg {
            margin-right: 4px;
          }
        }
      }
    }
    .block-one-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        color: #999;
      }
      .block-one-item-icon:hover {
        color: #e27279;
      }
    }
    .block-one-item:hover {
      .block-one-item-icon {
        opacity: 1;
      }
    }
  }
}

.htd-blocks-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  &-list {
    &-title {
      font-size: 14px;
      font-weight: 600;
      padding: 0 8px;
    }
    &-container {
      position: relative;
      transition: background-color 0.25s;
      &:hover {
        background-color: #fbeeef;
        & > .htd-blocks-content-list-item {
          width: calc(100% - 56px);
          padding-right: 0;
          color: @primary-color;
        }
      }
      &:hover &-actions {
        display: flex;
      }
      &-actions {
        display: none;
        position: absolute;
        right: 0;
        height: 34px;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;
        &-action {
          font-size: 14px;
          margin-right: 8px;
          cursor: pointer;
          color: #999;
          transition: color 0.2s;
          &:hover {
            color: @primary-color;
          }
        }
      }
    }
    &-item {
      height: 34px;
      padding: 0 12px 0;
      line-height: 34px;
      font-size: 12px;
      transition: all linear 0.2s;
      font-family: PingFangSC-Regular;
      color: #666666;
      font-weight: 400;
      & > div > div {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.htd-blocks-new-category {
  .ant-form-item {
    margin-bottom: 24px;
  }
  .ant-popover-message-title {
    padding-left: 0;
  }
  .ant-popover-message {
    padding: 0;
    .anticon {
      display: none;
    }
  }
  .ant-popover-buttons {
    margin-bottom: 0;
  }
  .ant-popover-inner-content {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;