.htd-page-preview-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: #fff;
  z-index: 1000;
  &-header {
    height: 48px;
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
  }
  &-close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: red;
    cursor: pointer;
    text-align: center;
  }
}

.htd-page-preview-root {
  width: 100%;
  height: 100%;
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;