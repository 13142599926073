/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
body {
  font-family: PingFangSC-Regular;
}
body .ant-modal .ant-modal-content .ant-modal-header {
  font-size: 16px;
  padding: 24px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
  border-bottom: 0;
}
body .ant-modal .ant-modal-content .ant-modal-body {
  padding: 16px 24px;
}
body .ant-modal .ant-modal-content .ant-modal-footer {
  padding: 16px 24px;
}
body .ant-switch {
  background-color: #d9d9d9;
}
body .ant-switch-checked {
  background-color: #e27279;
}
body .ant-form-item-with-help {
  margin-bottom: 24px;
}
