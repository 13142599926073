.htd-aside-right-columns-table-status {
  width: 0;
}
.htd-aside-right-columns-table-status-box {
  display: flex;
  align-items: center;
}
.htd-aside-right-columns-table-status-box-img {
  width: 5px;
  height: 16px;
  margin-left: 4px;
  cursor: move;
}
.htd-aside-right-columns-table-status .anticon {
  font-size: 12px;
}
.htd-aside-right-columns-table .ant-table-thead th.ant-table-cell {
  height: 16px;
}
