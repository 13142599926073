@import '~antd/lib/style/themes/default.less';
@import '../../../index.less';

.htd-aside-right-main-validator {
  .self-validator-box {
    width: 84px;
    height: 28px;
    border: 2px solid @back;
    margin: 16px 0 6px 6px;
    font-size: 12px;
    color: @back;
    text-align: center;
    line-height: 24px;
    border-radius: 2px;
  }
  .validate-list-img {
    width: 16px;
    height: 16px;
  }
  .validate-list-img:hover {
    color: #e27279 !important;
  }
  .ant-checkbox-checked {
    background-color: @back;
    .ant-checkbox-inner {
      background-color: @back;
      border-radius: 2px;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid @back;
  }
  .ant-form-item {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 6px;
    margin-bottom: 12px;
    .ant-form-item-extra {
      right: 24.4px !important;
      > span {
        > svg:hover {
          color: #e27279;
        }
      }
      > .anticon {
        cursor: pointer;

        &:hover {
          color: @primary-color;
        }
      }
    }
  }
}

.htd-validate-config-panel {
  .ant-form-item {
    justify-content: space-between;
    > .ant-form-item-control {
      max-width: 69%;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .htd-variable-input {
        justify-content: flex-end;
      }
      > div {
        width: 100%;
      }
      > .ant-radio-group.ant-radio-group-outline {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.antd-table-number {
  position: absolute;
  left: -136px;
}
.file-edit-panel-content-back {
  // margin: 0 8px;
  .htd-aside-right-columns-button-wrapper {
    .ant-btn {
      // width: 233px;
      height: 36px;
      background-color: @back !important;
      border-radius: 2px;
      color: #fff !important;
      border: none !important;
    }
  }
  // 下拉框样式
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 1px solid @back;
  }
  .ant-select-selector:hover {
    border: 1px solid @back;
  }

  // 表格头颜色
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fff !important;
      border: none;
      font-size: 12px;
      height: 20px;
    }
  }
  tr {
    background-color: #f8f8f8;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
  }
  th,
  td {
    padding: 4px 2px !important;
    input {
      font-size: 12px;
    }
  }
  th {
    &::before {
      background-color: transparent !important;
    }
  }
  .htd-aside-right-columns-table-status {
    width: 0;
    .anticon {
      font-size: 12px;
    }
  }
  .htd-aside-right-buttons-table-operator {
    width: 20%;
    .anticon {
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
      & + .anticon {
        margin-left: 8px;
      }
    }
  }
  .htd-aside-right-columns-table-operator {
    width: 20%;
    color: #999;
    .anticon {
      cursor: pointer;
      &:hover {
        color: #e27279;
      }
      & + .anticon {
        margin-left: 8px;
      }
    }
  }

  .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
    height: 28px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }
  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    .ant-select-selector {
      height: 28px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }
  input {
    height: 28px;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 26px;
  }
}
// 设置表格 属性面板 编辑的 弹框层级 （高于弹框组件）
.htd-aside-right-table-field-edit-panel {
  z-index: 50;
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;