@import '../../../utils/styles/variables.less';

.htd-drop-area {
  .htd-drop-area-placeholder {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    // width: 100%;
    height: 20px;
    // height: 100%;
    line-height: 20px;
    margin-left: -50px;
    margin-top: -10px;
    color: #ccc;
    text-align: center;
    // background-color: yellow;

    &::after {
      content: '拖拽组件到这里';
      width: 100%;
      height: 100%;
    }
  }
  // 画布中的按钮 高度
  > div > div > div > main > button {
    height: 28px;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;