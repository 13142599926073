body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary-color: #e27279; /* 主色调 */
  --default-color: #d8d8d8; /* 浅色调 */
  --de-font-size: 12px; /* 默认字体大小 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

/*整个滚动条*/
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

/*定义滚动条轨道*/
*::-webkit-scrollbar-track {
  background-color: transparent;
}

/*定义滑块*/
*::-webkit-scrollbar-thumb {
  background-color: #acb1bf;
}

.ant-form-item-label {
  overflow: visible !important;
}

.ant-form-item-label > label {
  white-space: normal;
  font-size: var(--de-font-size) !important;
}

.c7n-pro-table-cell-inner {
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 输入框样式 */
.ant-form-item .ant-input {
  box-shadow: none !important;
}
/* outline 不生效  使用这个方法 */
input:focus {
  border: 1px solid var(--primary-color) !important;
}
/* 输入框边框hover */
.ant-form-item .ant-input:hover {
  border: 1px solid var(--primary-color);
}
/* 数字输入框 hover */
.c7n-input-number {
  box-shadow: none !important;
}
/* 数字输入框 图标偏移问题 */
.c7n-input-number-handler-wrap {
  top: -7px !important;
}
.c7n-input-number :focus {
  border: none !important;
}
.c7n-input-number .c7n-input-content {
  height: 28px;
}
.c7n-input-number .c7n-input-content .c7n-input-rendered-wrapper {
  height: 28px;
}
.c7n-input-number .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered {
  height: 28px;
}
.c7n-input-number .c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered input {
  height: 28px;
}
.c7n-input-focused {
  border: none !important;
}

/* 下拉框 点击样式 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none !important;
  border-color: var(--primary-color) !important;
  background-color: transparent;
}
/* 折叠面板边距 */
.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px !important;
}
.htd-aside-edit-table-panel .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
}
.ant-form-item-label-left {
  flex: 0 0 30.5%;
}
/* 解决猪齿鱼bug optPut预览时 样式问题 */
td {
  vertical-align: middle !important;
}
/* 表格样式  */
.ant-table-cell {
  font-size: var(--de-font-size);
  height: 40px;
  /* border-top: 1px solid #F2F2F2; */
  /* border-bottom: 1px solid #F2F2F2; */
  border-bottom: none !important;
}
.ant-table-cell svg {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
.ant-table-cell:hover {
  background-color: transparent !important;
}
.ant-select-selection-item {
  font-size: var(--de-font-size);
}
.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
  padding: 0 4px !important;
}
.c7n-table-placeholder {
  height: 40px;
  background-color: #f0f0f0 !important;
  line-height: 30px;
}
.htd-aside-right-columns-table .ant-table-thead .ant-table-cell {
  color: #666;
}
thead .ant-table-cell {
  color: #666;
}
tbody .ant-table-cell .ant-select-selection-item, tbody .ant-table-cell input, .ant-form-item .ant-input {
  color: #333;  
}
/* tbody .ant-table-cell input {
  color: #333;
}
.ant-form-item .ant-input {
  color: #333;
} */
/* 删除提示框样式 */
/* .ant-popover-inner  {
  width: 150px;
} */
.ant-popover-message {
  font-size: var(--de-font-size) !important;
}
.ant-popover-buttons .ant-btn {
  font-size: var(--de-font-size);
}
/* 表格 中下拉框 图标样式 */
.htd-aside-right-operator-table-select .ant-select-arrow {
  top: 40% !important;
}
.ant-table-tbody .ant-select-arrow {
  top: 40% !important;
}
/* 滑杆样式 */
.ant-slider-track {
  background-color: var(--primary-color) !important;
}
.ant-slider:hover .ant-slider-track {
  background-color: var(--primary-color) !important;
}
.ant-slider-handle {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgba(226,114,121,0.12) !important;
}
/* .c7n-pro-table-professional-query-bar .c7n-pro-form-wrapper {
  width: 70%;
}
.c7n-pro-table-professional-query-bar .c7n-pro-table-professional-query-bar-button {
  width: 30%;
  font-size: 14px;
  background-color: red;
} */
/* 勾选框样式 */
.htd-aside-right-main-validator .ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}
.ant-checkbox-checked::after {
  border: 1px solid var(--primary-color) !important;
}

/* 按钮图表 样式 */
.c7n-pro-icon-picker:hover {
  border-color: var(--primary-color) !important;
}
/* 基础属性 部分 存在 from中包含折叠面板时 单独处理 */
.base-box {
  padding: 2px 12px 12px 12px !important;
}
/* 按钮样式 */
.ant-btn {
  background-color: #fff;
  border-color: var(--default-color) !important;
  color: #525A6E !important;
}
.ant-btn:hover, .ant-btn:focus {
  background-color: #fff;
  border-color: #525A6E !important;
  color: #525A6E !important;
}
/* 弹框中 主按钮样式 */
.ant-modal-footer .ant-btn-primary {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: #fff !important;
}
/* 区块删除 弹框 按钮 */
.ant-modal-confirm-btns .ant-btn-primary {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: #fff !important;
}
.ant-modal-footer .ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: var(--primary-color) !important;
}
/* c7n按钮样式 */
.c7n-pro-modal-footer .c7n-pro-btn-wrapper {
  border: 1px solid var(--default-color) !important;
}
.c7n-pro-modal-footer .c7n-pro-btn-primary {
  border: 1px solid var(--primary-color) !important;
}
.c7n-pro-modal-footer .c7n-pro-btn-wrapper .c7n-pro-btn-default {
  border: 1px solid var(--default-color) !important;
  color: #525A6E !important;
}
.c7n-pro-btn-default.c7n-pro-btn-raised:not(.c7n-pro-btn-disabled):hover{
  border-color: #525A6E !important;
  color: #525A6E !important;
}
/* 警告框 按钮样式 */
.ant-popover-buttons .ant-btn-primary {
  background-color: var(--primary-color) !important;
  border: transparent !important;
  color: #fff !important;
}
/* 下拉框选项 样式 */
.c7n-pro-popup,
.ant-select-item-option {
  font-size: var(--de-font-size) !important;
}
.c7n-pro-popup .c7n-pro-select-dropdown-menu-item-active {
  background-color: #FAE6E7;
  color: var(--primary-color);
}
.ant-select-item-option-active {
  background-color: #FAE6E7 !important;
  color: var(--primary-color) !important;
}
/* 弹框存在垂直滚动条  footer的按钮显示不全 */
.ant-modal-wrap {
  top: -55px !important;
}
/* 折叠面板 内容的 组件图标显示不全问题 */
.c7n-collapse-content > .c7n-collapse-content-box {
  /* padding-top: 22px;
  padding-bottom: 8px; */
  margin-top: 6px;
}