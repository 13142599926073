.left1-svg1-cla1 {
  color: #999 !important;
  cursor: pointer;
}
.left1-svg1-cla1 svg {
  width: 16px;
  height: 16px;
}
.left1-svg1-cla1 svg:hover {
  color: #e27279 !important;
}
.select-right-img {
  width: 10px;
  height: 10px;
}
