/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-aside-right-columns-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.htd-aside-right-columns-button-wrapper button {
  color: #e27279;
}
.htd-aside-right-columns-button-wrapper button:hover {
  color: #e27279;
}
.htd-aside-right-columns-table .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  border: none;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  height: 20px !important;
}
.htd-aside-right-columns-table tr {
  background-color: #f8f8f8;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
}
.htd-aside-right-columns-table th,
.htd-aside-right-columns-table td {
  padding: 4px 2px !important;
}
.htd-aside-right-columns-table th input,
.htd-aside-right-columns-table td input {
  font-size: 12px;
}
.htd-aside-right-columns-table th::before {
  background-color: transparent !important;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status {
  width: 0;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status-box {
  display: flex;
  align-items: center;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status-box-img {
  width: 5px;
  height: 16px;
  margin-left: 4px;
  cursor: move;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-status .anticon {
  font-size: 12px;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator {
  cursor: pointer;
  width: 20%;
  color: #999;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon {
  cursor: pointer;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon:hover {
  color: #e27279;
}
.htd-aside-right-columns-table .htd-aside-right-columns-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.jus-conn {
  justify-content: center !important;
}
.jus-conn .ant-btn {
  width: 276px;
  height: 36px;
  background-color: #e27279 !important;
  border-radius: 2px;
  color: #fff !important;
  border: none !important;
}
.ant-table-content table {
  border-spacing: 0 8px !important;
}
.htd-aside-right-table-field-edit-panel {
  position: absolute;
  top: 0;
  right: 299px;
  width: 280px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #efefef;
  z-index: 99;
  overflow: hidden;
}
.htd-aside-right-table-field-edit-panel .htd-aside-right-table-field-validate-message-setting {
  cursor: pointer;
}
.htd-aside-right-table-field-edit-panel .htd-input-width .ant-form-item-control-input-content::after {
  content: 'px';
  margin-left: 5px;
}
.htd-aside-right-table-field-edit-panel .c7n-pro-select-box .c7n-pro-radio-button {
  padding: 0 8px !important;
}
