/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-canvas-wrap {
  padding: 12px;
  background-color: #ededed;
}
.canvas-header-box {
  height: 44px;
  background-color: #fff;
  margin-bottom: 12px;
  margin-top: 2px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
}
.htd-canvas {
  position: relative;
  width: 100%;
  height: calc(100% - 56px);
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  overflow-y: auto;
}
.htd-canvas-droppable-root-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible !important;
  overflow-y: auto;
}
.htd-canvas-droppable-container {
  transition: all 0.2s;
}
.htd-canvas-droppable-inner-container {
  width: 100%;
  min-height: 100%;
}
.htd-canvas-draggable-dragging {
  background-color: #c4c1c1 !important;
  border: 1px solid transparent !important;
}
.htd-canvas-draggable-dragging .htd-drag-item-container-title,
.htd-canvas-draggable-dragging .htd-drag-item-container-header {
  display: none !important;
}
.htd-canvas-height .htd-drag-item-container {
  height: fit-content;
}
.htd-canvas-height2 {
  height: calc(100% - 94px);
}
.canvas-footer-box {
  height: 44px;
  background-color: #fff;
  margin-bottom: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
}
