.wfb-config-modal-antdform .ant-form-item-label {
  padding: 0.1rem 0.05rem;
  width: 1rem;
}
.wfb-config-modal-antdform .ant-form-item-label label {
  color: #000000 !important;
}
.wfb-config-modal-antdform .ant-form-item-control {
  padding: 0.1rem 0.05rem;
}
