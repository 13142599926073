.table-form-item-box {
  max-height: 270px;
  overflow-y: auto;
}
.table-form-item-box .ant-input[disabled] {
  background-color: #ececec !important;
}
.table-form-item-box .svg-cla1 {
  color: #e27279 !important;
}
.table-form-item-box .svg-cla1:hover {
  color: #e27279 !important;
}
.resize-code-area .CodeMirror {
  resize: vertical;
  min-height: 300px;
}
