.flex(@num) {
  flex: 0 0 percentage(@num / 24);
}

.makeCol(@i) when(@i < 25) {
  .htd-components-Col-@{i} {
    flex: 0 0 percentage(@i / 24);
    padding-left: 2px;
    padding-right: 2px;
    // （效果：rol组件里面的table组件 加字段不撑开长度）
    width: 50%;
    // 表格在col中时 其他col被压缩了
    min-height: 80px;
  }

  .makeCol(@i+1);
}

.makeCol(1);

.makeForm(@i) when(@i < 25) {
  .htd-components-Form-@{i} {
    & > div > div > main > .htd-placeholder-form > .htd-drop-area {
      &::after {
        display: block;
        width: 100%;
        height: 50px;
        content: '';
      }
      // not(.htd-components-Button) 按钮 在form中时 不设置份数长度
      & > .htd-drag-box:not(.htd-components-PLACEHOLDER) {
        margin-bottom: 16px;
        width: percentage(1 / @i) !important;
        .makeColSpan(@j) when(@j <= @i) {
          &.htd-components-form-field-@{j} {
            width: percentage(@j / @i) !important;
            // float: left;
          }
          .makeColSpan(@j + 1);
        }
        .makeColSpan(1);
      }
    }
  }

  .makeForm(@i+1);
}

.makeForm(1);

.htd-components {
}
// 画布 拖拽时鼠标
.htd-placeholder-row-tensile-line:hover {
  border-left-style: solid;
  border-left-width: 3px;
}

.clear-float {
  clear: left;
}
// .htd-placeholder-row-tensile-line:hover {
//   border-left-style: solid;
//   border-left-width: 3px;
// }
