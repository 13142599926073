/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-drop-area .htd-drop-area-placeholder {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 20px;
  line-height: 20px;
  margin-left: -50px;
  margin-top: -10px;
  color: #ccc;
  text-align: center;
}
.htd-drop-area .htd-drop-area-placeholder::after {
  content: '拖拽组件到这里';
  width: 100%;
  height: 100%;
}
.htd-drop-area > div > div > div > main > button {
  height: 28px;
}
