.htd-aside-left-main-tree.c7n-tree {
  padding-top: 4px !important;
  // color: #a9adb7 !important;
  svg {
    width: 16px;
    height: 16px;
  }

  .c7n-tree-treenode-disabled .c7n-tree-node-content-wrapper {
    color: inherit;
    cursor: pointer;
  }
  .c7n-tree-treenode.drag-over > [draggable] {
    color: inherit;
    background-color: #fff;
    opacity: 1;
  }
}

.c7n-tree .c7n-tree-node-content-wrapper.c7n-tree-node-selected {
  background: rgba(226, 114, 121, 0.18) !important;
  color: rgba(226, 114, 121);
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;