@import '../../../utils/styles/variables.less';

// 弹框遮盖层 大小计算
@fullScreenLeft: @left-menu-width + @left-panel-width+ @canvas-padding - 4px;
@fullScreenRight: @right-panel-width + @canvas-padding - 4px;
@fullScreenTop: @head-height + @canvas-padding - 4px;
@fullScreenBottom: @canvas-padding - 4px;

.htd-components-Modal-drawer {
  .htd-drag-item-container {
    height: 98.2% !important;
    // 弹框样式中 侧边弹框时 下面超出问题
    // .htd-drag-item-container-active {
    //   height: 98% !important;
    // }
  }
}

.htd-components-Modal {
  position: fixed !important;
  left: calc(50% - 250px);
  top: calc(50% - 250px);
  // width: 500px;
  // height: 500px;
  display: none;
  z-index: 20;

  &-fullscreen {
    width: initial;
    height: initial;
    left: @fullScreenLeft;
    top: @fullScreenTop;
    bottom: @fullScreenBottom;
    right: @fullScreenRight;
  }

  &-drawer {
    width: 320px;
    height: initial;
    left: initial;
    right: @fullScreenRight;
    top: @fullScreenTop;
    bottom: 0;
  }

  &-has-focused,
  &.htd-canvas-draggable-container-active {
    display: block;
  }

  .htd-drag-item-container {
    &-header,
    &-title {
      z-index: 8;
    }
  }

  .htd-drag-box-content {
    z-index: 6;
  }
}

.htd-placeholder-modal {
  &-fullscreen {
    position: fixed !important;
    left: @fullScreenLeft;
    top: @fullScreenTop;
    bottom: @fullScreenBottom;
    right: @fullScreenRight;
  }

  &-fullscreen &-content {
    width: 100%;
    height: 100%;
  }

  &-mask {
    position: fixed;
    left: @fullScreenLeft;
    top: @fullScreenTop;
    bottom: @fullScreenBottom;
    right: @fullScreenRight;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-drawer,
  &-drawer,
  &-content {
    width: 100%;
    height: 100%;
  }

  &-content {
    position: relative;
    padding: 0 16px 16px 16px;
    background-color: #fff;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      line-height: 48px;
      // border-bottom: 1px solid #efefef;
    }

    &-main {
      box-sizing: border-box;
      padding-top: 16px;
      width: 100%;
      height: calc(100% - 48px - 36px);
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.05);
    }

    &-footer {
      height: 36px;
      padding-top: 12px;
      text-align: right;

      &-btn {
        height: 28px;
        outline: none;
        margin-left: 8px;
        min-width: 64px;
        font-size: 12px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        color: #30303d;
        background-color: #fff;

        &-primary {
          // color: #fff;
          // background-color: #2166d0;
          // border-color: #2166d0;
          background-color: #30303d;
          border: 1px solid rgba(0, 0, 0, 0.15);
          color: #fff;
        }
      }
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;