/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-blocks-container {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  display: flex;
  position: relative;
  background: #fff;
}
.htd-blocks-container-header {
  height: 44px;
  padding: 0 12px 12px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
}
.htd-blocks-container-header-search {
  display: block;
  width: 100%;
  position: relative;
}
.htd-blocks-container-header-search svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #333333;
}
.htd-blocks-container-header-search svg:hover {
  color: #e27279;
}
.htd-blocks-container-header .ant-input {
  height: 32px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.htd-blocks-aside {
  width: 110px;
  flex: 0 0 110px;
  background: rgba(255, 255, 255, 0.01);
  border-right: 1px solid #f0f0f0;
}
.htd-blocks-aside-list {
  margin: 0 0 8px 0;
  padding: 0;
}
.htd-blocks-aside-list-item {
  line-height: 34px;
  padding: 0 12px;
  font-size: 12px;
  list-style: none;
  cursor: pointer;
  font-family: PingFangSC-Regular;
  color: #333333;
  font-weight: 400;
  transition: all 0.2s;
  position: relative;
}
.htd-blocks-aside-list-item:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 2px;
  height: 18px;
  top: calc(50% - 9px);
  background: none;
  transition: all 0.2s;
}
.htd-blocks-aside-list-item-active {
  color: #e27279;
  font-weight: 500;
}
.htd-blocks-aside-list-item-active:before {
  background: #e27279;
}
.htd-blocks-aside-list-item-add {
  padding: 0 2px;
}
.htd-blocks-aside-list-item-add div {
  width: 100%;
  padding: 0 8px;
  line-height: 28px;
  background: rgba(226, 114, 121, 0.08);
  border: 1px dashed #e27279;
  border-radius: 2px;
  color: #e27279;
  display: inline-flex;
  align-items: center;
}
.htd-blocks-aside-list-item-add div svg {
  margin-right: 4px;
}
.htd-blocks-aside-list .block-one-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.htd-blocks-aside-list .block-one-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #999;
}
.htd-blocks-aside-list .block-one-item .block-one-item-icon:hover {
  color: #e27279;
}
.htd-blocks-aside-list .block-one-item:hover .block-one-item-icon {
  opacity: 1;
}
.htd-blocks-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}
.htd-blocks-content-list-title {
  font-size: 14px;
  font-weight: 600;
  padding: 0 8px;
}
.htd-blocks-content-list-container {
  position: relative;
  transition: background-color 0.25s;
}
.htd-blocks-content-list-container:hover {
  background-color: #fbeeef;
}
.htd-blocks-content-list-container:hover > .htd-blocks-content-list-item {
  width: calc(100% - 56px);
  padding-right: 0;
  color: #e27279;
}
.htd-blocks-content-list-container:hover .htd-blocks-content-list-container-actions {
  display: flex;
}
.htd-blocks-content-list-container-actions {
  display: none;
  position: absolute;
  right: 0;
  height: 34px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
}
.htd-blocks-content-list-container-actions-action {
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
  color: #999;
  transition: color 0.2s;
}
.htd-blocks-content-list-container-actions-action:hover {
  color: #e27279;
}
.htd-blocks-content-list-item {
  height: 34px;
  padding: 0 12px 0;
  line-height: 34px;
  font-size: 12px;
  transition: all linear 0.2s;
  font-family: PingFangSC-Regular;
  color: #666666;
  font-weight: 400;
}
.htd-blocks-content-list-item > div > div {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.htd-blocks-new-category .ant-form-item {
  margin-bottom: 24px;
}
.htd-blocks-new-category .ant-popover-message-title {
  padding-left: 0;
}
.htd-blocks-new-category .ant-popover-message {
  padding: 0;
}
.htd-blocks-new-category .ant-popover-message .anticon {
  display: none;
}
.htd-blocks-new-category .ant-popover-buttons {
  margin-bottom: 0;
}
.htd-blocks-new-category .ant-popover-inner-content {
  padding-top: 32px;
  padding-bottom: 24px;
}
