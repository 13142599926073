.htd-ds-config-collapse {
  // 内容部分
  .ant-collapse-header {
    background-color: #fff;
  }
  // 外层设置了 hover事件  改变背景颜色 子层也有效果 这里设置一下
  .ant-collapse-header:hover {
    background-color: transparent !important;
  }
  .htd-ds-config-top-border {
    border-top: 1px solid #dbdbdb;
    .htd-ds-config-back {
      background-color: #f7f7f7 !important;
      padding-top: 4px;
      .t-j-api-box {
        margin-left: 12px;
        color: #525a6e;
        font-size: 12px;
        padding-bottom: 12px;
        padding-top: 10px;
        width: 25%;
        svg {
          width: 12px !important;
          height: 12px !important;
          // margin-right: 2px;
        }
        svg:hover {
          color: #525a6e;
        }
      }
      .htd-ds-config-but {
        color: #525a6e !important;
        font-size: 12px;
        margin-left: 14px;
        > i {
          margin-right: 0;
        }
      }
    }
  }
  & > .ant-collapse-item {
    & > .ant-collapse-header {
      height: 44px !important;
      padding: 0 12px !important;
    }

    & > .ant-collapse-content {
      padding: 0;
      border: none !important;
      & > .ant-collapse-content-box {
        // background-color: #fff;
        padding: 4px 0 0 0;
        // position: relative;
        // top: 1px;
        .htd-ds-config-api {
          &-row {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 4px 0;

            & + button {
              margin-top: 4px;
              margin-left: 8px;
              margin-bottom: 8px;
            }

            &-left {
              width: 100%;
              max-width: 183px;
              white-space: nowrap;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 8px;
              line-height: 14px;
            }

            &-right {
              position: absolute;
              top: 4px;
              right: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 22px;
              // background-color: #fff;
              transition: all 0.2s;
              // box-shadow: -15px 0 10px #fff;
              // opacity: 0;
              margin-left: 8px;
              display: flex;
              justify-content: space-between;
              color: #999;

              i.icon {
                color: #e0e0e0;
                cursor: pointer;

                &.icon-edit-o:hover {
                  // color: #3f51b5;
                  color: #e27279;
                }

                &.icon-delete:hover {
                  color: #e27279;
                  // color: red;
                }
              }
            }

            &:hover {
              .htd-ds-config-api-row-right {
                opacity: 1;
              }
            }
          }
          &-name {
            display: inline-block;
            // flex: 0 0 26px;
            width: 32px;
            height: 16px;
            background-color: rgba(160, 160, 173, 0.23);
            border-radius: 2px;
            line-height: 16px;
            color: #525a6e;
            font-size: 10px;
            text-align: center;
            margin-right: 2px;
          }
          &-method {
            display: inline-block;
            padding: 2px;
            margin: 0 2px;
            background-color: #f6f6f6;
            border-radius: 2px;
            font-size: 12px !important;
            // transform: scale(0.8);
            width: 38px;
            height: 16px;
            line-height: 10px;
            text-align: center;
            font-weight: 500;

            &-get {
              background-color: rgba(50, 190, 80, 0.23);
              color: rgba(44, 174, 0, 1);
            }
            &-post {
              background-color: rgba(0, 145, 255, 0.2);
              color: #0091ff;
            }
            &-put {
              background-color: #097bed;
              color: #fff;
            }
            &-delete {
              background-color: rgba(#f96f68, 0.2);
              color: #f96f68;
            }
          }
          &-url {
            display: inline-block;
            color: #666666;
            width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .htd-ds-config-field {
          table {
            font-size: 12px;
            background-color: rgba(247, 247, 247, 1);
            color: #666;
            padding: 0 8px;
            // border-spacing: 0 4px;
          }
          .ant-table-header {
            background-color: transparent;
            th {
              background-color: transparent;
              // border: none !important;
            }
          }
        }
        .htd-aside-left-fields-table {
          .ant-table-header {
            height: 32px;
          }
          .ant-table-thead {
            .ant-table-cell {
              background-color: rgba(247, 247, 247, 1) !important;
              border: none;
              font-size: 12px;
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #666666;
              font-weight: 400;
              height: 32px !important;
            }
          }
          .ant-table-tbody {
            > tr > td {
              > input {
                background-color: #ececec;
                border: 1px solid rgba(0, 0, 0, 0.08);
                border-radius: 2px;
              }
              svg {
                color: #999;
              }
              svg:hover {
                color: #e27279;
              }
            }
          }
          th,
          td {
            padding: 4px 2px !important;
            input {
              font-size: 12px;
            }
          }
          th {
            &::before {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
  &-icon {
    width: 16px;
    height: 16px;
  }
}
.htd-ds-config-collapse:hover {
  cursor: pointer;
}
// 表格无数据时 去掉折叠面板的下边框
.query-api-table-no-data-box {
  .no-data {
    border-bottom: none !important;
  }
}
// 数据源部分样式
.render-top-span {
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  &-title {
    color: #999;
    margin-right: 4px;
  }
}

// 添加api弹框
.htd-add-field-modal {
  .c7n-pro-modal-header {
    padding: 24px 14px 12px 14px;
    border-bottom: none !important;
    .c7n-pro-modal-title {
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .c7n-pro-modal-body {
    padding: 0 14px 0 14px;
  }
  .c7n-pro-modal-footer {
    border-top: 1px solid #eeeeee !important;
    padding-right: 14px;
    .c7n-pro-btn-wrapper {
      height: 28px;
      width: 64px;
      font-size: 12px;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      color: #525a6e;
    }
    .c7n-pro-btn-primary {
      background-color: #e27279;
      color: #fff;
    }
    .c7n-pro-btn-primary.c7n-pro-btn-raised:not(.c7n-pro-btn-disabled):hover {
      background-color: #e27279;
      color: #fff;
    }
  }
  &-row {
    margin-bottom: 8px;
    font-size: 12px;
    color: #666666;
    &-title {
      height: 32px;
      line-height: 32px;
      margin-bottom: 12px;
      width: 96%;
      border-bottom: 1px solid rgba(#c3c3c3, 0.5);
      & > div {
        // text-align: center;
        i {
          width: 14px;
          height: 14px;
          font-size: 14px;
          color: #999;
        }
        .icon-help:before {
          width: 12.25px;
          height: 12.25px;
        }
      }
    }
    &-form {
      height: 44px !important;
      line-height: 41px;
      background-color: #f9f9f9;
      width: 96%;
      & > div {
        text-align: center;
        .c7n-pro-select-wrapper {
          height: 28px;
          > label {
            height: 28px;
            > .c7n-pro-select {
              height: 28px;
            }
          }
        }
        .left1-svg1-cla1 {
          position: relative;
          top: 4px;
        }
      }
      // 必输框 样式背景
      .c7n-pro-input-required-colors,
      .c7n-pro-select-required-colors {
        background-color: #fff !important;
      }
      .c7n-pro-input-required-colors .c7n-pro-input:hover {
        border-color: #e27279 !important;
      }
      .c7n-pro-select:hover {
        border-color: #e27279 !important;
      }
    }
  }
  &-table {
    .c7n-pro-table-professional-toolbar {
      padding-top: 0;
      .c7n-pro-table-toolbar {
        text-align: left;
      }
    }
  }
}
.add-api-list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
      color: #999;
    }
    svg:hover {
      color: #e27279;
    }
  }
  .c7n-col-6 {
    padding-right: 0 !important;
  }
}
// 字段列表为空 样式
.table-null-left-box {
  background-color: #f7f7f7;
  width: 100%;
  height: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 42px;
  color: #999;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 84px;
    height: 50px;
  }
  span {
    line-height: 24px;
  }
}
.t-j-api-model-box {
  color: #e27279;
  font-size: 12px;
  padding-bottom: 16px;
  padding-top: 4px;
  width: 10%;
  font-size: 12px;
  cursor: pointer;
  svg {
    width: 16px !important;
    height: 16px !important;
    margin-right: 2.4px;
  }
  &-title {
    position: relative;
    top: -1px;
  }
}
.htd-aside-right-columns-table-operator {
  svg {
    color: #999 !important;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;