@import '../../utils/styles/variables.less';

.htd-code-viewer {
  width: 100%;
  display: flex;
  flex-flow: column;
  &-header {
    // flex: 0 0 48px;
    flex: 0 0 36px;
    .htd-code-viewer-header-file-active {
      background-color: #e27277;
      border-radius: 1px;
      color: #fff;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 12px;
        margin-right: 8px;
        line-height: 28px;
        cursor: pointer;
      }
    }
    &-file {
      margin-right: 8px;
      background-color: @common-bg;
      color: @font-color;
      border-radius: @border-radius;
      transition: all 0.35s;
      &-active {
        background-color: @common-bg-hover;
        color: @font-color-hover;
      }
    }
  }
  &-main {
    flex: 1;
    font-size: 12px;
    line-height: 1.5;
    &-pre {
      > pre {
        height: 73vh;
        overflow-y: auto;
      }
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;