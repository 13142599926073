/*
  下面是针对 antd less 变量的处理，修改之后必须重新启动才可生效
*/
.htd-aside-left-memu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49px;
  height: 100%;
  padding: 8px 0;
  background: #fff;
  border-right: 1px solid #dbdbdb;
}
.htd-aside-left-memu > div {
  display: flex;
  width: 48px;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: #30303d;
  font-size: 18px;
  cursor: pointer;
}
.htd-aside-left-memu > div > img {
  width: 16px;
  height: 16px;
}
.htd-aside-left-memu > div:hover {
  color: #e27279 !important;
}
.htd-aside-left-memu > div + div {
  margin-top: 8px;
}
.menu-selected {
  background: rgba(226, 114, 121, 0.18);
  color: #e27279 !important;
  border-left: 2px solid;
  background: linear-gradient(to right, rgba(226, 114, 121, 0.18), #fff);
}
.c7n-input-content {
  border: 1px solid #bfc3d0 !important;
  border-radius: 2px !important;
  height: 32px;
  background-color: #fff;
}
.c7n-input-content .c7n-input-rendered-wrapper {
  position: relative;
  height: 32px !important;
}
.c7n-input-content .c7n-input-rendered-wrapper .c7n-input-rendered-sm .c7n-input-label {
  line-height: 27px;
}
.c7n-input-content .c7n-input-label {
  color: #bfc3d0 !important;
}
.c7n-input-content .c7n-input-suffix {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.c7n-input-content .c7n-input-suffix .c7n-input-suffix-sm {
  height: 32px !important;
}
.c7n-input-content .c7n-btn.c7n-btn-sm {
  padding: 6px;
}
.c7n-input-content .c7n-btn-flat.c7n-btn-primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.c7n-input-content .c7n-btn-flat.c7n-btn-primary .left-svg-cla {
  position: relative;
  right: -2px;
  color: #a0a0ad;
}
.c7n-input-content .c7n-btn-flat.c7n-btn-primary .left-svg-cla svg {
  width: 16px;
  height: 16px;
}
.c7n-input-content .c7n-btn-flat.c7n-btn-primary:hover {
  background-color: transparent;
}
.c7n-input-content:hover {
  border-color: #e27279 !important;
  border-radius: 2px !important;
}
.c7n-input-focused {
  border-color: transparent !important;
}
.c7n-input:focus input {
  border-color: transparent !important;
}
.c7n-input-focused .htd-aside-left-search {
  border: none !important;
}
.c7n-input-has-border.c7n-input-focused.c7n-input-wrapper::before {
  border: 1px solid #e27279 !important;
}
.htd-aside-left {
  height: 100%;
  border-right: 1px solid #dbdbdb;
  overflow: hidden;
  background-color: #f7f7f7;
}
.htd-aside-left-search {
  height: 32px !important;
}
.htd-aside-left i.icon-search {
  font-size: 20px !important;
  color: #333 !important;
}
.htd-aside-left-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 8px;
}
.htd-aside-left-header-backImg {
  width: 16px;
  margin-right: 5px;
  position: relative;
  top: -1px;
  cursor: pointer;
}
.htd-aside-left-header > span {
  font-size: 16px;
}
.htd-aside-left-header > i.icon {
  cursor: pointer;
}
.htd-aside-left-header > i.icon:hover {
  color: #e27279;
}
.htd-aside-left-main {
  height: calc(100% - 38px);
  overflow-y: auto;
}
.htd-aside-left-main-components {
  height: 100%;
}
.htd-aside-left-main-components-container {
  padding: 0 8px 16px 8px;
}
.htd-aside-left-main-components-container .c7n-input-suffix {
  top: 3px;
}
.htd-aside-left-main-components-t {
  color: #666;
  margin-bottom: 12px;
  margin-top: 4px;
  font-size: 12px;
}
.htd-aside-left-main-components .c7n-tabs-bar {
  background-color: #fff;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-container-scrolling {
  padding: 0 !important;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-tab-prev,
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-tab-next {
  display: none;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll .c7n-tabs-nav {
  width: 100%;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll .c7n-tabs-nav > div {
  width: 50% !important;
  text-align: center;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll .c7n-tabs-nav .c7n-tabs-ink-bar-animated {
  background-color: #e27279;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll .c7n-tabs-nav .c7n-tabs-tab-active {
  color: #e27279;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll .c7n-tabs-nav .c7n-tabs-tab {
  margin: 0 !important;
}
.htd-aside-left-main-components .c7n-tabs-bar .c7n-tabs-nav-scroll .c7n-tabs-nav .c7n-tabs-tab:hover {
  color: #e27279;
}
.htd-aside-left-main-components .c7n-tabs-content {
  height: calc(100% - 54px);
}
.htd-aside-left-main-components .c7n-tabs-tabpane {
  height: 100%;
  overflow-y: auto;
  padding: 0;
}
.htd-aside-left-wapper-component {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  padding: 0 8px;
  width: 116px;
  height: 36px;
  line-height: 36px;
  color: #333;
  background-color: #fff;
  user-select: none;
  transition: all 0.35s ease;
  border: 1px solid #fff;
  border-radius: 2px;
  font-size: 12px;
}
.htd-aside-left-wapper-component svg {
  width: 1.5em;
  height: 1.5em;
}
.htd-aside-left-wapper-component .htd-drag-box-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.htd-aside-left-wapper-component .htd-drag-box-content > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.htd-aside-left-wapper-component .htd-drag-box-content > img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.htd-aside-left-wapper-component .htd-aside-left-wapper-component-left-top-box {
  width: 16px !important;
  height: 16px !important;
  position: absolute;
  top: -1px;
  right: -9px;
  opacity: 0;
  background-color: #e27279;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.htd-aside-left-wapper-component .htd-aside-left-wapper-component-left-top-box > img {
  width: 12px;
  height: 12px;
}
.htd-aside-left-wapper-component .htd-drag-box-content:hover {
  cursor: pointer;
}
.htd-aside-left-wapper-component .htd-drag-box-content:hover .htd-aside-left-wapper-component-left-top-box {
  opacity: 1 !important;
}
.htd-aside-left-wapper-component .htd-drag-box-content:active {
  cursor: move;
}
.htd-aside-left-wapper-component.htd-aside-left-wapper-component-disabled {
  cursor: not-allowed;
  background: transparent;
  border-style: dashed;
  border-color: #ccc;
}
.htd-aside-left-wapper-component:not(.htd-aside-left-wapper-component-disabled):hover {
  background-color: #f6e9ea;
  color: #e27279;
  border: 1px dashed #e27279;
}
.htd-aside-left-wapper-component:nth-child(odd) {
  margin-right: 8px;
}
