@import '../../../utils/styles/variables.less';
@import '../../../mapping/data/index.less';

.htd-aside-left-memu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49px;
  height: 100%;
  padding: 8px 0;
  background: #fff;
  border-right: 1px solid #dbdbdb;

  > div {
    display: flex;
    // width: 32px;
    width: 48px;
    height: 32px;
    // border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: #30303d;
    font-size: 18px;
    cursor: pointer;

    > img {
      width: 16px;
      height: 16px;
    }

    &:hover {
      color: @primary-color !important;
    }

    & + div {
      margin-top: 8px;
    }
  }
}

.menu-selected {
  background: fade(@primary-color, 18%);
  color: @primary-color !important;
  border-left: 2px solid;
  background: linear-gradient(to right, rgba(226, 114, 121, 0.18), #fff);
}

// 搜索框样式
.c7n-input-content {
  border: 1px solid #bfc3d0 !important;
  border-radius: 2px !important;
  height: 32px;
  background-color: #fff;

  .c7n-input-rendered-wrapper {
    position: relative;
    // top: -2px;
    height: 32px !important;

    .c7n-input-rendered-sm .c7n-input-label {
      line-height: 27px;
    }
  }

  .c7n-input-label {
    color: #bfc3d0 !important;
  }

  .c7n-input-suffix {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // 设置之后 数字输入框 会有问题
    // top: 4px;
  }

  .c7n-input-suffix .c7n-input-suffix-sm {
    height: 32px !important;
  }

  .c7n-btn.c7n-btn-sm {
    padding: 6px;
  }

  .c7n-btn-flat.c7n-btn-primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .left-svg-cla {
      position: relative;
      right: -2px;
      color: #a0a0ad;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .c7n-btn-flat.c7n-btn-primary:hover {
    background-color: transparent;
  }
}

.c7n-input-content:hover {
  border-color: @back !important;
  border-radius: 2px !important;
}

.c7n-input-focused {
  border-color: transparent !important;
}

.c7n-input:focus input {
  border-color: transparent !important;
}

.c7n-input-focused .htd-aside-left-search {
  border: none !important;
}

.c7n-input-has-border.c7n-input-focused.c7n-input-wrapper::before {
  border: 1px solid @back !important;
  // border: none !important;
}

.htd-aside-left {
  height: 100%;
  border-right: 1px solid #dbdbdb;
  overflow: hidden;
  background-color: #f7f7f7;

  &-search {
    height: 32px !important;
  }

  i.icon-search {
    font-size: 20px !important;
    color: #333 !important;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 47px;
    height: 38px;
    padding: 0 8px;

    &-backImg {
      width: 16px;
      margin-right: 5px;
      position: relative;
      top: -1px;
      cursor: pointer;
    }

    > span {
      font-size: 16px;
    }

    > i.icon {
      cursor: pointer;

      &:hover {
        color: @primary-color;
      }
    }
  }

  &-main {
    height: calc(100% - 38px);
    overflow-y: auto;

    &-components {
      height: 100%;

      &-container {
        padding: 0 8px 16px 8px;

        .c7n-input-suffix {
          top: 3px;
        }
      }

      &-t {
        color: #666;
        margin-bottom: 12px;
        margin-top: 4px;
        font-size: 12px;
      }

      .c7n-tabs-bar {
        background-color: #fff;

        .c7n-tabs-nav-container-scrolling {
          padding: 0 !important;
        }

        .c7n-tabs-tab-prev,
        .c7n-tabs-tab-next {
          display: none;
        }

        .c7n-tabs-nav-scroll {
          display: flex;
          justify-content: center;

          .c7n-tabs-nav {
            width: 100%;

            > div {
              width: 50% !important;
              text-align: center;
            }

            .c7n-tabs-ink-bar-animated {
              background-color: @primary-color;
            }

            .c7n-tabs-tab-active {
              color: @primary-color;
            }

            .c7n-tabs-tab {
              margin: 0 !important;

              &:hover {
                color: @primary-color;
              }
            }
          }
        }
      }

      .c7n-tabs-content {
        height: calc(100% - 54px);
      }

      .c7n-tabs-tabpane {
        height: 100%;
        overflow-y: auto;
        padding: 0;
        // padding: 0 8px 16px 8px;
      }
    }
  }

  &-wapper {
    &-component {
      position: relative;
      display: inline-block;
      margin-bottom: 8px;
      padding: 0 8px;
      // width: calc(50% - 4px);
      width: 116px;
      height: 36px;
      line-height: 36px;
      color: @font-color;
      background-color: @common-bg;
      user-select: none;
      transition: all 0.35s ease;
      border: 1px solid @common-bg;
      border-radius: @border-radius;
      font-size: 12px;

      svg {
        width: 1.5em;
        height: 1.5em;
      }

      // svg path {
      //   fill: currentColor;
      // }

      // svg rect {
      //   stroke: currentColor;
      // }

      .htd-drag-box-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > div {
          // display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }

      // 组件资源  hover时 右上角图标
      .htd-aside-left-wapper-component-left-top-box {
        width: 16px !important;
        height: 16px !important;
        position: absolute;
        top: -1px;
        right: -9px;
        opacity: 0;
        background-color: @back;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          width: 12px;
          height: 12px;
          // position: absolute;
          // top: 3px;
          // left: 2px;
        }
      }

      .htd-drag-box-content:hover {
        cursor: pointer;

        .htd-aside-left-wapper-component-left-top-box {
          opacity: 1 !important;
          // .left-svg-cla {
          //   background-color: #fff !important;
          //   color: #fff !important;
          // }
        }
      }

      .htd-drag-box-content:active {
        // cursor: crosshair;
        cursor: move;
      }

      &.htd-aside-left-wapper-component-disabled {
        cursor: not-allowed;
        background: transparent;
        border-style: dashed;
        border-color: #ccc;
      }

      &:not(.htd-aside-left-wapper-component-disabled) {
        &:hover {
          background-color: @common-bg-hover;
          color: @font-color-hover;
          border: 1px dashed @border-color-hover;
        }
      }

      &:nth-child(odd) {
        margin-right: 8px;
      }
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;