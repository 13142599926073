/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-aside-right-buttons-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.htd-aside-right-buttons-button-wrapper .ant-btn {
  width: 276px;
  height: 36px;
  background-color: #e27279 !important;
  border-radius: 2px;
  color: #fff !important;
  border: none !important;
}
.htd-aside-right-buttons-button-wrapper button {
  color: #e27279;
}
.htd-aside-right-buttons-button-wrapper button:hover {
  color: #e27279;
}
.htd-aside-right-buttons-table .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px !important;
}
.htd-aside-right-buttons-table .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.htd-aside-right-buttons-table .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  border: none;
  font-size: 12px;
  height: 20px;
}
.htd-aside-right-buttons-table tr {
  background-color: #f8f8f8;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
}
.htd-aside-right-buttons-table th,
.htd-aside-right-buttons-table td {
  padding: 4px 2px !important;
}
.htd-aside-right-buttons-table th input,
.htd-aside-right-buttons-table td input {
  font-size: 12px;
}
.htd-aside-right-buttons-table th::before {
  background-color: transparent !important;
}
.htd-aside-right-buttons-table .htd-aside-right-columns-table-status {
  width: 0;
}
.htd-aside-right-buttons-table .htd-aside-right-columns-table-status .anticon {
  font-size: 12px;
}
.htd-aside-right-buttons-table .htd-aside-right-buttons-table-operator {
  cursor: pointer;
  width: 20%;
  color: #999;
}
.htd-aside-right-buttons-table .htd-aside-right-buttons-table-operator .anticon {
  cursor: pointer;
}
.htd-aside-right-buttons-table .htd-aside-right-buttons-table-operator .anticon:hover {
  color: #e27279;
}
.htd-aside-right-buttons-table .htd-aside-right-buttons-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.htd-aside-right-buttons-table .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
  height: 28px;
}
.htd-aside-right-buttons-table .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px;
}
.htd-aside-right-buttons-table .ant-form-item .ant-select .ant-select-selector,
.htd-aside-right-buttons-table .ant-form-item .ant-cascader-picker .ant-select-selector {
  height: 28px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.htd-aside-right-buttons-table input {
  height: 28px;
  font-size: 12px;
}
.htd-aside-right-buttons-table .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 26px;
}
.button-column-panel .ant-form-item .ant-form-item-control {
  width: auto;
}
.button-column-panel .ant-form-item .ant-form-item-control .ant-form-item-control-input {
  width: auto;
}
.base-box > div .ant-form-item-control-input-content {
  height: 28px;
}
.base-box > div .ant-form-item-control-input-content .c7n-pro-icon-picker-wrapper {
  height: 28px;
}
.base-box > div .ant-form-item-control-input-content .c7n-pro-icon-picker-wrapper label {
  height: 28px;
}
.base-box > div .ant-form-item-control-input-content .c7n-pro-icon-picker-wrapper label .c7n-pro-icon-picker {
  height: 28px;
}
