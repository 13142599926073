.htd-aside-left-main-tree.c7n-tree {
  padding-top: 4px !important;
}
.htd-aside-left-main-tree.c7n-tree svg {
  width: 16px;
  height: 16px;
}
.htd-aside-left-main-tree.c7n-tree .c7n-tree-treenode-disabled .c7n-tree-node-content-wrapper {
  color: inherit;
  cursor: pointer;
}
.htd-aside-left-main-tree.c7n-tree .c7n-tree-treenode.drag-over > [draggable] {
  color: inherit;
  background-color: #fff;
  opacity: 1;
}
.c7n-tree .c7n-tree-node-content-wrapper.c7n-tree-node-selected {
  background: rgba(226, 114, 121, 0.18) !important;
  color: rgba(226, 114, 121);
}
