.htd-placeholder-row {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}
.htd-placeholder-row > .htd-drop-area {
  display: flex;
  flex-flow: row wrap;
  min-height: 100px;
}
.htd-placeholder-row-tensile {
  position: relative;
}
.htd-placeholder-row-tensile-line {
  position: absolute;
  top: 10%;
  left: -2px;
  height: 80%;
  width: 3px;
  cursor: col-resize;
  z-index: 19;
  margin-left: 1px;
  border-left-style: dashed;
  border-left-width: 2px;
  border-left-color: #e8e8e8;
}
.htd-placeholder-row-tensile-line:hover {
  border-left-color: #e27277;
}
.not-chr {
  background-color: transparent !important;
}
.htd-placeholder-col {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  height: 100%;
}
.htd-placeholder-col > .htd-drop-area {
  height: 100%;
}
.htd-placeholder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.htd-placeholder-header > span {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.htd-placeholder-header > span {
  display: inline-block;
  margin-left: 16px;
  font-weight: bold;
}
.htd-placeholder-header > div {
  flex: 1;
  height: 100%;
}
.htd-placeholder-header > div > .htd-drop-area {
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
}
.htd-placeholder-header > div > .htd-drop-area .htd-drop-area-placeholder {
  margin-left: 0;
  right: 0;
  left: unset;
  margin-right: 16px;
}
.htd-placeholder-header > div > .htd-drop-area .htd-drop-area-placeholder::after {
  content: '拖拽按钮';
  width: 82px;
  display: inline-block;
  height: 28px;
  background-color: #f2f2f2;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  line-height: 27px;
  position: relative;
  top: -4px;
  right: -8px;
  text-align: start;
  padding-left: 8px;
  color: #bfc3d0;
  font-size: 12px;
}
.htd-placeholder-header .htd-drag-header-right-jt {
  position: absolute;
  right: 24px;
}
.htd-placeholder-tabs {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
}
.htd-placeholder-tabs > .htd-drop-area {
  min-height: 100px;
}
.tabs-over-flow {
  overflow: hidden;
}
.tabs-over-flow .c7n-tabs {
  overflow: visible;
}
.tabs-over-flow .c7n-tabs:not(.c7n-tabs-vertical) > .c7n-tabs-content-animated {
  margin-top: 12px;
}
.tabs-over-flow .c7n-tabs:not(.c7n-tabs-vertical) > .c7n-tabs-content {
  margin-top: 12px;
}
.tabs-over-flow .c7n-tabs-vertical > .c7n-tabs-content {
  overflow: visible;
  margin-top: 12px !important;
}
.tabs-over-flow .c7n-tabs-vertical.c7n-tabs-right > .c7n-tabs-bar {
  border-left: none !important;
}
.tabs-over-flow .c7n-tabs-vertical.c7n-tabs-left > .c7n-tabs-bar {
  border-right: none !important;
}
.tabs-over-flow .htd-placeholder-tabs-content-margin-left > .c7n-tabs-content {
  margin-top: 12px !important;
  margin-left: 80px !important;
}
.tabs-over-flow .htd-placeholder-tabs-content-margin-left > .c7n-tabs-content-animated {
  margin-left: 80px !important;
}
.tabs-over-flow .htd-placeholder-tabs-content-margin-right > .c7n-tabs-content {
  margin-top: 12px !important;
  margin-right: 80px !important;
}
.tabs-over-flow .htd-placeholder-tabs-content-margin-right > .c7n-tabs-content-animated {
  margin-right: 80px !important;
}
.htd-placeholder-tabs-tabpane {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
}
.htd-placeholder-tabs-tabpane > .htd-drop-area {
  min-height: 100px;
}
.htd-placeholder-content {
  height: 100%;
  padding: 21px 16px 16px 16px;
}
.htd-placeholder-table {
  pointer-events: none;
}
.htd-placeholder-table .c7n-pro-table-professional-query-more {
  pointer-events: auto;
}
.htd-placeholder-table .c7n-pro-table-content-overflow {
  pointer-events: auto;
}
.htd-placeholder-table .c7n-pro-table-content-overflow > table {
  pointer-events: none;
}
.htd-placeholder-table .c7n-pro-table-content-overflow::-webkit-scrollbar {
  height: 10px;
}
.htd-placeholder-button {
  pointer-events: none;
}
.htd-placeholder-card-border {
  border: #e27277 1px solid;
  min-height: 140px;
}
.htd-placeholder-card-noborder {
  min-height: 140px;
}
.htd-placeholder-card-label-pre {
  display: inline-block;
  width: 4px;
  height: 100%;
  background-color: #e27277;
}
.htd-placeholder-card-label {
  height: 17px;
  line-height: 32px;
  margin: 0 10px;
  font-size: 14px;
  text-align: left;
}
.htd-placeholder-card-label-text {
  display: inline-block;
  margin: 0px 0px 0 10px;
  width: 50%;
  height: 14px;
}
.htd-placeholder-card-content {
  min-height: 90px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 10px 10px;
}
.htd-placeholder-card-content > .htd-drop-area {
  min-height: 100px;
}
/* lov框样式bug */
.htd-placeholder-form-field .c7n-pro-select-focused .c7n-pro-select {
  border: none !important;
}
.htd-placeholder-form-field-content > label > .c7n-pro-select-suffix {
  right: -8px;
  top: -2px;
}
.htd-placeholder-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.htd-placeholder-footer > span {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.htd-placeholder-footer > span {
  display: inline-block;
  margin-left: 16px;
  font-weight: bold;
}
.htd-placeholder-footer > div {
  flex: 1;
  height: 100%;
}
.htd-placeholder-footer > div > .htd-drop-area {
  padding-top: 5px;
  padding-left: 16 px;
  padding-right: 16px;
}
.htd-placeholder-footer > div > .htd-drop-area .htd-drop-area-placeholder::after {
  content: '拖拽按钮到这里';
  display: inline-block;
  height: 28px;
  border-radius: 2px;
  line-height: 27px;
  position: relative;
  top: -4px;
  right: -8px;
  text-align: start;
  padding-left: 8px;
  padding-right: 8px;
  color: #bfc3d0;
  font-size: 12px;
}
