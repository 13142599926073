.htd-aside-left-main-dataSet {
  height: 100%;
  overflow: hidden;
  background-color: #fff;

  .ant-collapse-borderless {
    background-color: #fff;
  }
  > button {
    width: calc(100% - 16px);
    margin: 0 8px 8px 8px;
  }
  &-collapse {
    // display: flex;
    height: calc(100% - 36px);
    overflow-y: auto;
    font-size: 12px !important;
    > .ant-collapse-item > .ant-collapse-header {
      padding: 12px !important;
      > .ant-collapse-arrow {
        margin-right: 8px !important;
      }
    }
    .htd-aside-left-main-dataSet-collapse-panel-bottom {
      .ant-collapse-content-box {
        padding: 0;
      }
      border: none !important;
      // width: 100% !important;
      padding: 0 8px;
      .ant-collapse-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 36px;
        font-size: 12px;
        // border-bottom: 1px solid red;
      }
      // 改变数据源头背景颜色（如何用样式设置 点击之后 一直是这个颜色？什么时候又让颜色变回来？）
      // .ant-collapse-header:active {
      //   background-color: #525a6e;
      // }
      // .ant-collapse-header:hover {
      //   background-color: #525a6e;
      // }
    }
    .ant-collapse-content {
      .htd-ds-config-back {
        background-color: transparent;
      }
    }
    &-panel-bottom {
      margin-bottom: 12px;
    }
  }
  .item-back {
    background-color: red;
  }
  .item-back:hover {
    background-color: blue;
  }
}

.htd-collapse-edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 22px);
  .c7n-pro-input-wrapper label {
    height: 28px;
    .c7n-pro-input {
      height: 28px;
      // 数据源 头 hover 时 样式问题
      border-color: #d9d9d9;
    }
  }
  &-imgIcon {
    width: 16px;
    height: 16px;
    color: #e0e0e0;
    cursor: pointer;
    opacity: 0;
    position: relative;
    left: 6px;
  }

  &-input {
    background-color: transparent !important;
    width: 190px;
    height: 28px;
    // margin-right: 4px;
    input {
      border: none;
      padding: 0;
      background-color: transparent;
      font-size: 12px;
      font-weight: 500;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2);
        // border: none !important;
        padding: 0 5px;
        background-color: #fff;
      }
    }
  }
}
// 整行触发  删除 图标
.htd-collapse-edit-header:hover {
  .htd-collapse-edit-header-imgIcon {
    color: #e27279 !important;
    opacity: 1;
  }
  .c7n-pro-input {
    border: 1px solid #d9d9d9;
    background-color: #fff !important;
    border-radius: 2px;
    padding-left: 5px;
    cursor: pointer;
  }
}
.left-collapse-box {
  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0 !important;
  }
}
// 数据源 头部 部分 hover时改变了鼠标样式  获取焦点时改回来
.c7n-pro-input:focus {
  cursor: text !important;
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;