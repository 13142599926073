@import '~antd/lib/style/themes/default.less';
@import '../../index.less';

// 大按钮  （外层框用于按钮居中）
.htd-aside-right-operator-button-wrapper {
  display: flex;
  justify-content: center;
  .ant-btn {
    width: 276px;
    height: 36px;
    background-color: @back !important;
    border-radius: 2px;
    color: #fff !important;
    border: none !important;
  }
  button {
    color: @primary-color;
    &:hover {
      color: @primary-color;
    }
  }
}

.htd-operator-box {
  border: none !important;
  // input 和 下拉框 高度
  .ant-form-item {
    margin-bottom: 12px;
    align-items: center;
    height: 28px;
    .ant-form-item-label > label {
      height: 28px;
      color: #666;
    }
    .ant-form-item-label-left {
      height: 28px;
    }
    .ant-form-item-control {
      height: 28px !important;
      width: 190px !important;
      .ant-form-item-control-input {
        height: 28px;
        width: 190px;
      }
    }
    .ant-radio-button-wrapper {
      font-size: 12px !important;
      height: 28px !important;
      .ant-radio-button-wrapper > .ant-radio-button {
        height: 28px !important;
      }
    }

    .htd-aside-right-main .ant-form .ant-form-item {
      height: 28px;
    }
    input {
      height: 28px;
      font-size: 12px;
    }
  }

  .htd-operator-box-top {
    // 输入框长度样式
    .ant-form-item {
      width: 100%;
      display: flex;
      justify-content: flex-start !important;
    }
    .ant-form-item-label {
      width: 75px;
    }
    .ant-form-item-control {
      width: 190px;
      .ant-form-item-control-input {
        width: 190px;
      }
    }
  }
  .ant-select-selector {
    border-radius: 4px !important;
  }
  .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
    max-width: 70% !important;
  }
  .ant-switch-checked {
    background-color: @back;
  }
  .c7n-input-number {
    padding-left: 16px;
    padding-right: 16px;
    .c7n-input-content {
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
}
// }

.ant-collapse-header {
  background-color: #f2f2f2;
  font-size: 12px;
}

.htd-aside-right-operator-table {
  .htd-aside-right-operator-table-operator {
    cursor: pointer;
    width: 20%;
    color: #999999;
    .anticon {
      cursor: pointer;
      &:hover {
        // color: @primary-color;
        color: #e27279;
      }
      & + .anticon {
        margin-left: 8px;
      }
    }
  }
  // 下拉框hover
  .ant-form-item.ant-select-selector:hover {
    border: 1px solid @back;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 1px solid @back;
  }
  // 表格头 和 表格列
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fff !important;
      border: none !important;
      font-size: 12px;
      height: 20px;
    }
    // 表格的竖线
    > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0;
    }
  }
  tr {
    background-color: #f8f8f8;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
  }
  &-select {
    .ant-select-selector {
      height: 28px !important;
      span {
        height: 28px !important;
        input {
          height: 28px !important;
        }
      }
    }
  }
  &-operator {
    width: 23%;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    .anticon {
      &:hover {
        color: @primary-color;
      }
    }
  }
}
.operator-column-panel {
  .ant-form-item .ant-form-item-control {
    width: auto;
    .ant-form-item-control-input {
      width: auto;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;