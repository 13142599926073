.my-popconfirm-contents {
  padding: 12px 16px;
  color: #000000d9;

  .my-popconfirm-message {
    position: relative;
    padding: 4px 0 12px;
    color: #000000d9;
    font-size: 14px;

    .my-popconfirm-message-title {
      display: inline-block;
      padding-left: 9px;
    }
  }

  .my-popconfirm-buttons {
    .ant-btn-primary {
      color: #fff !important;
      border-color: #e27279 !important;
      background: #e27279 !important;
    }
  }
}

.htd-aside-right-columns-table-operator {
  .my-popconfirm-message {
    svg {
      color: #faad14 !important;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;