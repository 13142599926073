/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.htd-aside-right-operator-button-wrapper {
  display: flex;
  justify-content: center;
}
.htd-aside-right-operator-button-wrapper .ant-btn {
  width: 276px;
  height: 36px;
  background-color: #e27279 !important;
  border-radius: 2px;
  color: #fff !important;
  border: none !important;
}
.htd-aside-right-operator-button-wrapper button {
  color: #e27279;
}
.htd-aside-right-operator-button-wrapper button:hover {
  color: #e27279;
}
.htd-operator-box {
  border: none !important;
}
.htd-operator-box .ant-form-item {
  margin-bottom: 12px;
  align-items: center;
  height: 28px;
}
.htd-operator-box .ant-form-item .ant-form-item-label > label {
  height: 28px;
  color: #666;
}
.htd-operator-box .ant-form-item .ant-form-item-label-left {
  height: 28px;
}
.htd-operator-box .ant-form-item .ant-form-item-control {
  height: 28px !important;
  width: 190px !important;
}
.htd-operator-box .ant-form-item .ant-form-item-control .ant-form-item-control-input {
  height: 28px;
  width: 190px;
}
.htd-operator-box .ant-form-item .ant-radio-button-wrapper {
  font-size: 12px !important;
  height: 28px !important;
}
.htd-operator-box .ant-form-item .ant-radio-button-wrapper .ant-radio-button-wrapper > .ant-radio-button {
  height: 28px !important;
}
.htd-operator-box .ant-form-item .htd-aside-right-main .ant-form .ant-form-item {
  height: 28px;
}
.htd-operator-box .ant-form-item input {
  height: 28px;
  font-size: 12px;
}
.htd-operator-box .htd-operator-box-top .ant-form-item {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
.htd-operator-box .htd-operator-box-top .ant-form-item-label {
  width: 75px;
}
.htd-operator-box .htd-operator-box-top .ant-form-item-control {
  width: 190px;
}
.htd-operator-box .htd-operator-box-top .ant-form-item-control .ant-form-item-control-input {
  width: 190px;
}
.htd-operator-box .ant-select-selector {
  border-radius: 4px !important;
}
.htd-operator-box .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
  max-width: 70% !important;
}
.htd-operator-box .ant-switch-checked {
  background-color: #e27279;
}
.htd-operator-box .c7n-input-number {
  padding-left: 16px;
  padding-right: 16px;
}
.htd-operator-box .c7n-input-number .c7n-input-content {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.ant-collapse-header {
  background-color: #f2f2f2;
  font-size: 12px;
}
.htd-aside-right-operator-table .htd-aside-right-operator-table-operator {
  cursor: pointer;
  width: 20%;
  color: #999999;
}
.htd-aside-right-operator-table .htd-aside-right-operator-table-operator .anticon {
  cursor: pointer;
}
.htd-aside-right-operator-table .htd-aside-right-operator-table-operator .anticon:hover {
  color: #e27279;
}
.htd-aside-right-operator-table .htd-aside-right-operator-table-operator .anticon + .anticon {
  margin-left: 8px;
}
.htd-aside-right-operator-table .ant-form-item.ant-select-selector:hover {
  border: 1px solid #e27279;
}
.htd-aside-right-operator-table .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: 1px solid #e27279;
}
.htd-aside-right-operator-table .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  border: none !important;
  font-size: 12px;
  height: 20px;
}
.htd-aside-right-operator-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}
.htd-aside-right-operator-table tr {
  background-color: #f8f8f8;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
}
.htd-aside-right-operator-table-select .ant-select-selector {
  height: 28px !important;
}
.htd-aside-right-operator-table-select .ant-select-selector span {
  height: 28px !important;
}
.htd-aside-right-operator-table-select .ant-select-selector span input {
  height: 28px !important;
}
.htd-aside-right-operator-table-operator {
  width: 23%;
}
.htd-aside-right-operator-table-operator img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.htd-aside-right-operator-table-operator .anticon:hover {
  color: #e27279;
}
.operator-column-panel .ant-form-item .ant-form-item-control {
  width: auto;
}
.operator-column-panel .ant-form-item .ant-form-item-control .ant-form-item-control-input {
  width: auto;
}
