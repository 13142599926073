.htd-page-preview-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: #fff;
  z-index: 1000;
}
.htd-page-preview-container-header {
  height: 48px;
}
.htd-page-preview-container-header-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.htd-page-preview-container-close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  color: red;
  cursor: pointer;
  text-align: center;
}
.htd-page-preview-root {
  width: 100%;
  height: 100%;
}
