.page-head {
  display: flex;
  height: 48px;
  min-height: 48px;
  line-height: 47px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  align-items: center;
}
.page-head-back-btn {
  flex-shrink: 0;
}
.page-head .page-head-back-btn {
  cursor: pointer;
}
.page-head .back-btn {
  cursor: pointer;
  font-size: 22px;
  vertical-align: middle;
  margin: 0 -12px 2px 14px;
  padding: 10px;
  border-radius: 22px;
}
.page-head .back-btn:before {
  content: '\E61C';
}
.page-head .back-btn:hover {
  background-color: #f0f2f5;
}
.page-head .anticon-arrow-left:before {
  content: '\E61C';
}
.page-head-title {
  margin-left: 16px;
  margin-right: 24px;
  color: #000;
  font-size: 16px;
  line-height: 48px;
}
.page-head-operator {
  margin-right: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}
.page-head-operator > .c7n-pro-btn + .c7n-pro-btn:not(.c7n-pro-btn-block) {
  margin-left: 0;
}
.page-head-operator > .c7n-pro-btn:not(:last-child) {
  margin-left: 8px !important;
}
.page-content-wrap {
  height: calc(100% - 48px);
  flex-grow: 1;
  overflow: hidden;
}
.page-content-wrap-no-card > .page-content {
  margin: 0;
}
.page-content {
  margin: 16px 16px 16px;
  padding: 16px;
  height: calc(100% - 32px);
  background-color: #fff;
  overflow: auto;
}
.page-content-header {
  display: block;
}
.page-content-header .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
}
.page-content-header .description {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 24px;
  max-width: 512px;
  color: #898b96;
}
.page-content-header .description i.anticon {
  font-size: 18px;
  color: #898b96;
  margin-right: 12px;
}
.page-content-header .description a {
  color: #3f51b5;
  cursor: pointer;
  text-decoration: underline;
}
