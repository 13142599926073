@import '../../utils/styles/variables.less';
@import './common.less';

.htd-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-header {
    flex: 0 0 48px;
    background-color: @header-color;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-top-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 40px;
        height: 18px;
        margin-right: 8px;
      }
    }

    &-title {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;
      background-image: linear-gradient(134deg, #f78249 0%, #e27279 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-actions {
      &-generate {
        height: 28px;
        padding: 0 16px;
        font-size: 12px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.35s;
        border-radius: @border-radius;

        &-default {
          color: @default-font-color;
          background-color: @default-bg;
          border: 1px solid @default-border-color;
        }

        &-primary {
          color: @primary-font-color;
          background-color: @primary-bg;

          &:hover {
            background-color: @primary-bg-hover;
            color: @primary-font-color-hover;
          }
        }
      }
      &-buttonBox {
        border-right: 1px solid #55555f;
        display: inline-block;
        margin-right: 24px;
        font-size: 12px;
        // span {
        //   margin-right: 24px;
        //   color: #999999;
        // }
        a {
          margin-right: 24px;
          color: #999999;
        }
        img {
          cursor: pointer;
          width: 18px;
          height: 18px;
          position: relative;
          top: -2px;
        }
      }

      button + button {
        margin-left: 8px;
      }
    }
  }

  &-main {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: calc(100% - 48px);
  }

  .htd-aside-left {
    // flex: 0 0 200px;
    flex: 0 0 265px;
  }

  .htd-aside-right {
    // flex: 0 0 280px;
    flex: 0 0 300px;
  }

  .htd-canvas-wrap {
    flex: 1;
  }
}

.htd-drag-box-inline {
  display: inline;
}

.htd-drag-box-inline-container,
.htd-drag-box-inline-block {
  display: inline-block;
}
// 预览代码弹框
.htd-components-preview-modal {
  .c7n-pro-modal-body {
    padding-top: 0 !important;
  }
  .c7n-pro-modal-header {
    border-bottom: none !important;
  }
}

.page-tabs {
  position: absolute;
  left: 326px;
  top: 48px;
  z-index: 9;
  display: flex;
  height: 40px;
  align-items: center;
  .page-tabs-tab-active {
    display: flex;
    min-width: 60px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: white;
    background: rgb(226, 114, 119);
    border-radius: 3px;
    margin-right: 8px;
  }
  .page-tabs-tab {
    display: flex;
    min-width: 60px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #f1f1f1;
    border-radius: 3px;
    margin-right: 8px;
  }
  .page-title {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .page-close {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    .icon {
      font-size: 14px;
    }
  }
  .page-close:hover,
  .add-page-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .add-page-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 20px;
    span {
      margin-top: -4px;
    }
  }
}
.htd-page-config-modal {
  .c7n-pro-modal-header {
    padding: 16px 0 16px 24px;
    border-bottom: 1px solid #eaeaea !important;
  }
  // .c7n-pro-field-wrapper {
  //   width: 280px;
  // }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;