@import '../../utils/styles/variables.less';
@import '../../mapping/data/index.less';

.htd-drag-item-container {
  width: 100%;
  height: 100%;
  position: relative;

  // 容器边框
  &-active {
    border: 2px solid @canvas-border-color !important;

    > .htd-drag-item-container-title,
    > .htd-drag-item-container-header {
      display: flex;
      background-color: @back;
      color: #fff;
      font-size: 12px;
      border-radius: 1px;
      height: 20px;
    }

    img {
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
    }
  }

  &-title {
    position: absolute;
    top: -3px;
    left: 0;
    padding: 0 4px;
    display: none;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    background-color: transparent;
    color: @primary-color;
    font-weight: bold;
    z-index: 2;
  }

  &-header {
    position: absolute;
    top: -3px;
    right: 0;
    transform: translateY(-100%);
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
    height: 18px;
    z-index: 2;

    &-action {
      color: #fff;
      background-color: @primary-color;
      line-height: 0;
      cursor: pointer;
      transition: all 0.25s;
      margin-left: 2px;

      // .left1-svg1-cla1:hover {
      //   color: #fff !important;
      // }
      svg {
        color: #fff !important;
        width: 14px !important;
        height: 14px !important;
      }

      svg:hover {
        color: #fff !important;
      }

      &:hover {
        opacity: 0.6;
      }

      &:not(:first-child) {
        margin-left: 4px;
      }

      &-delete {
        margin-left: 2px;
      }

      &-save {
        margin-right: 2px;
      }
    }
  }

  &-main {
    position: relative;
    width: 100%;
    height: 100%;

    // 画布中lov输入框  在父级hover时 背景色问题
    .htd-placeholder-form-field .c7n-pro-select-wrapper {
      background-color: transparent;
    }
  }
}

.htd-category-select {
  width: 100%;
  display: inline-flex;

  .ant-select {
    flex: 0 0 203px;
  }

  &-add {
    display: none;
    flex: 0 0 auto;
    cursor: pointer;
    margin-left: 8px;
    line-height: 28px;
    font-size: 12px;
    color: #525a6e;
    letter-spacing: 0;
    font-weight: 400;
    transition: color 0.2s;

    &:hover {
      color: @primary-color;
    }

    svg {
      margin-right: 2px;
    }
  }
}

.htd-block-save-modal {
  .ant-form-item {
    margin-bottom: 12px;

    &-has-error {
      margin-bottom: 20px;
    }
  }

  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  .ant-form-item-label {
    width: 80px;
    padding-right: 30px;

    label:after {
      display: none;
    }
  }

  &-tooltip-label {
    display: inline-flex;
    margin-right: -18px;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }
}

.htd-change-component-modal {
  .ant-modal-body {
    padding-bottom: 0 !important;

    .ant-form-item-control {
      height: auto;

      .ant-form-item-control-input {
        height: auto !important;
      }
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;