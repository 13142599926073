.page-head {
  display: flex;
  height: 48px;
  min-height: 48px;
  line-height: 47px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  align-items: center;

  &-back-btn {
    flex-shrink: 0;
  }

  .page-head-back-btn {
    cursor: pointer;
  }

  .back-btn {
    cursor: pointer;
    font-size: 22px;
    vertical-align: middle;
    margin: 0 -12px 2px 14px;
    padding: 10px;
    border-radius: 22px;

    &:before {
      content: '\E61C';
    }

    &:hover {
      background-color: #f0f2f5;
    }
  }

  .anticon-arrow-left:before {
    content: '\E61C';
  }

  &-title {
    margin-left: 16px;
    margin-right: 24px;
    color: #000;
    font-size: 16px;
    line-height: 48px;
  }

  &-operator {
    margin-right: 16px;
    flex-grow: 1;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;

    > .c7n-pro-btn + .c7n-pro-btn:not(.c7n-pro-btn-block) {
      margin-left: 0;
    }

    > .c7n-pro-btn:not(:last-child) {
      margin-left: 8px !important;
    }
  }
}

.page-content-wrap {
  height: calc(100% - 48px);
  flex-grow: 1;
  overflow: hidden;
}

.page-content-wrap-no-card {
  > .page-content {
    margin: 0;
  }
}

.page-content {
  // FIXME: 先使用常量 之后 变量全部完成后再替换成变量
  margin: 16px 16px 16px;
  padding: 16px;
  height: calc(~'100% - 32px');
  background-color: #fff;
  overflow: auto;
}

.page-content-header {
  display: block;

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .description {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 24px;
    max-width: 512px;
    color: #898b96;

    i.anticon {
      font-size: 18px;
      color: #898b96;
      margin-right: 12px;
    }

    a {
      color: #3f51b5;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;