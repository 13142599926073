/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.editable-row .ant-form-item {
  margin: 0 !important;
}
.editable-row.drop-over-downward td {
  border-bottom: 2px dashed #e27279;
}
.editable-row.drop-over-upward td {
  border-top: 2px dashed #e27279;
}
.only-table-icon-drop-over-downward {
  border-bottom: 2px dashed #e27279 !important;
}
.only-table-icon-drop-over-upward {
  border-top: 2px dashed #e27279;
}
.table-null-right-box {
  background-color: #f7f7f7;
  width: 100%;
  height: 100px;
  font-size: 12px;
  text-align: center;
  line-height: 42px;
  color: #999;
  font-weight: 400;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-null-right-box img {
  width: 84px;
  height: 50px;
}
.table-null-right-box span {
  line-height: 24px;
}
