.modal-size .ant-row {
  width: 50% !important;
  display: inline-block !important;
  margin-bottom: 0 !important;
}
.modal-size .ant-row .ant-col {
  display: inline-block !important;
}
.modal-size .ant-row .ant-col .ant-form-item-control-input {
  width: auto !important;
  display: inline-block !important;
}
.modal-size .ant-form-item > .ant-form-item-control {
  max-width: 100% !important;
}
.model-right-box .operator-button-panel-clu {
  width: 10% !important;
}
.model-right-box thead .ant-table-cell {
  text-align: start !important;
  color: #666;
}
.model-right-box tbody .ant-table-cell .ant-select-selection-item {
  color: #333;
}
.model-right-box tbody .ant-table-cell input {
  color: #333;
}
