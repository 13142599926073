.dynamic-box {
  padding: 12px;
  // padding: 2px 4px;

  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;

    span:last-child {
      font-size: 14px;
      margin-left: 4px;
    }
  }

  .func-list {
    height: 24px;
    line-height: 23px;
    margin-bottom: 2px;
    padding: 0 12px;
  }
  .ant-collapse-header {
    height: 24px;
    padding: 4px 12px !important;
    background-color: #eeeeee;
    .ant-collapse-arrow {
      margin-right: 6px !important;
    }
  }
  .clock-color {
    background-color: rgba(241, 188, 191, 0.48);
    color: #e27279 !important;
  }
  .no-color {
    background-color: white;
  }
  .ant-collapse {
    border: none !important;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse-content > {
    margin-top: 4px;
    .ant-collapse-content-box {
      padding: 0;
      padding-top: 8px !important;
      padding-bottom: 8px;
      box-shadow: 0px 2px 4px rgba(226, 114, 121, 0.24);
      opacity: 1;
    }
  }
}

.model-field {
  padding: 2px 12px;
  overflow-y: auto;
  // padding: 0 10px;

  .columns-checked-box {
    display: flex;
    align-items: center;

    label {
      margin-right: 6px;
      position: relative;
      left: -6px;
    }
  }

  tr {
    .ant-table-cell {
      max-width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-cell:hover {
      background-color: #fafafa !important;
    }
  }
  .ant-table-thead .ant-table-cell {
    height: 24px !important;
    background-color: #eeeeee;
  }
  .ant-table-tbody .ant-table-cell {
    height: 24px !important;
  }
  .ant-table-thead .ant-table-cell:hover {
    background-color: #eeeeee !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #f9f9f9;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #999;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    top: 30%;
    left: 0;
  }
}

// 函数样式
.model-add-func {
  .ant-modal-body {
    padding: 0 24px 6px 24px !important;
  }
}

.model-body-top-box {
  margin-bottom: 6px;
  padding-left: 6px;
}

.model-body-middle-box {
  margin-bottom: 10px;

  .model-body-middle-box-i {
    padding: 6px;

    // position: relative;
    .model-body-middle-box-i-list {
      margin: 8px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .model-body-middle-fx {
        display: flex;
        align-items: center;
      }

      .ant-col {
        margin-right: 6px;
      }
    }
  }
}

.middle-bottom-box {
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.model-body-butt-box {
  margin-bottom: 10px;
  width: 49%;
  height: 200px;

  &-i {
    padding: 6px;
  }

  .model-body-butt-box-i-input {
    margin-top: 6px;
    border: none;
  }
  .ant-input:focus,
  .ant-input-focused {
    border: none;
    border-color: transparent;
    box-shadow: none;
  }
}
.model-body-butt-fx {
  width: 49%;
  height: 200px;
}

.model-body-box-title {
  position: absolute;
  width: 46px;
  background-color: #fff;
  top: -9px;
  left: 6px;
  z-index: 2;
  text-align: center;
}
.dynamic-model {
  .ant-modal-header {
    padding: 16px 0 16px 24px !important;
    border-bottom: 1px solid #eaeaea !important;
  }
  .top-title-box-row {
    margin-top: 12px;
  }
  .htd-aside-right-columns-button-wrapper {
    margin-top: 12px;
  }
  .htd-aside-right-columns-button-wrapper .ant-btn {
    background-color: #fff !important;
    color: #e27279 !important;
    border: 1px solid #e27279 !important;
    height: 30px !important;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;