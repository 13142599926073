.dynamic-box {
  padding: 12px;
}
.dynamic-box .button-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dynamic-box .button-box span:last-child {
  font-size: 14px;
  margin-left: 4px;
}
.dynamic-box .func-list {
  height: 24px;
  line-height: 23px;
  margin-bottom: 2px;
  padding: 0 12px;
}
.dynamic-box .ant-collapse-header {
  height: 24px;
  padding: 4px 12px !important;
  background-color: #eeeeee;
}
.dynamic-box .ant-collapse-header .ant-collapse-arrow {
  margin-right: 6px !important;
}
.dynamic-box .clock-color {
  background-color: rgba(241, 188, 191, 0.48);
  color: #e27279 !important;
}
.dynamic-box .no-color {
  background-color: white;
}
.dynamic-box .ant-collapse {
  border: none !important;
}
.dynamic-box .ant-collapse .ant-collapse-item {
  border-bottom: none;
}
.dynamic-box .ant-collapse-content {
  border-top: none;
}
.dynamic-box .ant-collapse-content {
  margin-top: 4px;
}
.dynamic-box .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
  padding-top: 8px !important;
  padding-bottom: 8px;
  box-shadow: 0px 2px 4px rgba(226, 114, 121, 0.24);
  opacity: 1;
}
.model-field {
  padding: 2px 12px;
  overflow-y: auto;
}
.model-field .columns-checked-box {
  display: flex;
  align-items: center;
}
.model-field .columns-checked-box label {
  margin-right: 6px;
  position: relative;
  left: -6px;
}
.model-field tr .ant-table-cell {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.model-field tr .ant-table-cell:hover {
  background-color: #fafafa !important;
}
.model-field .ant-table-thead .ant-table-cell {
  height: 24px !important;
  background-color: #eeeeee;
}
.model-field .ant-table-tbody .ant-table-cell {
  height: 24px !important;
}
.model-field .ant-table-thead .ant-table-cell:hover {
  background-color: #eeeeee !important;
}
.model-field .ant-table-thead > tr > th,
.model-field .ant-table-tbody > tr > td,
.model-field .ant-table tfoot > tr > th,
.model-field .ant-table tfoot > tr > td {
  padding: 4px 16px;
}
.model-field .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f9f9f9;
}
.model-field .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #999;
}
.model-field .ant-checkbox-checked .ant-checkbox-inner::after {
  top: 30%;
  left: 0;
}
.model-add-func .ant-modal-body {
  padding: 0 24px 6px 24px !important;
}
.model-body-top-box {
  margin-bottom: 6px;
  padding-left: 6px;
}
.model-body-middle-box {
  margin-bottom: 10px;
}
.model-body-middle-box .model-body-middle-box-i {
  padding: 6px;
}
.model-body-middle-box .model-body-middle-box-i .model-body-middle-box-i-list {
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.model-body-middle-box .model-body-middle-box-i .model-body-middle-box-i-list .model-body-middle-fx {
  display: flex;
  align-items: center;
}
.model-body-middle-box .model-body-middle-box-i .model-body-middle-box-i-list .ant-col {
  margin-right: 6px;
}
.middle-bottom-box {
  display: flex;
  justify-content: space-between;
}
.model-body-butt-box {
  margin-bottom: 10px;
  width: 49%;
  height: 200px;
}
.model-body-butt-box-i {
  padding: 6px;
}
.model-body-butt-box .model-body-butt-box-i-input {
  margin-top: 6px;
  border: none;
}
.model-body-butt-box .ant-input:focus,
.model-body-butt-box .ant-input-focused {
  border: none;
  border-color: transparent;
  box-shadow: none;
}
.model-body-butt-fx {
  width: 49%;
  height: 200px;
}
.model-body-box-title {
  position: absolute;
  width: 46px;
  background-color: #fff;
  top: -9px;
  left: 6px;
  z-index: 2;
  text-align: center;
}
.dynamic-model .ant-modal-header {
  padding: 16px 0 16px 24px !important;
  border-bottom: 1px solid #eaeaea !important;
}
.dynamic-model .top-title-box-row {
  margin-top: 12px;
}
.dynamic-model .htd-aside-right-columns-button-wrapper {
  margin-top: 12px;
}
.dynamic-model .htd-aside-right-columns-button-wrapper .ant-btn {
  background-color: #fff !important;
  color: #e27279 !important;
  border: 1px solid #e27279 !important;
  height: 30px !important;
}
