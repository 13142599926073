@import '~antd/lib/style/themes/default.less';
@import '../../index.less';

.htd-aside-right-columns-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  button {
    color: @primary-color;
    &:hover {
      color: @primary-color;
    }
  }
}
// .data-table-h {
// 表格 去掉表格头背景 和 添加行背景
.htd-aside-right-columns-table {
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fff !important;
      border: none;
      font-size: 12px;
      height: 20px;
    }
  }
  tr {
    background-color: #f8f8f8;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
  }
  th,
  td {
    padding: 4px 2px !important;
    input {
      font-size: 12px;
    }
  }
  th {
    &::before {
      background-color: transparent !important;
    }
  }
  .htd-aside-right-columns-table-status {
    width: 0;
    &-box {
      display: flex;
      align-items: center;
      &-img {
        width: 5px;
        height: 16px;
        margin-left: 4px;
        cursor: move;
      }
    }
    .anticon {
      font-size: 12px;
    }
  }
  .htd-aside-right-columns-table-operator {
    cursor: pointer;
    width: 20%;
    color: #999;
    .anticon {
      cursor: pointer;
      &:hover {
        // color: @primary-color;
        color: #e27279;
      }
      & + .anticon {
        margin-left: 8px;
      }
    }
  }
}
// }
.data-table-h {
  .ant-table-wrapper {
    max-height: 270px;
    overflow-y: auto;
  }
}

.jus-conn {
  justify-content: center !important;
  // 大按钮（添加字段）
  .ant-btn {
    width: 276px;
    height: 36px;
    background-color: @back !important;
    border-radius: 2px;
    color: #fff !important;
    border: none !important;
  }
}

.ant-table-content {
  table {
    border-spacing: 0 8px !important;
  }
}

.htd-aside-right-table-field-edit-panel {
  position: absolute;
  top: 0;
  right: 299px;
  width: 280px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #efefef;
  z-index: 99;
  overflow: hidden;

  .htd-aside-right-table-field-validate-message-setting {
    cursor: pointer;
  }

  .htd-input-width {
    .ant-form-item-control-input-content {
      &::after {
        content: 'px';
        margin-left: 5px;
      }
    }
  }

  .c7n-pro-select-box {
    .c7n-pro-radio-button {
      padding: 0 8px !important;
    }
  }
}
.field-list-popover-box {
  // 搜索框样式
  .c7n-input-content {
    height: 28px;
  }
  .c7n-input-content > .c7n-input-rendered-wrapper {
    height: 28px !important;
    .c7n-input-rendered {
      height: 28px;
      > input {
        height: 28px !important;
      }
    }
    .c7n-input-label-wrapper > .c7n-input-label {
      line-height: 23px;
    }
  }
  .field-list-popover-list {
    max-height: 300px;
    overflow-y: auto;
    width: 260px;
    .field-list-popover-list-item {
      // paddingRight: 8, display: 'flex', alignItems: 'center'
      padding-right: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
.field-list-popover-box > span > div > div > .c7n-input-suffix {
  top: 1px !important;
}
// ds字段批量操作样式
.model-box {
  thead {
    .ant-table-selection {
      left: -8px;
    }
  }
  tr {
    .ant-table-cell:hover {
      background-color: #fafafa !important;
    }
  }
  // 滑动条加粗
  ::-webkit-scrollbar {
    height: 8px;
  }
  .ant-table-thead .ant-table-cell {
    height: 30px !important;
    background-color: #eeeeee;
  }
  .ant-table-tbody .ant-table-cell {
    height: 30px !important;
  }
  .ant-table-thead .ant-table-cell:hover {
    background-color: #eeeeee !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 8px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #999;
  }
  .ant-modal-header {
    padding: 16px 0 16px 24px !important;
    border-bottom: 1px solid #eaeaea !important;
  }
  .ant-table-body tr td.ant-table-cell {
    padding: 4px;
    vertical-align: top !important;
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;