@import '~antd/lib/style/themes/default.less';
@import '../../index.less';

.htd-aside-right-buttons-button-wrapper {
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    width: 276px;
    height: 36px;
    background-color: @back !important;
    border-radius: 2px;
    color: #fff !important;
    border: none !important;
  }

  button {
    color: @primary-color;
    &:hover {
      color: @primary-color;
    }
  }
}
.htd-aside-right-buttons-table {
  // 下拉框高度 和 边框hover
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 1px solid @back;
  }
  // 表格头颜色
  .ant-table-thead {
    // position: relative;
    // top: -8px;
    .ant-table-cell {
      background-color: #fff !important;
      border: none;
      font-size: 12px;
      height: 20px;
    }
  }
  tr {
    background-color: #f8f8f8;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
  }
  th,
  td {
    padding: 4px 2px !important;
    input {
      font-size: 12px;
    }
  }
  th {
    &::before {
      background-color: transparent !important;
    }
  }
  .htd-aside-right-columns-table-status {
    width: 0;
    .anticon {
      font-size: 12px;
    }
  }
  .htd-aside-right-buttons-table-operator {
    cursor: pointer;
    width: 20%;
    color: #999;
    .anticon {
      cursor: pointer;
      &:hover {
        // color: @primary-color;
        color: #e27279;
      }
      & + .anticon {
        margin-left: 8px;
      }
    }
  }

  .htd-aside-right-main .ant-form .ant-form-item > .ant-form-item-control {
    height: 28px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }
  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    .ant-select-selector {
      height: 28px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }
  input {
    height: 28px;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 26px;
  }
}
.button-column-panel {
  .ant-form-item .ant-form-item-control {
    width: auto;
    .ant-form-item-control-input {
      width: auto;
    }
  }
}
// .button-box {
//   > main > div > div > div > .ant-collapse-content-box{
//     padding-bottom: 0px !important;
//   }
// }
.base-box > div .ant-form-item-control-input-content {
  height: 28px;
  .c7n-pro-icon-picker-wrapper {
    height: 28px;
  }
  .c7n-pro-icon-picker-wrapper label {
    height: 28px;
    .c7n-pro-icon-picker {
      height: 28px;
    }
  }
}

@header-color: #30303d;@border-radius: 2px;@primary-color: #e27279;@primary-bg: #e27279;@primary-bg-hover: #e27279;@primary-font-color: #fff;@primary-font-color-hover: #fff;@default-bg: transparent;@default-bg-hover: transparent;@default-font-color: #fff;@default-font-color-hover: #fff;@default-border-color: #fff;@common-bg: #fff;@common-bg-hover: #f6e9ea;@font-color: #333;@font-color-hover: #e27279;@border-color-hover: #e27279;@canvas-border-color: #e27279;@error-color: rgb(247, 103, 118);@head-height: 48px;@left-menu-width: 49px;@left-panel-width: 265px;@right-panel-width: 300px;@canvas-padding: 16px;@back: #e27279;@height-base: 28px;@font-size-base: 12px;@switch-height: 24px;@switch-min-width: 40px;@btn-padding-horizontal-base: 19px;